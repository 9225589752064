import { PAGE_SIZE } from '../../constants';
import type {
    ServiceResponse,
    ServiceResponseOperatorServiceType
} from 'generatedTypes/operator-map/models';
import { useCallback, useEffect, useMemo, useState } from 'react';

import useTranslation from 'components/form/OperatorMapServiceDetailsForm/hooks/useTranslation';

import useServices from 'hooks/useServices';

const useData = () => {
    const { DRONE_AERIAL_APPLICATION, DRONE_IMAGERY } = useTranslation();

    const serviceLabels: Record<ServiceResponseOperatorServiceType, string> = useMemo(
        () => ({
            DRONE_AERIAL_APPLICATION: DRONE_AERIAL_APPLICATION,
            DRONE_IMAGERY: DRONE_IMAGERY
        }),
        [DRONE_AERIAL_APPLICATION, DRONE_IMAGERY]
    );

    const [serviceItems, setServiceItems] = useState([]);

    const { fetchServices } = useServices();

    const handleFetchServices = useCallback(async () => {
        const services = await fetchServices({
            pageable: {
                size: PAGE_SIZE
            }
        });

        const serviceDropdownItems = services.objects.map((service: ServiceResponse) => ({
            label: serviceLabels[service.operatorServiceType as ServiceResponseOperatorServiceType],
            type: 'string',
            value: service.id
        }));

        setServiceItems(serviceDropdownItems);
    }, [serviceLabels, fetchServices]);

    useEffect(() => {
        handleFetchServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        serviceItems
    };
};

export default useData;
