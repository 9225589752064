import { WORK_ORDER_NOTES_CHAR_MAX } from 'config';
import { Field } from 'formik';

import { FormSectionContainer } from '@rantizo-software/rantizo-ui';

import FormTextArea from 'components/deprecating/FormTextArea';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const Notes: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { LABEL } = useTranslation();

    return (
        <FormSectionContainer testId={testId}>
            <Field
                autosize={true}
                component={FormTextArea}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                label={LABEL}
                max={WORK_ORDER_NOTES_CHAR_MAX}
                minRows={isEditable && 15}
                name="notes"
            />
        </FormSectionContainer>
    );
};

export default Notes;
