export const AREA_FLOWN = 'areaFlown';

export const CONTACT = 'contact';

export const DEFAULT_AREA_UNIT = 'ac';

export const DESCRIPTION = 'description';

export const EMAIL = 'email';

export const HAS_PART_137 = 'hasPart137';

export const HAS_PUBLIC_INFORMATION_CONSENT = 'hasPublicInformationConsent';

export const PLACE_ID = 'placeId';

export const HOURS_FLOWN = 'hoursFlown';

export const IS_INSURED = 'isInsured';

export const NAME = 'name';

export const PHONE_NUMBER = 'phoneNumber';

export const SERVICES = 'services';

export const TEST_ID = 'operatorTilePage';

export const YEARS_OF_SERVICE = 'yearsOfService';

export const SEARCH_INPUT_KEY = 'searchInput';

export const LOCATION_KEY = 'location';

export const DEFAULT_SIZE = 5;

export const MAX_OPERATOR_LOCATIONS = 5;
