import IconTextButton from '../IconTextButton';

import { FarmIcon } from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const AddFarmButton = ({ className, onClick, testId = TEST_ID }: Props) => {
    const { ADD_FARM } = useTranslation();

    return (
        <IconTextButton
            className={`${className} ${styles.iconTextButton}`}
            onClick={onClick}
            testId={testId}
            text={ADD_FARM}
        >
            <FarmIcon className={styles.farmIcon} />
        </IconTextButton>
    );
};

export default AddFarmButton;
