import { memo, useCallback, useEffect, useMemo } from 'react';

import {
    DeprecatingTextInput as TextInput,
    DeprecatingWithErrorMessage as WithErrorMessage,
    useValidation
} from '@rantizo-software/rantizo-ui';

import type { FormFieldProps } from 'hooks/useFormState/types';

import useTranslation from './hooks/useTranslation';

import { EMAIL_ADDRESS_REGEX, MAX_CHAR_COUNT, MIN_CHAR_COUNT } from './constants';
import type { Validator } from './types';

const EmailField = <T extends object>(props: FormFieldProps<T>) => {
    const { form, isDisabled = false, isEditable = true, isRequired = false, name } = props;
    const { getFieldError, getValues, handleChange, handleError, handleValid, register } = form;

    const { ref } = register<HTMLInputElement>(name);

    const { INVALID_EMAIL, REQUIRED_ERROR_MESSAGE, TOO_LONG, TOO_SHORT } = useTranslation();

    const { validateMaximumLength, validateMinimumLength, validatePattern, validateRequired } =
        useValidation();

    const validationMap: [Validator, string][] = useMemo(
        () => [
            [validateMaximumLength(MAX_CHAR_COUNT), TOO_LONG],
            [validateMinimumLength(MIN_CHAR_COUNT), TOO_SHORT],
            [validateRequired(isRequired), REQUIRED_ERROR_MESSAGE],
            [validatePattern(EMAIL_ADDRESS_REGEX), INVALID_EMAIL]
        ],
        [
            INVALID_EMAIL,
            REQUIRED_ERROR_MESSAGE,
            TOO_LONG,
            TOO_SHORT,
            isRequired,
            validateMaximumLength,
            validateMinimumLength,
            validatePattern,
            validateRequired
        ]
    );

    const validateValue = useCallback(
        (value: string) => {
            if (!value) {
                handleValid(name);

                return;
            }
            let validationErrorMessage = '';

            const isValid = validationMap.every(([validator, text]) => {
                validationErrorMessage = text;

                return validator(value);
            });

            if (!isValid) {
                handleError(name, { message: validationErrorMessage });
            }

            return isValid;
        },
        [handleError, name, validationMap, handleValid]
    );

    const handleFieldChange = (value: string) => {
        if (!name) {
            return;
        }

        handleChange(name, value);

        if (validateValue(value)) {
            handleValid(name);
        }
    };

    useEffect(() => {
        validateValue(ref.current.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fieldErrors = getFieldError(name);

    return (
        <WithErrorMessage text={fieldErrors ? fieldErrors[0].message : ''}>
            <TextInput
                hasError={!!fieldErrors}
                isDisabled={isDisabled}
                isEditable={isEditable}
                name={name as string}
                onChange={handleFieldChange}
                onError={(message?: string) => handleError(name, { message })}
                ref={ref}
                value={getValues(name) as string}
            />
        </WithErrorMessage>
    );
};

EmailField.displayName = 'EmailField';

export default memo(
    EmailField,
    (prevProps, nextProps) =>
        prevProps.name === nextProps.name &&
        prevProps.form.formErrors === nextProps.form.formErrors &&
        prevProps.isEditable === nextProps.isEditable
) as typeof EmailField;
