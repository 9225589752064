import { CloseButton } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const BackX: FunctionComponent<Props> = ({ className = '', onClick, testId = TEST_ID }) => (
    <CloseButton className={`${className} ${styles.backX}`} onClick={onClick} testId={testId} />
);

export default BackX;
