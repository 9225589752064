import { clone } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { PageContainer } from '@rantizo-software/rantizo-ui';

import Alert from 'components/Alert';
import AlertModal from 'components/AlertModal';
import Jobs from 'components/Jobs';
import ListPageLayout from 'components/ListPageLayout';
import NoItems from 'components/NoItems';
import WithFeatureGate from 'components/WithFeatureGate';
import WorkOrderListPageLayoutHeader from 'components/WorkOrderListPageLayoutHeader';

import useAccount from 'hooks/useAccount';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { ALERT_MODAL_TIMEOUT, FEATURE_WORK_ORDERS, TEST_ID } from './constants';
import type { FunctionComponent, JobsByDate, Props, Tab } from './types';

import styles from './styles.module.scss';

const HomePage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { isOnboardingComplete, user } = useAccount();
    const { isNewOrganization, setIsNewOrganization } = useCurrentOrganization();
    const { fetchData } = useData();
    const navigate = useNavigate();
    const { workOrderPage } = usePageRoutes();
    const { BEGIN_USING, LICENSE_READY, NO_JOBS, ORGANIZATION_CREATED, TITLE } = useTranslation();

    const previousTab = useRef('');
    const [selectedTab, setSelectedTab] = useState<Tab>('upcoming');
    const [jobs, setJobs] = useState<JobsByDate>();
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            const preventRefetchForSameTab = selectedTab === previousTab.current;

            if (preventRefetchForSameTab || !isOnboardingComplete) {
                return;
            }

            const response = await fetchData(selectedTab);

            setJobs(response.data.jobs);
        };

        void fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    const handleAddClick = useCallback(() => {
        navigate(workOrderPage);
    }, [navigate, workOrderPage]);

    const handleAlertClose = useCallback(() => {
        setShowAlert(false);
    }, []);

    const handleTabChange = useCallback((tab: Tab) => {
        setSelectedTab(tabState => {
            previousTab.current = tabState;

            return tab;
        });
    }, []);

    const handleDelete = useCallback((date: string, id: string) => {
        setJobs(currentJobMap => {
            const relevantJobList = currentJobMap ? currentJobMap[date] : [];
            const newJobList = relevantJobList.filter(job => job.id !== id);
            const newJobMap = clone(currentJobMap);

            if (newJobMap && newJobList.length > 0) {
                newJobMap[date] = newJobList;
            } else if (newJobMap) {
                delete newJobMap[date];
            }

            return newJobMap;
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            void setIsNewOrganization(false);
        }, ALERT_MODAL_TIMEOUT);
    }, [setIsNewOrganization]);

    const jobsCount = jobs ? Object.keys(jobs).length : 0;

    return (
        <>
            {isNewOrganization && <AlertModal text={BEGIN_USING} title={ORGANIZATION_CREATED} />}

            <PageContainer testId={testId}>
                <ListPageLayout className={styles.listLayout}>
                    <WorkOrderListPageLayoutHeader
                        onAddClick={handleAddClick}
                        onTabChange={handleTabChange}
                        pageTitle={TITLE(user?.firstName ?? '')}
                    />

                    <WithFeatureGate feature={FEATURE_WORK_ORDERS} />

                    {jobsCount ? (
                        <Jobs
                            items={jobs}
                            onDelete={handleDelete}
                            sortOrder={selectedTab === 'upcoming' ? 'ASC' : 'DESC'}
                        />
                    ) : (
                        <NoItems text={NO_JOBS} />
                    )}
                </ListPageLayout>

                {createPortal(
                    showAlert && (
                        <Alert
                            className={styles.alert}
                            onClose={handleAlertClose}
                            text={LICENSE_READY}
                        />
                    ),
                    document.querySelector('[data-testid=contentContainer]') || document.body
                )}
            </PageContainer>
        </>
    );
};

export default HomePage;
