import { AsAppliedMapRequestFlightModification } from 'generatedTypes/aam/models';
import { useCallback, useEffect, useState } from 'react';

import { convertGeometryToGeoJSON } from '@@utils/mapUtils';
import { FormSectionContainer } from '@rantizo-software/rantizo-ui';
import { area, bbox, bboxPolygon, convex } from '@turf/turf';

import BackNextButtons from 'components/BackNextButtons';
import ErrorMessageText from 'components/ErrorMessageText';
import FlightList from 'components/FlightList';
import SideBarContentContainer from 'components/SideBarContentContainer';
import WarningNotification from 'components/WarningNotification';
import WithLabel from 'components/WithLabel';

import useFeatureAccess from 'hooks/useFeatureAccess';
import useFlightMap from 'hooks/useFlightMap';
import useForm from 'hooks/useForm';

import FlightOverview from 'pages/GenerateAsAppliedMapPage/components/FlightOverview';
import MapProcessorLoader from 'pages/GenerateAsAppliedMapPage/components/MapProcessorLoader';

import useTranslation from './hooks/useTranslation';

import {
    AAM,
    AAM_FLIGHT_BOUNDS_SQ_KM,
    COVERAGE,
    DJI_UPLOADED,
    DRY,
    DRY_COVERAGE,
    MAX_NUM_FLIGHTS,
    TEST_ID,
    XAG_KML_UPLOADED
} from './constants';
import type {
    AsAppliedMapJob,
    AsAppliedMapsRequest,
    FlightLogFormSchema,
    FunctionComponent,
    Geometry,
    MapType,
    Props,
    SelectableFlight
} from './types';

const FlightLogForm: FunctionComponent<Props> = ({
    alternateFlights,
    flights,
    onBack,
    onLoad,
    onMapComplete,
    onUnload,
    productUsageReportId,
    systemOfMeasurement = 'IMPERIAL',
    testId = TEST_ID
}) => {
    const {
        COVERAGE_MAP_WARNING,
        FLIGHT_AREA_VALIDATION_ERROR,
        FLIGHT_SPRAY_VALIDATION_ERROR,
        FLIGHTS_FOUND,
        MAP_ERROR,
        MAX_NUMBER_OF_FLIGHTS_ERROR,
        MULTIPLE_FLIGHT_TYPES_SELECTED,
        SELECT_FLIGHTS
    } = useTranslation();

    const {
        centroidLocation,
        handleMapLoaded,
        handleToggleAlternateFlights,
        handleToggleFlightPath,
        loadFlights,
        mapData,
        mapView,
        selectableFlights,
        setSelectableFlights,
        showAlternateFlights,
        toggleLayerVisibility
    } = useFlightMap(flights, alternateFlights);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isValidArea, setValidArea] = useState<boolean>(false);
    const [hasSprayData, setHasSprayData] = useState<boolean>(false);
    const [onlyOneFlightType, setOnlyOneFlightType] = useState<boolean>(true);
    const [hasMaxFlights, setHasMaxFlights] = useState<boolean>(false);
    const [onlyCoverageMaps, setOnlyCoverageMaps] = useState<boolean>(false);

    const [mapRequest, setMapRequest] = useState<AsAppliedMapsRequest>();

    const formSchema: FlightLogFormSchema = {
        flightData: []
    };

    const form = useForm(formSchema);

    useEffect(() => {
        const loadData = async () => {
            await loadFlights();
            setIsLoading(false);
        };

        onLoad?.();
        loadData();

        return () => {
            onUnload?.();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateOnlyOneFlightType = useCallback((flights: SelectableFlight[]) => {
        const selectedFlights = flights.filter(flight => flight.checked);
        const flightTypes = selectedFlights.map(flight => flight.flightType);

        const numFlightTypes = new Set(flightTypes).size;

        return numFlightTypes === 1 || numFlightTypes === 0;
    }, []);

    const checkIfOnlyCoverageMaps = useCallback((flights: SelectableFlight[]) => {
        const coverageOnlyFlights = flights.filter(
            flight =>
                flight.checked &&
                (flight.flightType === DRY || flight.flightSource === DJI_UPLOADED)
        );

        return coverageOnlyFlights.length > 0;
    }, []);

    useEffect(() => {
        setValidArea(validateArea(selectableFlights));
        setHasSprayData(validateHasSpraydata(selectableFlights));
        setOnlyOneFlightType(validateOnlyOneFlightType(selectableFlights));
        setHasMaxFlights(selectableFlights.filter(f => f.checked).length > MAX_NUM_FLIGHTS);
        setOnlyCoverageMaps(checkIfOnlyCoverageMaps(selectableFlights));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectableFlights]);

    const { handleValid } = form;

    useEffect(() => {
        if (isValidArea) {
            handleValid('flightData')(flights);
        }
    }, [handleValid, isValidArea, flights]);

    const validateHasSpraydata = useCallback((flights: SelectableFlight[]) => {
        if (flights.filter(flight => flight.checked).length === 0) {
            return true;
        }

        return (
            flights
                .filter(flight => flight.checked)
                .filter(flight => flight.totalLitersApplied != 0 || flight.flightType === DRY)
                .length != 0
        );
    }, []);

    const validateArea = useCallback((flights: SelectableFlight[]) => {
        if (flights.filter(flight => flight.checked).length === 0) {
            return true;
        }

        const includedFlights = flights
            .filter(flight => flight.checked)
            .map(flight => flight.flightPath)
            .map(flightPath => ({ geometry: flightPath as Geometry, properties: {} }));

        const pathGeojson = convertGeometryToGeoJSON(includedFlights);

        const bounds = convex(pathGeojson);

        if (bounds) {
            const boundingBox = bboxPolygon(bbox(bounds));

            const convertSquareMetersToSquareKilometers = (squareMeters: number) =>
                squareMeters * 1e-6;

            const boundArea = convertSquareMetersToSquareKilometers(area(boundingBox));

            return boundArea < AAM_FLIGHT_BOUNDS_SQ_KM;
        }

        return true;
    }, []);

    const handleCheckbox = useCallback(
        async ({ index, isChecked }: { index: number; isChecked: boolean }) => {
            setSelectableFlights(flights => {
                const newFlightList = [...flights];

                newFlightList[index] = {
                    ...newFlightList[index],
                    checked: isChecked
                };

                return newFlightList;
            });

            toggleLayerVisibility(mapView, selectableFlights[index].id, isChecked);
        },
        [setSelectableFlights, toggleLayerVisibility, mapView, selectableFlights]
    );

    const handleOverallCheckbox = useCallback(
        async ({ isChecked, isClicked = false }: { isChecked: boolean; isClicked?: boolean }) => {
            if (isClicked) {
                setSelectableFlights(flights =>
                    flights.map(flight => ({ ...flight, checked: isChecked }))
                );

                flights.forEach(({ id }) => toggleLayerVisibility(mapView, id, isChecked));
            }
        },
        [setSelectableFlights, flights, toggleLayerVisibility, mapView]
    );

    const handleBack = useCallback(async () => {
        onBack?.({ form });
    }, [form, onBack]);

    const featureAccess = useFeatureAccess();

    const handleNext = useCallback(async () => {
        const selectedFlights = selectableFlights.filter(f => f.checked);

        let mapTypes = [COVERAGE, AAM] as MapType[];

        if (selectedFlights.some(selectedFlight => selectedFlight.flightType === DRY)) {
            mapTypes = [DRY_COVERAGE] as MapType[];
        } else if (
            selectedFlights.some(
                selectedFlight =>
                    selectedFlight.flightSource === DJI_UPLOADED ||
                    selectedFlight.flightSource === XAG_KML_UPLOADED
            )
        ) {
            mapTypes = [COVERAGE] as MapType[];
        }

        if (!featureAccess['asAppliedMaps']) {
            mapTypes = [COVERAGE] as MapType[];
        }

        const selectedFlightIds = selectableFlights.filter(f => f.checked).map(f => f.id);

        const request = {
            flightIds: selectedFlightIds,
            flightModification: showAlternateFlights.current
                ? AsAppliedMapRequestFlightModification.REMOVE_FERRYING_LINES
                : undefined,
            productUsageReportId: productUsageReportId,
            systemOfMeasurement: systemOfMeasurement,
            types: mapTypes
        };

        setMapRequest(request);
    }, [
        featureAccess,
        productUsageReportId,
        selectableFlights,
        showAlternateFlights,
        systemOfMeasurement
    ]);

    const onMapLoadComplete = useCallback(
        (job: AsAppliedMapJob) => {
            onMapComplete(job);
        },
        [onMapComplete]
    );

    const onError = useCallback(() => {
        // TODO alert the user
        alert(MAP_ERROR);
    }, [MAP_ERROR]);

    return (
        <SideBarContentContainer testId={testId}>
            {mapRequest && (
                <MapProcessorLoader
                    mapRequest={mapRequest}
                    onLoadComplete={onMapLoadComplete}
                    onLoadFail={onError}
                />
            )}

            {!mapRequest && (
                <FormSectionContainer>
                    <FormSectionContainer>
                        <FlightOverview
                            centroidLocation={centroidLocation}
                            data={mapData}
                            isLoading={isLoading}
                            onAlternatePathClick={handleToggleAlternateFlights}
                            onLoad={handleMapLoaded}
                            onPathClick={handleToggleFlightPath}
                        />

                        <WithLabel
                            isRequired
                            secondaryText={`${flights.length} ${FLIGHTS_FOUND}`}
                            text={SELECT_FLIGHTS}
                        >
                            <FlightList
                                items={selectableFlights}
                                onCheckAll={handleOverallCheckbox}
                                onCheckboxChange={handleCheckbox}
                            />
                        </WithLabel>
                    </FormSectionContainer>

                    {!hasSprayData && <ErrorMessageText text={FLIGHT_SPRAY_VALIDATION_ERROR} />}

                    {!isValidArea && <ErrorMessageText text={FLIGHT_AREA_VALIDATION_ERROR} />}

                    {!onlyOneFlightType && (
                        <ErrorMessageText text={MULTIPLE_FLIGHT_TYPES_SELECTED} />
                    )}

                    {hasMaxFlights && (
                        <ErrorMessageText text={MAX_NUMBER_OF_FLIGHTS_ERROR(MAX_NUM_FLIGHTS)} />
                    )}

                    {onlyCoverageMaps && (
                        <WarningNotification testId={testId} text={COVERAGE_MAP_WARNING} />
                    )}

                    <BackNextButtons
                        isNextDisabled={
                            !isValidArea || !hasSprayData || !onlyOneFlightType || hasMaxFlights
                        }
                        isNextLoading={false}
                        onBack={handleBack}
                        onNext={handleNext}
                    />
                </FormSectionContainer>
            )}
        </SideBarContentContainer>
    );
};

export default FlightLogForm;
