import { forwardRef, useCallback, useMemo } from 'react';

import {
    DeprecatingDropdown as Dropdown,
    DeprecatingWithErrorMessage as WithErrorMessage
} from '@rantizo-software/rantizo-ui';

import useFormError from 'hooks/useFormError';

import { DROPDOWN_ITEMS, TEST_ID } from './constants';
import type { OnError, Props } from './types';

const YearsOfServiceDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isDisabled = false,
            isEditable,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            placeholder,
            selectedValue,
            testId = TEST_ID
        },
        ref
    ) => {
        const { clearError, errorMessage, handleError } = useFormError(onError);

        const defaultSelectedIndex = useMemo(() => {
            const index = DROPDOWN_ITEMS.findIndex(item => item.value === selectedValue);

            if (index === -1) {
                return undefined;
            }

            return index;
        }, [selectedValue]);

        const handleChange = useCallback(
            (_selectedValue: unknown, _handleError: OnError, index: number) => {
                onChange?.(DROPDOWN_ITEMS[index].value);
                clearError?.();
            },
            [onChange, clearError]
        );

        const hasAnError = Boolean(hasError || errorMessage);

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasAnError}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    isRequired={isRequired}
                    items={DROPDOWN_ITEMS}
                    label={label}
                    onChange={handleChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    placeholder={placeholder}
                    ref={ref}
                    selected={defaultSelectedIndex}
                    testId={testId}
                />
            </WithErrorMessage>
        );
    }
);

YearsOfServiceDropdown.displayName = 'YearsOfServiceDropdown';

export default YearsOfServiceDropdown;
