import { Field, FieldArray, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { round } from '@@utils/math';
import { rem } from '@mantine/core';
import { BodyText, FormSectionContainer, SplitContainer } from '@rantizo-software/rantizo-ui';
import { area } from '@turf/turf';

import TextWithLabel from 'components/TextWithLabel';
import UnitLabel from 'components/UnitLabel';
import WithAddButton from 'components/WithAddButton';
import WithLabel from 'components/WithLabel';
import WithLabelAndInfo from 'components/WithLabelAndInfo';
import WithRemoveButton from 'components/WithRemoveButton';
import FormInput from 'components/deprecating/FormInput';
import FormMultiSelect from 'components/deprecating/FormMultiSelect';
import FormNumberInput from 'components/deprecating/FormNumberInput';
import FormFieldWithUnit from 'components/form/FormFieldWithUnit';

import useConvert from 'hooks/useConvert';

import useCalculations from './hooks/useCalculations';
import useTranslation from './hooks/useTranslation';

import { MAXIMUM_INVOICE_ITEMS, TEST_ID } from './constants';
import type {
    FunctionComponent,
    InvoiceItem,
    Props,
    RawApplicationSite,
    Unit,
    WorkOrderAcreageUnit,
    WorkOrderForm
} from './types';

import styles from './styles.module.scss';

const fieldApplicationRateOptions = [
    { label: '$ / Ac', value: 'PER_ACRE' },
    { label: '$ / Hr', value: 'PER_HOUR' }
];

const taxAmountOptions = [
    { label: 'USD', value: 'FLAT_RATE' },
    { label: '%', value: 'PERCENTAGE' }
];

const InvoiceAssistant: FunctionComponent<Props> = ({
    isEditable,
    productUsageReports = [],
    testId = TEST_ID
}) => {
    const { calculateEstimatedTotal } = useCalculations();
    const {
        ACRES,
        APPLICATION_SITE,
        APPLIED_ACRES,
        APPLIED_ACRES_TOOLTIP,
        CHEMICAL_COST,
        ESTIMATED_TOTAL,
        FIELD_APPLICATION_FLAT_FEE,
        FIELD_APPLICATION_RATE,
        HOURS,
        HOURS_WORKED,
        NOT_PROVIDED,
        OPTIONAL,
        OTHER,
        PROPOSED_ACRES,
        TAX_AMOUNT,
        TAX_TOOLTIP,
        USD
    } = useTranslation();

    const { convertAreaToAcres } = useConvert();
    const {
        setFieldValue,
        values: {
            applicationSites,
            appliedAcres,
            chemicalCost,
            fieldApplicationFlatFee,
            fieldApplicationHours,
            fieldApplicationRate,
            fieldApplicationRateUnit,
            invoiceItems,
            invoicedApplicationSites,
            otherDescription,
            otherFee,
            proposedAcres,
            proposedAcresUnit,
            taxAmount,
            taxAmountUnit
        }
    } = useFormikContext<WorkOrderForm>();

    const [purSites, setPurSites] = useState<RawApplicationSite[]>([]);

    const applicationSiteValues = useMemo(
        () =>
            applicationSites.reduce((acc, { id, siteName }) => {
                acc.push({
                    label: siteName,
                    value: id ?? siteName ?? ''
                });

                return acc;
            }, [] as Unit[]),
        [applicationSites]
    );

    const proposedAcresText = useMemo(() => {
        if (!proposedAcres) {
            return NOT_PROVIDED;
        }

        const convertedAcres = convertAreaToAcres(
            proposedAcres,
            proposedAcresUnit as WorkOrderAcreageUnit
        );

        return `${convertedAcres} ${ACRES}`;
    }, [ACRES, NOT_PROVIDED, convertAreaToAcres, proposedAcres, proposedAcresUnit]);

    const handleAddInvoiceItem = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (push: any) => {
            push({ amountDollars: otherFee, description: otherDescription });
            setFieldValue('otherDescription', '');
            setFieldValue('otherFee', undefined);
        },
        [setFieldValue, otherDescription, otherFee]
    );

    useEffect(() => {
        const deDupedSites = productUsageReports.reduce((acc, { applicationSites }) => {
            for (const site of applicationSites) {
                if (!acc.find(currentSite => currentSite.id === site.id)) {
                    acc.push(site);
                }
            }

            return acc;
        }, [] as RawApplicationSite[]);

        setPurSites(deDupedSites);
    }, [productUsageReports]);

    useEffect(() => {
        if (!appliedAcres) {
            const acres = invoicedApplicationSites.reduce((acc, invoicedSite) => {
                const purSite = purSites.find(({ id }) => id === invoicedSite);

                if (purSite) {
                    const purAreaMeters = area(purSite.boundary);
                    const purAreaAcres = convertAreaToAcres(purAreaMeters, 'm2');

                    return acc + round(purAreaAcres, 2);
                }

                return acc;
            }, 0);

            setFieldValue('appliedAcres', acres);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicedApplicationSites]);

    useEffect(() => {
        const estimatedTotal = calculateEstimatedTotal(
            appliedAcres,
            chemicalCost,
            fieldApplicationFlatFee,
            fieldApplicationHours,
            fieldApplicationRate,
            fieldApplicationRateUnit,
            invoiceItems,
            otherDescription,
            otherFee,
            taxAmount,
            taxAmountUnit
        );

        setFieldValue('estimatedTotal', estimatedTotal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        appliedAcres,
        chemicalCost,
        fieldApplicationFlatFee,
        fieldApplicationHours,
        fieldApplicationRate,
        fieldApplicationRateUnit,
        invoiceItems,
        otherDescription,
        otherFee,
        taxAmount,
        taxAmountUnit
    ]);

    return (
        <FormSectionContainer testId={testId}>
            <TextWithLabel label={PROPOSED_ACRES} text={proposedAcresText} />

            <WithLabelAndInfo
                className={styles.appliedAcresFieldLabel}
                text={APPLIED_ACRES}
                tooltipText={APPLIED_ACRES_TOOLTIP}
            >
                <Field
                    component={FormInput}
                    isEditable={isEditable}
                    name="appliedAcres"
                    placeholder={OPTIONAL}
                />
            </WithLabelAndInfo>

            <Field
                component={FormMultiSelect}
                data={applicationSiteValues}
                isEditable={isEditable}
                label={APPLICATION_SITE}
                name="invoicedApplicationSites"
                searchable
            />

            <Field
                component={FormNumberInput}
                isEditable={isEditable}
                label={FIELD_APPLICATION_FLAT_FEE}
                name="fieldApplicationFlatFee"
                precision={2}
                rightSection={<UnitLabel label={USD} />}
                rightSectionWidth={rem('50px')}
            />

            <FormFieldWithUnit
                fieldComponent={FormNumberInput}
                fieldLabel={FIELD_APPLICATION_RATE}
                fieldName="fieldApplicationRate"
                isEditable={isEditable}
                unitName="fieldApplicationRateUnit"
                unitValues={fieldApplicationRateOptions}
            />

            {fieldApplicationRateUnit === 'PER_HOUR' && (
                <Field
                    component={FormNumberInput}
                    isEditable={isEditable}
                    label={HOURS_WORKED}
                    name="fieldApplicationHours"
                    precision={2}
                    rightSection={<UnitLabel label={HOURS} />}
                    rightSectionWidth={rem('50px')}
                />
            )}

            <Field
                component={FormNumberInput}
                isEditable={isEditable}
                label={CHEMICAL_COST}
                name="chemicalCost"
                precision={2}
                rightSection={<UnitLabel label={USD} />}
                rightSectionWidth={rem('50px')}
            />

            <FieldArray name="invoiceItems">
                {({ push, remove }) => (
                    <WithLabel text={OTHER}>
                        {invoiceItems?.map(
                            ({ amountDollars, description }: InvoiceItem, index: number) => {
                                const item = (
                                    <SplitContainer className={styles.splitContainer} key={index}>
                                        <BodyText
                                            className={styles.description}
                                            text={description}
                                        />

                                        <BodyText
                                            className={styles.fee}
                                            text={String(amountDollars)}
                                        />
                                    </SplitContainer>
                                );

                                if (isEditable) {
                                    return (
                                        <WithRemoveButton key={index} onClick={() => remove(index)}>
                                            {item}
                                        </WithRemoveButton>
                                    );
                                }

                                return item;
                            }
                        )}

                        {isEditable && invoiceItems.length < MAXIMUM_INVOICE_ITEMS && (
                            <WithAddButton
                                isDisabled={!otherDescription || !otherFee}
                                onClick={() => handleAddInvoiceItem(push)}
                            >
                                <SplitContainer>
                                    <Field
                                        className={styles.otherDescription}
                                        component={FormInput}
                                        isEditable={isEditable}
                                        name="otherDescription"
                                    />

                                    <Field
                                        className={styles.otherFee}
                                        component={FormNumberInput}
                                        isEditable={isEditable}
                                        name="otherFee"
                                        precision={2}
                                        rightSection={<UnitLabel label={USD} />}
                                        rightSectionWidth={rem('50px')}
                                    />
                                </SplitContainer>
                            </WithAddButton>
                        )}
                    </WithLabel>
                )}
            </FieldArray>

            <WithLabelAndInfo
                className={styles.taxFieldLabel}
                text={TAX_AMOUNT}
                tooltipText={TAX_TOOLTIP}
            >
                <FormFieldWithUnit
                    fieldComponent={FormNumberInput}
                    fieldName="taxAmount"
                    isEditable={isEditable}
                    unitName="taxAmountUnit"
                    unitValues={taxAmountOptions}
                />
            </WithLabelAndInfo>

            <Field
                component={FormNumberInput}
                isEditable={false}
                label={ESTIMATED_TOTAL}
                name="estimatedTotal"
                precision={2}
                rightSection={<UnitLabel label={USD} />}
                rightSectionWidth={rem('50px')}
            />
        </FormSectionContainer>
    );
};

export default InvoiceAssistant;
