import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('operatorMapServiceDetailsForm');

    return {
        ACRES_FLOWN: t('acresFlown'),
        DRONE_AERIAL_APPLICATION: t('droneAerialApplication'),
        DRONE_IMAGERY: t('droneImagery'),
        HOURS_FLOWN: t('hoursFlown'),
        INSURANCE_LABEL: t('insuranceLabel'),
        PART_137_LABEL: t('part137Label'),
        SERVICE_DETAILS: t('serviceDetails'),
        SERVICES_OFFERED: t('servicesOffered'),
        TELL_US_ABOUT_YOUR_BUISNESS: t('tellUsAboutYourBuisness'),
        YEARS_IN_SERVICE: t('yearsInService'),
        YES_CHECKMARK_LABEL: t('yesCheckmarkLabel')
    };
};

export default useTranslation;
