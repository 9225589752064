import { type FunctionComponent, useEffect } from 'react';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from '@rantizo-software/rantizo-ui';

import useAccount from 'hooks/useAccount';
import useLogout from 'hooks/useLogout';
import usePageRoutes from 'hooks/usePageRoutes';

const AuthChecker: FunctionComponent = () => {
    const navigate = useNavigate();
    const { isLoggingOut } = useLogout();
    const { isAuthenticated, isLoading } = useAuth0();
    const {
        hasCurrentOrganization,
        isAccountComplete,
        isEmailVerified,
        isOnboardingComplete,
        user
    } = useAccount();
    const {
        accountLoginPage,
        accountPage,
        homePage,
        verifyAccountPage,
        verifyEmailPage,
        verifyOrganizationPage
    } = usePageRoutes();
    const loginMatch = useMatch(accountLoginPage);
    const onboardingMatch = useMatch('/verify/*');
    const orgMatch = useMatch('/verify/organization/*');

    useEffect(() => {
        if (isAuthenticated && !isLoggingOut && user !== undefined) {
            if ((isOnboardingComplete && onboardingMatch) || loginMatch) {
                navigate(homePage);
            } else if (!isEmailVerified) {
                navigate(verifyEmailPage);
            } else if (!isAccountComplete && user) {
                navigate(verifyAccountPage);
            } else if (!hasCurrentOrganization) {
                if (!orgMatch) {
                    navigate(verifyOrganizationPage);
                }
            }
        }
    }, [
        accountPage,
        hasCurrentOrganization,
        homePage,
        isAccountComplete,
        isAuthenticated,
        isEmailVerified,
        isLoggingOut,
        isOnboardingComplete,
        loginMatch,
        navigate,
        onboardingMatch,
        orgMatch,
        user,
        verifyAccountPage,
        verifyEmailPage,
        verifyOrganizationPage
    ]);

    const showLoader = isLoading || isLoggingOut || user === undefined;

    if (showLoader) {
        return <PageLoader />;
    }

    if (isAuthenticated || loginMatch) {
        return <Outlet />;
    }

    return <Navigate replace to={accountLoginPage} />;
};

export default AuthChecker;
