import { Outlet } from 'react-router-dom';

import { Content } from '@rantizo-software/rantizo-ui';

import Toast from 'components/Toast';

import type { FunctionComponent } from './types';

import styles from './styles.module.scss';

const SimpleLayout: FunctionComponent = () => (
    <div className={styles.simpleLayout}>
        <Toast />

        <Content className={styles.content}>
            <Outlet />
        </Content>
    </div>
);

export default SimpleLayout;
