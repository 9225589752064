export const AREA_FLOWN_KEY = 'areaFlown';

export const DESCRIPTION_KEY = 'description';

export const HAS_PART_137_KEY = 'hasPart137';

export const HOURS_FLOWN_KEY = 'hoursFlown';

export const IS_INSURED_KEY = 'isInsured';

export const SERVICES_KEY = 'services';

export const TEST_ID = 'operatorMapServiceDetailsForm';

export const YEARS_OF_SERVICE_KEY = 'yearsOfService';

export const PAGE_SIZE = 25;
