import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { equipmentSortNickname } from '@@utils/sort';
import {
    InfiniteScroll,
    PageLoader,
    PrimaryButton,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import ControllerCard from 'components/ControllerCard';
import NoControllerCard from 'components/NoControllerCard';

import useControllers from 'hooks/useControllers';
import usePageRoutes from 'hooks/usePageRoutes';
import useRoles from 'hooks/useRoles';

import useTranslation from './hooks/useTranslation';

import type { Controller, ControllerApiResponse, FunctionComponent, Key } from './types';

import styles from './styles.module.scss';

const ControllerList: FunctionComponent = () => {
    const { canManageEquipment } = useRoles();
    const { deleteController, fetchControllers } = useControllers();
    const navigate = useNavigate();
    const { organizationNewController } = usePageRoutes();
    const [controllers, setControllers] = useState<ControllerApiResponse>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });
    const [loading, setLoading] = useState(true);
    const { BUTTON } = useTranslation();

    const fetchControllerData = useCallback(
        async (hasMore: boolean, nextToken: string) => {
            const response = await fetchControllers(hasMore ? nextToken : undefined);

            const { hasNext, nextPageToken, objects: newControllers } = response;

            setControllers((currentState: ControllerApiResponse) => {
                const newState = [...currentState.objects, ...newControllers];

                newState.sort(equipmentSortNickname);

                return { hasNext, nextPageToken, objects: newState };
            });
        },
        [fetchControllers, setControllers]
    );

    const handleAddController = useCallback(() => {
        navigate(organizationNewController);
    }, [navigate, organizationNewController]);

    const noController = useMemo(
        () => controllers.objects.length === 0,
        [controllers.objects.length]
    );

    useEffect(() => {
        const initialControllerFetch = async () => {
            await fetchControllerData(false, '');
        };

        if (loading) {
            initialControllerFetch();
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const deleteAndRefresh = useCallback(
        async (id: string) => {
            await deleteController(id);

            setControllers((currentControllers: ControllerApiResponse) => ({
                hasNext: currentControllers.hasNext,
                nextPageToken: currentControllers.nextPageToken,
                objects: currentControllers.objects.filter(
                    (currentController: Controller) => currentController.id !== id
                )
            }));
        },
        [setControllers, deleteController]
    );

    return (
        <VerticalContainer className={styles.controllerList}>
            {loading && <PageLoader />}

            {noController && !loading && <NoControllerCard />}

            {!noController && (
                <InfiniteScroll
                    fetchNextPage={async () =>
                        await fetchControllerData(controllers.hasNext, controllers.nextPageToken)
                    }
                    className={styles.list}
                    hasNextPage={controllers.hasNext}
                    loader={<PageLoader />}
                >
                    {controllers.objects.map(
                        (controller: Controller, index: Key | null | undefined) => (
                            <ControllerCard
                                controller={controller}
                                deleteController={() => deleteAndRefresh(controller.id)}
                                key={index}
                            />
                        )
                    )}
                </InfiniteScroll>
            )}

            {canManageEquipment && (
                <PrimaryButton
                    className={styles.button}
                    onClick={handleAddController}
                    text={BUTTON}
                />
            )}
        </VerticalContainer>
    );
};

export default ControllerList;
