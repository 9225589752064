import useGlobalStore from 'hooks/useGlobalStore';

import { TIME } from './constants';
import type { Notification, NotificationSetter } from './types';

export const useToast = () => {
    const { useGetValue, useSetValue } = useGlobalStore<Notification | null>({
        namespace: 'useToast'
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toastData = () => useGetValue('toast');

    const setToastData = useSetValue('toast') as NotificationSetter;

    const broadcastToast = ({ text, time = TIME, type }: Notification & { time?: number }) => {
        setToastData({ text, type });
        setTimeout(() => setToastData(null), time);
    };

    return { broadcastToast, setToastData, toastData };
};

export default useToast;
