import { memo, useCallback, useEffect, useMemo } from 'react';

import {
    DeprecatingWithErrorMessage as WithErrorMessage,
    useValidation
} from '@rantizo-software/rantizo-ui';

import ExemptionNumberInput from 'components/form/ExemptionNumberInput';

import useTranslation from './hooks/useTranslation';

import { EXEMPTION_NUMBER_REGEX, MAX_CHAR_COUNT } from './constants';
import type { FormFieldProps, Validator } from './types';

const ExemptionNumberField = <T extends object>(props: FormFieldProps<T>) => {
    const { form, isDisabled = false, isEditable = true, isRequired = false, name } = props;
    const { getFieldError, getValues, handleChange, handleError, handleValid, register } = form;

    const { ref } = register<HTMLInputElement>(name);

    const { EXEMPTION_NUMBER_ERROR_MESSAGE, MAX_EXCEEDED_ERROR_MESSAGE, REQUIRED_ERROR_MESSAGE } =
        useTranslation();

    const { validateMaximumLength, validatePattern, validateRequired } = useValidation();

    const validationMap: [Validator, string][] = useMemo(
        () => [
            [validateMaximumLength(MAX_CHAR_COUNT), MAX_EXCEEDED_ERROR_MESSAGE],
            [validatePattern(EXEMPTION_NUMBER_REGEX), EXEMPTION_NUMBER_ERROR_MESSAGE],
            [validateRequired(isRequired), REQUIRED_ERROR_MESSAGE]
        ],
        [
            EXEMPTION_NUMBER_ERROR_MESSAGE,
            MAX_EXCEEDED_ERROR_MESSAGE,
            REQUIRED_ERROR_MESSAGE,
            isRequired,
            validateMaximumLength,
            validatePattern,
            validateRequired
        ]
    );

    const validateValue = useCallback(
        (value: string) => {
            let validationErrorMessage = '';

            const isValid = validationMap.every(([validator, text]) => {
                validationErrorMessage = text;

                return validator(value);
            });

            if (!isValid) {
                handleError(name, { message: validationErrorMessage });
            }

            return isValid;
        },
        [handleError, name, validationMap]
    );

    const handleFieldChange = (value: string) => {
        if (!name) {
            return;
        }

        handleChange(name, value);

        if (validateValue(value)) {
            handleValid(name);
        }
    };

    useEffect(() => {
        validateValue(ref.current.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fieldErrors = getFieldError(name);

    return (
        <WithErrorMessage text={fieldErrors ? fieldErrors[0].message : ''}>
            <ExemptionNumberInput
                hasError={!!fieldErrors}
                isDisabled={isDisabled}
                isEditable={isEditable}
                name={name as string}
                onChange={handleFieldChange}
                onError={(message: string) => handleError(name, { message })}
                ref={ref}
                value={getValues(name) as string}
            />
        </WithErrorMessage>
    );
};

ExemptionNumberField.displayName = 'ExemptionNumberField';

export default memo(
    ExemptionNumberField,
    (prevProps, nextProps) =>
        prevProps.name === nextProps.name &&
        prevProps.form.formErrors === nextProps.form.formErrors &&
        prevProps.isEditable === nextProps.isEditable
) as typeof ExemptionNumberField;
