import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useCustomers from 'hooks/useCustomers';
import usePageRoutes from 'hooks/usePageRoutes';

const useData = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { deleteCustomer } = useCustomers();

    const { customersPage } = usePageRoutes();

    const navigate = useNavigate();

    const handleDelete = useCallback(
        async (customerId: string) => {
            try {
                await deleteCustomer(customerId);
                setIsOpen(false);
                navigate(customersPage);
            } catch (e) {
                console.error(e);
            }
        },
        [customersPage, deleteCustomer, navigate]
    );

    return {
        handleDelete,
        isOpen,
        setIsOpen
    };
};

export default useData;
