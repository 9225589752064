import type { GetOperatorServicesParams } from 'generatedTypes/operator-map/models';
import { getGetOperatorServicesUrl } from 'generatedTypes/operator-map/services/operator-services-controller';
import { useCallback } from 'react';

import useFetch from 'hooks/useFetch';

const useServices = () => {
    const { authenticatedGet } = useFetch();

    const fetchServices = useCallback(
        async (params: GetOperatorServicesParams) => {
            const url = getGetOperatorServicesUrl(params);
            const { data, error } = await authenticatedGet(url);

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedGet]
    );

    return {
        fetchServices
    };
};

export default useServices;
