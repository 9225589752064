import { FlightQueryFlightModification } from 'generatedTypes/aam/models';
import { useCallback, useState } from 'react';

import useFlights from 'hooks/useFlights';
import type { Flight } from 'hooks/useFlights/types';

const useData = () => {
    const { fetchFlight, fetchFlightsForAAM } = useFlights();

    const [flights, setFlights] = useState<Flight[]>([]);
    const [alternateFlights, setAlternateFlights] = useState<Flight[]>([]);

    const fetchFlights = useCallback(
        async (flightIds: string[], modification?: FlightQueryFlightModification) =>
            await Promise.all(
                flightIds.map(async item => {
                    const flight = await fetchFlight(item, modification);

                    return flight;
                })
            ),
        [fetchFlight]
    );

    const loadFlights = useCallback(
        async (aamId: string) => {
            const flightIds = await fetchFlightsForAAM({ aamId });

            const { data, error } = flightIds;

            if (error) {
                throw new Error(JSON.stringify(error));
            }

            const fetchedFlights = await fetchFlights(data || []);

            const alternateFlights = await fetchFlights(
                data || [],
                FlightQueryFlightModification.REMOVE_FERRYING_LINES
            );

            setFlights(fetchedFlights);
            setAlternateFlights(alternateFlights);
        },
        [fetchFlightsForAAM, fetchFlights]
    );

    return {
        alternateFlights,
        flights,
        loadFlights
    };
};

export default useData;
