import { useCallback } from 'react';

const useDeduplicate = () => {
    const deduplicate = useCallback(
        (items: (string | number | boolean)[]) => Array.from(new Set(items)),
        []
    );

    return {
        deduplicate
    };
};

export default useDeduplicate;
