import { useCallback, useEffect, useRef, useState } from 'react';

import {
    AddressSearchInput,
    ArrowRightCircleButton,
    BodyText,
    ContentText,
    HorizontalContainer,
    Map,
    MapMarker,
    MapPin,
    MapPinInfoWindow,
    PrimaryButton,
    VerticalContainer,
    useAdvancedMarkerRef,
    useGeocoding
} from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { LOCATION_KEY, PLACE_ID_KEY, SEARCH_INPUT_KEY, TEST_ID } from './constants';
import type { FunctionComponent, LatLongObject, Point, Props } from './types';

import styles from './styles.module.scss';

const OperatorMapLocationDetailsForm: FunctionComponent<Props> = ({
    form,
    isDisabled = false,
    isEditable = true,
    setActivePage,
    testId = TEST_ID
}) => {
    const addressSearchInputRef = useRef<HTMLInputElement | null>(null);
    const [center, setCenter] = useState<LatLongObject>();
    const { geocode } = useGeocoding();

    const { BUTTON_TEXT, MAP_PIN_INFO_WINDOW_BODY_TEXT } = useTranslation();

    const [searchInput, setSerachInput] = useState(form.getValues(SEARCH_INPUT_KEY) as string);

    const [point, setPoint] = useState<Point>(form.getValues(LOCATION_KEY) as Point);

    useEffect(() => {
        if (!navigator.geolocation) {
            console.error('Geolocation is not supported by this browser.');
        }

        if (!center) {
            if (!point) {
                navigator.geolocation.getCurrentPosition(
                    pos => {
                        setCenter({
                            lat: pos.coords.latitude,
                            lng: pos.coords.longitude
                        });
                    },
                    error => {
                        console.error(error.message);
                    }
                );
            } else {
                setCenter({
                    lat: point.coordinates[1],
                    lng: point.coordinates[0]
                });
            }
        }
    }, [center, point]);

    const handleKeyUp = useCallback(
        (geocoding?: google.maps.GeocoderResult) => {
            if (geocoding) {
                const geocodedPoint: Point = {
                    coordinates: [
                        geocoding.geometry.location.lng(),
                        geocoding.geometry.location.lat()
                    ],
                    type: 'Point'
                };

                setPoint(geocodedPoint);

                setCenter({
                    lat: geocoding.geometry.location.lat(),
                    lng: geocoding.geometry.location.lng()
                });

                form.handleChange(PLACE_ID_KEY, geocoding?.place_id);
                form.handleChange(SEARCH_INPUT_KEY, geocoding?.formatted_address);
                form.handleChange(LOCATION_KEY, geocodedPoint);
                setSerachInput(geocoding?.formatted_address ?? '');
            }
        },
        [form]
    );

    const handleButtonClick = async () => {
        const address = addressSearchInputRef?.current?.value;

        if (!address) {
            return;
        }
        const geocoding = await geocode(address);

        handleKeyUp(geocoding);
    };

    const [markerRef, marker] = useAdvancedMarkerRef();

    return (
        <div className={styles.operatorMapLocationDetailsForm} data-testid={testId}>
            <Map center={center} key={center?.lat} mapId={import.meta.env.VITE_GOOGLE_MAP_ID}>
                {point && searchInput && (
                    <VerticalContainer data-testid={testId}>
                        <MapMarker
                            position={{
                                lat: point?.coordinates[1],
                                lng: point?.coordinates[0]
                            }}
                            ref={markerRef}
                            title="testTitle"
                        >
                            <MapPin />
                        </MapMarker>

                        <MapPinInfoWindow
                            headerContent={
                                <BodyText
                                    className={styles.bodyText}
                                    text={MAP_PIN_INFO_WINDOW_BODY_TEXT}
                                />
                            }
                            position={{
                                lat: point?.coordinates[1],
                                lng: point?.coordinates[0]
                            }}
                            marker={marker}
                        >
                            <ContentText text={searchInput} />

                            <PrimaryButton
                                className={styles.primaryButton}
                                onClick={() => setActivePage('serviceDetails')}
                                text={BUTTON_TEXT}
                            />
                        </MapPinInfoWindow>
                    </VerticalContainer>
                )}
            </Map>

            <HorizontalContainer className={styles.horizontalContainer}>
                <AddressSearchInput
                    inputClassName={styles.addressSearchInput}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    onKeyUp={handleKeyUp}
                    ref={addressSearchInputRef}
                    value={searchInput}
                />

                <ArrowRightCircleButton
                    className={styles.arrowRightCircleButton}
                    iconClassName={styles.arrowRightCircleIcon}
                    onClick={handleButtonClick}
                />
            </HorizontalContainer>
        </div>
    );
};

export default OperatorMapLocationDetailsForm;
