import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { PageLoader } from '@rantizo-software/rantizo-ui';

import useOperators from 'hooks/useOperators';

import OperatorTileHomePage from 'pages/OperatorTileHomePage';
import OperatorTileSignUpFlowPage from 'pages/OperatorTileSignUpFlowPage';

import { DEFAULT_SIZE } from './constants';
import type { FunctionComponent } from './types';

const OperatorTilePage: FunctionComponent = () => {
    const { fetchAllOperators, loading, operators } = useOperators();

    const location = useLocation();
    const source = location.state?.source;
    const isEdit = location.pathname.includes('/edit');

    useEffect(() => {
        const params = { pageable: { size: DEFAULT_SIZE } };

        fetchAllOperators(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <PageLoader />;
    }

    const pageComponents = {
        edit: <OperatorTileSignUpFlowPage isEdit={true} />,
        home: <OperatorTileHomePage operators={operators ? operators?.objects : []} />,
        signup: <OperatorTileSignUpFlowPage isEdit={false} />
    };

    let componentToRender;

    if (isEdit) {
        componentToRender = pageComponents.edit;
    } else if (operators?.objects?.length && source !== 'add-location') {
        componentToRender = pageComponents.home;
    } else {
        componentToRender = pageComponents.signup;
    }

    return componentToRender;
};

export default OperatorTilePage;
