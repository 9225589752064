import { useCallback, useState } from 'react';

import { useVerifyAccountForm } from '@@state/Onboarding';
import { PageContainer, PageLoader } from '@rantizo-software/rantizo-ui';

import SimpleHeader from 'components/SimpleHeader';
import UserDetailsForm from 'components/UserDetailsForm';

import useAccount from 'hooks/useAccount';

import useTranslation from './hooks/useTranslation';
import useVerifyAccount from './hooks/useVerifyAccount';

import { TEST_ID } from './constants';
import type { EditUserData, FunctionComponent, Props } from './types';

const VerifyAccountPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { user } = useAccount();
    const { BUTTON, TITLE } = useTranslation();
    const verifyAccount = useVerifyAccount();
    const [formValues, setFormValues] = useVerifyAccountForm();
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
        async (values: EditUserData) => await verifyAccount(values),
        [verifyAccount]
    );

    const onSubmit = useCallback(
        async (values: EditUserData) => {
            setLoading(true);
            setFormValues(values);
            await handleSubmit(values);
            setLoading(false);
        },
        [setFormValues, handleSubmit]
    );

    if (user === undefined) {
        return <PageLoader />;
    }

    return (
        <PageContainer>
            <SimpleHeader />

            <UserDetailsForm
                buttonText={BUTTON}
                initialValues={formValues}
                isLoading={loading}
                isOnboarding={true}
                onSubmit={onSubmit}
                testId={testId}
                title={TITLE}
            />
        </PageContainer>
    );
};

export default VerifyAccountPage;
