import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import type { RedirectLoginOptions } from '@auth0/auth0-react/dist/auth0-context';

import usePageRoutes from 'hooks/usePageRoutes';

const SignupPage = () => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const { tag } = useParams();
    const navigate = useNavigate();
    const { homePage } = usePageRoutes();

    const unauthenticatedOptions = useRef<RedirectLoginOptions>({
        authorizationParams: {
            registration_tag: tag,
            screen_hint: 'signup'
        }
    });

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect(unauthenticatedOptions.current);
        } else if (!isLoading && isAuthenticated) {
            navigate(homePage);
        }
    }, [homePage, isAuthenticated, isLoading, loginWithRedirect, navigate]);

    return null;
};

export default SignupPage;
