import { useRecoilValue, useSetRecoilState } from 'recoil';

import store from 'app/store';

import type { Params, RecoilState, UseGetValue, UseRecoilHook, UseSetValue } from './types';

const useRecoil: UseRecoilHook = <T>({ namespace }: Params) => {
    if (!store) {
        throw new Error('This hook must be used within a GlobalStoreRoot');
    }

    const useGetValue: UseGetValue<T> = (type: string): T => {
        const atom: RecoilState<T> = store[namespace].atoms[`${type}Atom`];

        return useRecoilValue(atom);
    };

    const useSetValue: UseSetValue<T> = (type: string): ((value: T) => void) => {
        const atom: RecoilState<T> = store[namespace].atoms[`${type}Atom`];

        return useSetRecoilState<T>(atom);
    };

    return {
        store,
        useGetValue,
        useSetValue
    };
};

export default useRecoil;
