import type { Implement } from './types';

const implement: Implement = functionality => () => {
    console.log(
        `%c 🔴 implement ${functionality} `,
        `
        background-color: yellow; 
        color: black;
    `
    );
};

export default implement;
