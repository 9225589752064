import {
    AddressLineItem,
    FarmIcon,
    FieldIcon,
    HeadingText,
    IconWithCount,
    ItemContainer,
    PhoneNumberLineItem,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import { MAX_ADDRESS_LENGTH, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CustomerListItem: FunctionComponent<Props> = ({
    address,
    isSelected = false,
    name,
    numberOfFarms = 0,
    numberOfFields = 0,
    onClick,
    phoneNumber,
    testId = TEST_ID
}) => (
    <ItemContainer
        className={styles.itemContainer}
        isSelected={isSelected}
        onClick={onClick}
        testId={testId}
    >
        <VerticalContainer className={styles.customerDetailsContainer}>
            <HeadingText className={styles.headingText} text={name} />

            <AddressLineItem address={address} maxLength={MAX_ADDRESS_LENGTH} />

            <PhoneNumberLineItem phoneNumber={phoneNumber} />
        </VerticalContainer>

        <VerticalContainer className={styles.iconContainer}>
            <IconWithCount className={styles.farmIconContainer} count={numberOfFarms}>
                <FarmIcon />
            </IconWithCount>

            <IconWithCount className={styles.fieldIconContainer} count={numberOfFields}>
                <FieldIcon />
            </IconWithCount>
        </VerticalContainer>
    </ItemContainer>
);

export default CustomerListItem;
