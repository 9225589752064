import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormSectionContainer, SplitContainer } from '@rantizo-software/rantizo-ui';

import FormMultiSelect from 'components/deprecating/FormMultiSelect';
import FormNumberInput from 'components/deprecating/FormNumberInput';
import FormSelect from 'components/deprecating/FormSelect';
import FormCheckbox from 'components/form/FormCheckbox';
import FormDate from 'components/form/FormDate';
import FormFieldWithUnit from 'components/form/FormFieldWithUnit';
import FormLabel from 'components/form/FormLabel';
import FormText from 'components/form/FormText';
import FormTimeWithTimeZone from 'components/form/FormTimeWithTimeZone';

import useTranslation from './hooks/useTranslation';

import { DRONE_SERIAL_NUMBER_TEST_ID, FORM_NOT_TOUCHED, TEST_ID, windDirection } from './constants';
import type {
    ChangeEvent,
    FunctionComponent,
    ProductUsageReportForm,
    Props,
    SelectData
} from './types';

const ApplicationDetailsForm: FunctionComponent<Props> = ({
    productUsageReportOptions,
    testId = TEST_ID
}) => {
    const {
        APPLICATION_DATE,
        APPLICATION_SITE,
        APPLICATOR,
        DATE_PLACEHOLDER,
        DRONE_SERIAL_NUMBER,
        END_TIME,
        MPH,
        NO_MEASURABLE_WIND,
        START_TIME,
        TEMPERATURE,
        TEMPERATURE_UNIT,
        WEATHER,
        WIND_DIRECTION,
        WIND_GUST,
        WIND_SPEED,
        WORK_ORDER_NUMBER
    } = useTranslation();

    const { applicationSites, drones, workOrderKey } = productUsageReportOptions;
    const { setTouched, setValues, values } = useFormikContext<ProductUsageReportForm>();

    const [disableWind, setDisableWind] = useState(values.windDirection === 'NA');

    useEffect(
        () => {
            if (values.windDirection === 'NA') {
                setWindFieldsDisabled(true);
            }
        },
        // eslint-disable-next-line
        [values.windDirection]
    );

    const setWindFieldsDisabled = useCallback(
        async (isDisabled: boolean) => {
            setDisableWind(isDisabled);

            if (isDisabled) {
                const newValues = {
                    ...values,
                    noWind: isDisabled,
                    windDirection: 'NA',
                    windGustSpeed: 0.0,
                    windSpeed: 0.0
                };

                await setTouched({ ...FORM_NOT_TOUCHED });
                await setValues(newValues, false);
            } else {
                await setValues({
                    ...values,
                    noWind: isDisabled,
                    windGustSpeed: null,
                    windSpeed: null
                });
            }
        },
        [setTouched, setValues, values]
    );

    const onCheckboxChange = useCallback(
        async (event: ChangeEvent<HTMLInputElement>) => {
            // Change event gives old value, so need to invert value
            const checkValue = event.target.checked;

            await setWindFieldsDisabled(checkValue);
        },
        [setWindFieldsDisabled]
    );

    const applicationSiteValues = useMemo(
        () =>
            applicationSites.reduce((acc, site) => {
                acc.push({
                    label: site.siteName,
                    value: site.id
                });

                return acc;
            }, [] as SelectData[]),
        [applicationSites]
    );

    const droneValues = useMemo(
        () =>
            drones.reduce((acc, drone) => {
                acc.push({
                    label: drone.nickname,
                    value: drone.id
                });

                return acc;
            }, [] as SelectData[]),
        [drones]
    );

    return (
        <FormSectionContainer testId={testId}>
            <FormLabel label={WORK_ORDER_NUMBER}>
                <FormText text={`${workOrderKey}`} />
            </FormLabel>

            <FormLabel label={APPLICATOR}>
                <FormText text={productUsageReportOptions.applicatorName} />
            </FormLabel>

            <Field
                component={FormMultiSelect}
                data={applicationSiteValues}
                label={APPLICATION_SITE}
                name="applicationSites"
                searchable
            />

            <Field
                component={FormDate}
                label={APPLICATION_DATE}
                name="applicationDate"
                placeholder={DATE_PLACEHOLDER}
            />

            <SplitContainer>
                <FormTimeWithTimeZone
                    label={START_TIME}
                    name="startTime"
                    timeZoneName="startTimeTimeZone"
                />

                <FormTimeWithTimeZone
                    label={END_TIME}
                    name="endTime"
                    timeZoneName="endTimeTimeZone"
                />
            </SplitContainer>

            <FormLabel label={WEATHER}>
                <Field
                    checked={disableWind}
                    component={FormCheckbox}
                    customOnChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e)}
                    label={NO_MEASURABLE_WIND}
                    name="noWind"
                    type="checkbox"
                />

                <SplitContainer>
                    <Field
                        component={FormSelect}
                        data={windDirection}
                        disabled={disableWind}
                        name="windDirection"
                        placeholder={WIND_DIRECTION}
                        searchable
                        selectOnBlur
                    />

                    <FormFieldWithUnit
                        disabled={disableWind}
                        fieldComponent={FormNumberInput}
                        fieldName="windSpeed"
                        placeholder={WIND_SPEED}
                        unitName="windSpeedUnit"
                        unitValues={[MPH]}
                    />
                </SplitContainer>

                <SplitContainer>
                    <FormFieldWithUnit
                        disabled={disableWind}
                        fieldComponent={FormNumberInput}
                        fieldName="windGustSpeed"
                        placeholder={WIND_GUST}
                        unitName="windGustSpeedUnit"
                        unitValues={[MPH]}
                    />

                    <FormFieldWithUnit
                        fieldComponent={FormNumberInput}
                        fieldName="temperature"
                        placeholder={TEMPERATURE}
                        unitName="temperatureUnit"
                        unitValues={[TEMPERATURE_UNIT]}
                    />
                </SplitContainer>
            </FormLabel>

            <Field
                component={FormSelect}
                data={droneValues}
                label={DRONE_SERIAL_NUMBER}
                name="droneId"
                searchable
                testId={DRONE_SERIAL_NUMBER_TEST_ID}
            />
        </FormSectionContainer>
    );
};

export default ApplicationDetailsForm;
