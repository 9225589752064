import { useCallback } from 'react';

import { SplitContainer, useDateFormat } from '@rantizo-software/rantizo-ui';

import ProductUsageReportContentContainer from 'components/ProductUsageReport/ProductUsageReportContentContainer';
import WorkOrderLabelValuePair from 'components/WorkOrderLabelValuePair';
import WorkOrderReadOnlyLabel from 'components/WorkOrderReadOnlyLabel';

import useConvert from 'hooks/useConvert';
import useRoundFormat from 'hooks/useRoundFormat';

import useTranslation from './hooks/useTranslation';

import { DEGREE_SYMBOL, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ApplicationDetails: FunctionComponent<Props> = ({
    applicatorName,
    productUsageReport,
    testId = TEST_ID,
    workOrder
}) => {
    const { formatDate, formatTime } = useDateFormat();

    const { convertKelvinToFahrenheit, convertMetersPerSecondToMilesPerHour } = useConvert();

    const roundFormat = useRoundFormat();

    const {
        airTemperatureKelvin,
        applicationEndDate,
        applicationSites,
        applicationStartDate,
        drone,
        windDirection,
        windGustSpeedMetersPerSecond,
        windSpeedMetersPerSecond
    } = productUsageReport;

    const {
        AIR_TEMPERATURE,
        APPLICATION_DATE,
        APPLICATION_SITE,
        APPLICATOR_NAME,
        DRONE_SERIAL_NUMBER,
        END_TIME,
        MPH,
        START_TIME,
        WEATHER,
        WIND_DIRECTION,
        WIND_GUST_SPEED,
        WIND_SPEED,
        WORK_ORDER_NUMBER
    } = useTranslation();

    // meters per second to miles per hour label
    const mpsToMphLabel = useCallback(
        (value: number) => `${roundFormat(convertMetersPerSecondToMilesPerHour(value))} ${MPH}`,
        [MPH, roundFormat, convertMetersPerSecondToMilesPerHour]
    );

    const fahrenheitLabel = useCallback(
        () => `${roundFormat(convertKelvinToFahrenheit(airTemperatureKelvin))}${DEGREE_SYMBOL}F`,
        [roundFormat, convertKelvinToFahrenheit, airTemperatureKelvin]
    );

    const getSiteNames = useCallback(() => {
        if (applicationSites) {
            return applicationSites.map(({ siteName }) => siteName).join(', ');
        }

        return '';
    }, [applicationSites]);

    return (
        <ProductUsageReportContentContainer testId={testId}>
            <WorkOrderLabelValuePair label={WORK_ORDER_NUMBER} value={`${workOrder.key ?? ''}`} />

            <WorkOrderLabelValuePair label={APPLICATION_SITE} value={getSiteNames()} />

            <WorkOrderLabelValuePair
                label={APPLICATION_DATE}
                value={formatDate(applicationStartDate)}
            />

            <SplitContainer>
                <WorkOrderLabelValuePair
                    label={START_TIME}
                    value={formatTime(applicationStartDate)}
                />

                <WorkOrderLabelValuePair label={END_TIME} value={formatTime(applicationEndDate)} />
            </SplitContainer>

            <WorkOrderReadOnlyLabel text={WEATHER} />

            <SplitContainer>
                <WorkOrderLabelValuePair label={WIND_DIRECTION} value={windDirection} />

                <WorkOrderLabelValuePair
                    label={WIND_SPEED}
                    value={mpsToMphLabel(windSpeedMetersPerSecond)}
                />
            </SplitContainer>

            <SplitContainer>
                <WorkOrderLabelValuePair
                    label={WIND_GUST_SPEED}
                    value={mpsToMphLabel(windGustSpeedMetersPerSecond)}
                />

                <WorkOrderLabelValuePair label={AIR_TEMPERATURE} value={fahrenheitLabel()} />
            </SplitContainer>

            <WorkOrderLabelValuePair
                label={DRONE_SERIAL_NUMBER}
                value={drone?.nickname ? drone?.nickname : ''}
            />

            <WorkOrderLabelValuePair label={APPLICATOR_NAME} value={applicatorName} />
        </ProductUsageReportContentContainer>
    );
};

export default ApplicationDetails;
