import { atom } from 'recoil';

import type { OperatorResponse } from '@rantizo-software/rantizo-ui';

const operatorsAtom = atom<OperatorResponse[]>({
    default: undefined,
    key: 'operators'
});

const state = {
    atoms: {
        operatorsAtom
    }
};

export default state;
