import { useParams } from 'react-router-dom';

import {
    PageContainer,
    PageTitle,
    PrimaryButton,
    VerticalContainer,
    VerticalSpaceBetween,
    WithDrawerContainer,
    Wrapper
} from '@rantizo-software/rantizo-ui';

import SaveChangesModal from 'components/SaveChangesModal';
import CustomerForm from 'components/form/CustomerForm';

import useData from './hooks/useData';
import useTranslate from './hooks/useTranslate';

import CreateCustomerDrawer from './CreateCustomersDrawer';
import CustomerDeleteButton from './CustomerDeleteButton';
import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CreateCustomerPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { canSubmit, form, handleOnSaveClose, handleOnSaveOpen, handleSubmit, isOpen, resetKey } =
        useData();

    const { CUSTOMER, SAVE } = useTranslate();

    const { id } = useParams();

    return (
        <PageContainer testId={testId}>
            {isOpen && (
                <SaveChangesModal
                    isSaveDisabled={!canSubmit}
                    onClose={handleOnSaveClose}
                    onConfirm={handleSubmit}
                />
            )}

            <WithDrawerContainer isOpen>
                <CreateCustomerDrawer />

                <VerticalSpaceBetween className={styles.verticalSpaceBetween}>
                    <VerticalContainer className={styles.verticalContainer}>
                        <PageTitle text={CUSTOMER} />

                        <CustomerForm form={form} key={resetKey} />
                    </VerticalContainer>

                    <Wrapper className={styles.wrapper}>
                        <PrimaryButton
                            className={styles.saveButton}
                            isDisabled={!canSubmit}
                            onClick={handleOnSaveOpen}
                            text={SAVE}
                        />

                        {id && <CustomerDeleteButton customerId={id} />}
                    </Wrapper>
                </VerticalSpaceBetween>
            </WithDrawerContainer>
        </PageContainer>
    );
};

export default CreateCustomerPage;
