import { Outlet } from 'react-router-dom';

import { useDevice } from 'components/DeviceProvider';
import SiteFooter from 'components/SiteFooter';
import SiteHeader from 'components/SiteHeader';
import Toast from 'components/Toast';

import type { FunctionComponent } from './types';

import styles from './styles.module.scss';

const FullScreenLayout: FunctionComponent = () => {
    const device = useDevice();

    return (
        <>
            <SiteHeader />

            <Toast />

            <div className={styles.fullScreenContainer}>
                <Outlet />
            </div>

            {device.deviceType === 'desktop' && <SiteFooter />}
        </>
    );
};

export default FullScreenLayout;
