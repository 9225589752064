import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('joinServiceProviderMapDrawer');

    return {
        BACK: t('back'),
        COMPANY: t('companyDetails'),
        CONSENT_LABEL: t('consentLabel'),
        LOCATION: t('locationDetails'),
        SERVICE: t('serviceDetails'),
        SUBMIT: t('submitForApproval')
    };
};

export default useTranslation;
