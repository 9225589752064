import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createCustomerDrawer');

    return {
        BACK: t('back'),
        CREATE: t('create')
    };
};

export default useTranslation;
