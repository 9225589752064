import { type FunctionComponent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from '@rantizo-software/rantizo-ui';

import useAccount from 'hooks/useAccount';
import useCurrentOrganization from 'hooks/useCurrentOrganization';

const LoginPage: FunctionComponent = () => {
    const { loginWithRedirect } = useAuth0();
    const { saveMyUserToStore } = useAccount();
    const { setCurrentOrganization } = useCurrentOrganization();
    const [searchParams] = useSearchParams();

    const orgId = searchParams.get('organization');
    const invitation = searchParams.get('invitation');

    useEffect(() => {
        saveMyUserToStore(undefined);
        setCurrentOrganization(undefined);

        if (orgId && invitation) {
            loginWithRedirect({
                authorizationParams: { invitation: invitation, organization: orgId }
            });
        } else {
            loginWithRedirect();
        }
    }, [invitation, loginWithRedirect, orgId, saveMyUserToStore, setCurrentOrganization]);

    return <PageLoader />;
};

export default LoginPage;
