import { useCallback, useEffect, useRef, useState } from 'react';

import { FormSectionContainer, PrimaryButton } from '@rantizo-software/rantizo-ui';

import InputLabel from 'components/InputLabel';
import SideBarContentContainer from 'components/SideBarContentContainer';
import AircraftDropdown from 'components/form/AircraftDropdown';

import useForm from 'hooks/useForm';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const ApplicationDetailsTab: FunctionComponent<Props> = ({
    onDroneSelected,
    selectedDroneId,
    testId = TEST_ID
}) => {
    const [submitForm, setSubmitForm] = useState<boolean>(false);

    const aircraftRef = useRef<HTMLSelectElement>(null);

    const formSchema = {
        appliedWith: ''
    };

    const applicationDetailsForm = useForm(formSchema);

    const { form, handleChange, handleError, handleForm, handleSubmit, handleValid } =
        applicationDetailsForm;

    const { APPLIED_WITH, CONTINUE } = useTranslation();

    const onContinue = useCallback(() => {
        const event = new Event('submit');

        aircraftRef.current?.dispatchEvent(event);

        const output = handleForm();

        if (typeof output === 'string') {
            console.error(output);

            return;
        }
        setSubmitForm(true);
    }, [handleForm]);

    useEffect(() => {
        if (submitForm) {
            const { appliedWith } = form.current;
            const { value: selectedAircraft } = JSON.parse(appliedWith);

            onDroneSelected(JSON.parse(selectedAircraft));

            setSubmitForm(false);
        }
    }, [submitForm, form, onDroneSelected]);

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer>
                <InputLabel text={APPLIED_WITH} />

                <AircraftDropdown
                    onChange={(dropdownValue: string) => {
                        handleChange('appliedWith')(dropdownValue);
                    }}
                    ignoreList={[]}
                    isRequired={true}
                    onError={handleError('appliedWith')}
                    onSubmit={handleSubmit('appliedWith')}
                    onValid={handleValid('appliedWith')}
                    ref={aircraftRef}
                    selectedId={selectedDroneId}
                    testId={'appliedWithDropdown'}
                />

                <PrimaryButton isDisabled={submitForm} onClick={onContinue} text={CONTINUE} />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default ApplicationDetailsTab;
