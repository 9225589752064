import { useCallback, useEffect, useState } from 'react';

import { PageContainer, PageLoader, VerticalContainer } from '@rantizo-software/rantizo-ui';

import OnboardingBodyText from 'components/OnboardingBodyText';
import OnboardingLogout from 'components/OnboardingLogout';
import OnboardingPageTitle from 'components/OnboardingPageTitle';
import OrganizationList from 'components/OrganizationList';
import SimpleHeader from 'components/SimpleHeader';

import useAccessToken from 'hooks/useAccessToken';
import useAccount from 'hooks/useAccount';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useOrganizations from 'hooks/useOrganizations';
import type { Organization } from 'hooks/useOrganizations/types';

import useCreateOrganization from './hooks/useCreateOrganization';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const VerifyOrganizationPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { fetchOrganizations, organizations } = useOrganizations();
    const createOrganization = useCreateOrganization();
    const { user } = useAccount();
    const { fetchToken } = useAccessToken();
    const { setCurrentOrganization } = useCurrentOrganization();

    const { ERROR, ORGANIZATION, SELECT_ORGANIZATION, TITLE } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const handleError = useCallback(() => {
        setIsLoading(false);
        setIsError(true);
    }, [setIsLoading, setIsError]);

    const loginToOrg = useCallback(
        async (org: Organization) => {
            const token = await fetchToken(org.auth0Id, false);

            if (!token) {
                handleError();

                return;
            }

            await setCurrentOrganization(org);
        },
        [fetchToken, setCurrentOrganization, handleError]
    );

    useEffect(() => {
        const fetch = async () => {
            const { error } = await fetchOrganizations();

            if (error) {
                handleError();

                return;
            }
        };

        if (organizations === null) {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizations]);

    const loginToOnlyOrg = async () => {
        const onlyOrg = organizations[0];

        await loginToOrg(onlyOrg);
    };

    const createNewOrganization = async () => {
        const companyName = `${user?.firstName}'s ${ORGANIZATION}`;

        const newOrganization = await createOrganization(companyName);

        if (newOrganization?.error) {
            handleError();

            return;
        }
        loginToOrg(newOrganization);
    };

    useEffect(() => {
        if (isError) {
            return;
        }

        if (organizations?.length === 1) {
            loginToOnlyOrg();
        } else if (organizations?.length < 1) {
            createNewOrganization();
        } else if (organizations?.length > 1) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizations]);

    const text = isError ? SELECT_ORGANIZATION : ERROR;

    return (
        <PageContainer testId={testId}>
            {isLoading ? (
                <div className={styles.pageLoader}>
                    <PageLoader />
                </div>
            ) : (
                <PageContainer testId={testId}>
                    <SimpleHeader />

                    <VerticalContainer className={styles.body}>
                        <OnboardingPageTitle text={TITLE} />

                        <OnboardingBodyText text={text} />

                        <OrganizationList organizations={organizations} />
                    </VerticalContainer>

                    <OnboardingLogout />
                </PageContainer>
            )}
        </PageContainer>
    );
};

export default VerifyOrganizationPage;
