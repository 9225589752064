import { Field, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { InferType } from 'yup';

import {
    PageContainer,
    PageLoader,
    PageTitle,
    SubmitButton,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import FormContainer from 'components/FormContainer';
import WithBackArrow from 'components/WithBackArrow';
import FormInput from 'components/deprecating/FormInput';
import FormPhoneInput from 'components/deprecating/FormPhoneInput';

import usePageRoutes from 'hooks/usePageRoutes';

import usePersonOfContact from './hooks/usePersonOfContact';
import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FormikProps, FunctionComponent, PersonOfContactForm, Props } from './types';

const EditPersonOfContactPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { organizationDetails } = usePageRoutes();
    const { getInitialValues, submitForm } = usePersonOfContact();
    const personOfContactSchema = useSchema();
    const { BUTTON, PLACEHOLDERS, TITLE } = useTranslation();

    type PersonOfContactSchemaType = InferType<typeof personOfContactSchema>;
    const [initialValues, setInitialValues] = useState<PersonOfContactSchemaType>({
        email: '',
        firstName: '',
        lastName: '',
        phone: ''
    });
    const [loading, setLoading] = useState(false);
    const [fetchingContact, setFetchingContact] = useState(false);

    const handleBack = useCallback(() => {
        navigate(organizationDetails);
    }, [navigate, organizationDetails]);

    const prepopulateForm = useCallback(async () => {
        setFetchingContact(true);
        const contact: PersonOfContactForm = await getInitialValues();

        setInitialValues(contact);
        setFetchingContact(false);
    }, [getInitialValues]);

    const handleSubmit = useCallback(
        async (values: PersonOfContactSchemaType) => {
            const response = await submitForm(values);

            if (response?.data) {
                handleBack();
            }
        },
        [handleBack, submitForm]
    );

    const onSubmit = useCallback(
        async (values: PersonOfContactSchemaType) => {
            setLoading(true);
            setInitialValues(values);
            await handleSubmit(values);
            setLoading(false);
        },
        [setInitialValues, handleSubmit]
    );

    useEffect(() => {
        prepopulateForm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (fetchingContact) {
        return <PageLoader />;
    }

    return (
        <Formik
            data-testid={testId}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={personOfContactSchema}
        >
            {({ isSubmitting }: FormikProps<PersonOfContactSchemaType>) => (
                <FormContainer>
                    <PageContainer>
                        <VerticalContainer>
                            <WithBackArrow onClick={handleBack}>
                                <PageTitle text={TITLE} />
                            </WithBackArrow>

                            <Field
                                component={FormInput}
                                name="firstName"
                                placeholder={PLACEHOLDERS.FIRST_NAME}
                                type="text"
                                withAsterisk
                            />

                            <Field
                                component={FormInput}
                                name="lastName"
                                placeholder={PLACEHOLDERS.LAST_NAME}
                                type="text"
                                withAsterisk
                            />

                            <Field
                                component={FormPhoneInput}
                                name="phone"
                                placeholder={PLACEHOLDERS.PHONE}
                                type="text"
                            />

                            <Field
                                component={FormInput}
                                name="email"
                                placeholder={PLACEHOLDERS.EMAIL}
                                type="text"
                            />
                        </VerticalContainer>

                        <SubmitButton
                            isDisabled={isSubmitting || loading}
                            isLoading={isSubmitting || loading}
                            text={BUTTON}
                        />
                    </PageContainer>
                </FormContainer>
            )}
        </Formik>
    );
};

export default EditPersonOfContactPage;
