import { useFormikContext } from 'formik';
import { useCallback } from 'react';

import { FormSectionContainer, PrimaryButton } from '@rantizo-software/rantizo-ui';

import BackNextButtons from 'components/BackNextButtons';

import useTranslation from './hooks/useTranslation';

import type { FunctionComponent, Props } from './types';

// Todo I would like to subclass this from the resource navigation buttons but it doesn't look like that's an option
const FormNavigationButtons: FunctionComponent<Props> = ({
    children,
    customNextText,
    hasNext,
    isDisabled = false,
    isEditable = true,
    isLoading = false,
    onBack,
    saveButtonDisabled,
    saveButtonText,
    setFinalSubmit,
    testId
}) => {
    const { BACK } = useTranslation();

    const { submitForm } = useFormikContext();

    const onFinalSubmit = useCallback(() => {
        setFinalSubmit();
        submitForm();
    }, [submitForm, setFinalSubmit]);

    return (
        <FormSectionContainer testId={testId}>
            {children}

            {onBack && !hasNext && !customNextText && (
                <PrimaryButton onClick={onBack} text={BACK} />
            )}

            {(hasNext || customNextText) && (
                <BackNextButtons
                    isDisabled={isDisabled}
                    isNextLoading={isLoading}
                    nextText={customNextText}
                    onBack={onBack}
                    onNext={submitForm}
                />
            )}

            {isEditable && (
                <PrimaryButton
                    isDisabled={saveButtonDisabled}
                    isLoading={isLoading}
                    onClick={onFinalSubmit}
                    text={saveButtonText}
                />
            )}
        </FormSectionContainer>
    );
};

export default FormNavigationButtons;
