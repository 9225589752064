import { useRef } from 'react';

import useRecoil from 'hooks/useRecoil';

import type { Params, UseGlobalStoreHook } from './types';

const useGlobalStore: UseGlobalStoreHook = <T>({ namespace }: Params) => {
    const storeMap = useRef({
        recoil: useRecoil
    }).current;

    return storeMap.recoil<T>({ namespace });
};

export default useGlobalStore;
