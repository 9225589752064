import { PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX } from 'config';
import { Field } from 'formik';

import { FormSectionContainer } from '@rantizo-software/rantizo-ui';

import FormTextArea from 'components/deprecating/FormTextArea';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const NotesForm: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { NOTES } = useTranslation();

    return (
        <FormSectionContainer testId={testId}>
            <Field
                autosize={true}
                component={FormTextArea}
                label={NOTES}
                max={PRODUCT_USAGE_REPORT_NOTES_CHAR_MAX}
                minRows={15}
                name="notes"
            />
        </FormSectionContainer>
    );
};

export default NotesForm;
