import { PageContainer, SectionTitle } from '@rantizo-software/rantizo-ui';

import ListPageLayout from 'components/ListPageLayout';
import ListPageLayoutHeader from 'components/ListPageLayoutHeader';

import useFeatureFlags from 'hooks/useFeatureFlags';

import useTranslation from './hooks/useTranslation';

import FieldViewConnectionListItem from './components/FieldViewConnectionListItem';
import JohnDeereConnectionListItem from './components/JohnDeereConnectionListItem';
import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const ManageConnectionsPage = ({ testId = TEST_ID }: Props) => {
    const { CONNECTIONS_SECTION_HEADER, MANAGE_CONNECTIONS_PAGE_TITLE } = useTranslation();
    const { FIELD_VIEW, JOHN_DEERE } = useFeatureFlags();

    return (
        <PageContainer testId={testId}>
            <ListPageLayout>
                <ListPageLayoutHeader pageTitle={MANAGE_CONNECTIONS_PAGE_TITLE} />

                <SectionTitle className={styles.sectionTitle} text={CONNECTIONS_SECTION_HEADER} />

                {FIELD_VIEW && <FieldViewConnectionListItem />}

                {JOHN_DEERE && <JohnDeereConnectionListItem />}
            </ListPageLayout>
        </PageContainer>
    );
};

export default ManageConnectionsPage;
