import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('operatorTileHomePageDrawer');

    return {
        ADD_LOCATION: t('addLocation'),
        BACK: t('back'),
        MY_SERVICE_PROVIDER_DETAILS: t('myServiceProviderDetails')
    };
};

export default useTranslation;
