import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import { rem } from '@mantine/core';
import { SplitContainer } from '@rantizo-software/rantizo-ui';

import CopyButton from 'components/CopyButton';
import FormInput from 'components/deprecating/FormInput';

import useCoordinateFormat from 'hooks/useCoordinateFormat';

import useTranslation from './hooks/useTranslation';

import type {
    ChangeEvent,
    CoordinateType,
    CoordinatesToCopy,
    ExtendedCoordinates,
    FormatType,
    Props,
    WorkOrderForm
} from './types';

const CoordinateFields = ({ coordinates, hasProductUsageReport, index, isEditable }: Props) => {
    const { setFieldTouched, setFieldValue, values } = useFormikContext<
        WorkOrderForm & ExtendedCoordinates
    >();

    const { LATITUDE, LONGITUDE } = useTranslation();
    const { formatCoordinateDegreesDecimalMinutes, formatCoordinateDegreesMinutesSeconds } =
        useCoordinateFormat();

    const formatCoordinate = useCallback(
        (coordinateType: CoordinateType, formatType: FormatType) => {
            if (!coordinates) {
                return '';
            }

            return formatType === 'ddm'
                ? formatCoordinateDegreesDecimalMinutes(
                      coordinates[coordinateType] as number,
                      coordinateType
                  )
                : formatCoordinateDegreesMinutesSeconds(
                      coordinates[coordinateType] as number,
                      coordinateType
                  );
        },
        [coordinates, formatCoordinateDegreesDecimalMinutes, formatCoordinateDegreesMinutesSeconds]
    );

    const getFormattedCoordinates = useCallback(
        (formatType: FormatType) => ({
            latitude: formatCoordinate('latitude', formatType),
            longitude: formatCoordinate('longitude', formatType)
        }),
        [formatCoordinate]
    );

    const coordinatesDegreeDecimalMinutesFormatted = useMemo(
        () => getFormattedCoordinates('ddm'),
        [getFormattedCoordinates]
    );

    const coordinatesDegreesMinutesSecondsFormatted = useMemo(
        () => getFormattedCoordinates('dms'),
        [getFormattedCoordinates]
    );

    useEffect(() => {
        if (coordinatesDegreeDecimalMinutesFormatted) {
            setFieldValue(
                'coordinatesDegreeDecimalMinutesFormatted',
                coordinatesDegreeDecimalMinutesFormatted
            );
        }

        if (coordinatesDegreesMinutesSecondsFormatted) {
            setFieldValue(
                'coordinatesDegreesMinutesSecondsFormatted',
                coordinatesDegreesMinutesSecondsFormatted
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const valuesToCopy: CoordinatesToCopy = useMemo(
        () => ({
            degreesDecimalMinutesString: `${values.coordinatesDegreeDecimalMinutesFormatted?.latitude}, ${values.coordinatesDegreeDecimalMinutesFormatted?.longitude}`,
            degreesMinutesSecondsString: `${values.coordinatesDegreesMinutesSecondsFormatted?.latitude}, ${values.coordinatesDegreesMinutesSecondsFormatted?.longitude}`,
            degreesString: `${values.applicationSites[index].coordinates?.latitude}, ${values.applicationSites[index].coordinates?.longitude}`
        }),
        [index, values]
    );

    const handleCoordinateChange = useCallback(
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (event: ChangeEvent<HTMLInputElement>, props: any) => {
            const inputValue = event.target.value;

            const sanitizedValue = inputValue.replace(/[^0-9.-]/g, '');

            setFieldValue(props.field.name, sanitizedValue);
            setFieldTouched(props.field.name, false, false);
        },
        [setFieldTouched, setFieldValue]
    );

    const CoordinateField = useCallback(
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (props: any) => (
            <FormInput
                {...{
                    ...props,
                    field: {
                        ...props.field,
                        onChange: (e: ChangeEvent<HTMLInputElement>) =>
                            handleCoordinateChange(e, props)
                    }
                }}
                inputMode="decimal"
                pattern="^-?\d+(\.\d+)?$"
            />
        ),
        [handleCoordinateChange]
    );

    return (
        <SplitContainer>
            <Field
                component={CoordinateField}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                label={LATITUDE}
                name={`applicationSites.${index}.coordinates.latitude`}
                precision={15}
                rightSection={<></>}
            />

            <Field
                rightSection={
                    !isEditable ? <CopyButton text={valuesToCopy['degreesString']} /> : <></>
                }
                component={CoordinateField}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                label={LONGITUDE}
                name={`applicationSites.${index}.coordinates.longitude`}
                precision={15}
                rightSectionWidth={rem('30px')}
            />

            {!isEditable && coordinates?.latitude && coordinates.longitude && (
                <>
                    <Field
                        component={FormInput}
                        disabled={hasProductUsageReport}
                        isEditable={isEditable}
                        name={`coordinatesDegreeDecimalMinutesFormatted.latitude`}
                    />

                    <Field
                        rightSection={
                            <CopyButton text={valuesToCopy['degreesDecimalMinutesString']} />
                        }
                        component={FormInput}
                        disabled={hasProductUsageReport}
                        isEditable={isEditable}
                        name={`coordinatesDegreeDecimalMinutesFormatted.longitude`}
                        rightSectionWidth={rem('30px')}
                    />

                    <Field
                        component={FormInput}
                        disabled={hasProductUsageReport}
                        isEditable={isEditable}
                        name={`coordinatesDegreesMinutesSecondsFormatted.latitude`}
                    />

                    <Field
                        rightSection={
                            <CopyButton text={valuesToCopy['degreesMinutesSecondsString']} />
                        }
                        component={FormInput}
                        disabled={hasProductUsageReport}
                        isEditable={isEditable}
                        name={`coordinatesDegreesMinutesSecondsFormatted.longitude`}
                        rightSectionWidth={rem('30px')}
                    />
                </>
            )}
        </SplitContainer>
    );
};

export default CoordinateFields;
