import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    PageContainer,
    VerticalContainer,
    WithDrawerContainer
} from '@rantizo-software/rantizo-ui';

import SecondaryButton from 'components/SecondaryButton';
import OperatorMapCompanyDetailsForm from 'components/form/OperatorMapCompanyDetailsForm';
import OperatorMapLocationDetailsForm from 'components/form/OperatorMapLocationDetailsForm';
import OperatorMapServiceDetailsForm from 'components/form/OperatorMapServiceDetailsForm';

import useCompanyDetailsFormData from './hooks/useCompanyDetailsFormData';
import useData from './hooks/useData';
import useTranslate from './hooks/useTranslate';

import JoinServiceProviderMapDrawer from './components/JoinServiceProviderMapDrawer';
import { TEST_ID } from './constants';
import type { FunctionComponent, PageKeys } from './types';

import styles from './styles.module.scss';

const OperatorTileSignUpFlowPage: FunctionComponent<{ isEdit: boolean }> = ({ isEdit }) => {
    const [isOpen, setIsOpen] = useState(true);
    const { id } = useParams();
    const [activePage, setActivePage] = useState<PageKeys>('companyDetails');

    const {
        companyDetailsForm,
        consentForm,
        handleSubmit,
        isSubmitDisabled,
        loading,
        locationDetailsForm,
        operatorMapServiceDetailsForm
    } = useData(id);

    const handleExpand = () => {
        setIsOpen(true);
    };

    const handleOnCollapse = () => {
        setIsOpen(false);
    };

    const { handleCheckboxChange, isChecked, resetKey } =
        useCompanyDetailsFormData(companyDetailsForm);

    const pagesMap: Record<PageKeys, JSX.Element> = useMemo(
        () => ({
            companyDetails: (
                <OperatorMapCompanyDetailsForm
                    form={companyDetailsForm}
                    handleCheckboxChange={handleCheckboxChange}
                    isChecked={isChecked}
                    resetKey={resetKey}
                />
            ),
            locationDetails: (
                <OperatorMapLocationDetailsForm
                    form={locationDetailsForm}
                    setActivePage={setActivePage}
                />
            ),
            serviceDetails: <OperatorMapServiceDetailsForm form={operatorMapServiceDetailsForm} />
        }),
        [
            companyDetailsForm,
            locationDetailsForm,
            operatorMapServiceDetailsForm,
            handleCheckboxChange,
            isChecked,
            resetKey
        ]
    );

    const { BACK, NEXT } = useTranslate();

    const navMap: Record<PageKeys, PageKeys> = useMemo(
        () => ({
            companyDetails: 'locationDetails',
            locationDetails: 'serviceDetails',
            serviceDetails: 'locationDetails'
        }),
        []
    );

    const handleNav = useCallback(() => {
        setActivePage(navMap[activePage]);
    }, [activePage, navMap]);

    const navButtonText = useMemo(
        () => (activePage === 'serviceDetails' ? BACK : NEXT),
        [BACK, NEXT, activePage]
    );

    return (
        <PageContainer testId={TEST_ID}>
            <WithDrawerContainer className={styles.withDrawerContainer} isOpen={isOpen}>
                {activePage !== 'locationDetails' && (
                    <VerticalContainer>
                        {pagesMap[activePage]}

                        <SecondaryButton
                            className={styles.secondaryButton}
                            onClick={handleNav}
                            text={navButtonText}
                        />
                    </VerticalContainer>
                )}

                {activePage === 'locationDetails' && pagesMap[activePage]}

                <JoinServiceProviderMapDrawer
                    activePage={activePage}
                    form={consentForm}
                    isEdit={isEdit}
                    isOpen={isOpen}
                    isSubmitDisabled={isSubmitDisabled}
                    loading={loading}
                    onCollapse={handleOnCollapse}
                    onExpand={handleExpand}
                    onSubmit={handleSubmit}
                    setActivePage={setActivePage}
                />
            </WithDrawerContainer>
        </PageContainer>
    );
};

export default OperatorTileSignUpFlowPage;
