import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import {
    CONTACT_KEY,
    EMAIL_KEY,
    NAME_KEY,
    PHONE_KEY
} from 'components/form/OperatorMapCompanyDetailsForm/constants';
import type { OperatorMapCompanyDetailsFormSchema } from 'components/form/OperatorMapCompanyDetailsForm/types';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import type { NestedKeys, UseFormState } from 'hooks/useFormState/types';

const useCompanyDetailsFormData = (form: UseFormState<OperatorMapCompanyDetailsFormSchema>) => {
    const { id } = useParams();

    const [isChecked, setIsChecked] = useState(id ? false : true);

    const [resetKey, setResetKey] = useState<string>(uuid());

    const { currentOrganization, fetchPersonOfContact, personOfContact } = useCurrentOrganization();

    const { handleChange, handleValid } = form;

    useEffect(() => {
        fetchPersonOfContact();
        setResetKey(uuid());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const organizationCompanyDetails: OperatorMapCompanyDetailsFormSchema = useMemo(
        () => ({
            contact: `${personOfContact?.firstName ?? ''} ${personOfContact?.lastName ?? ''}`,
            email: personOfContact?.email ?? '',
            name: currentOrganization?.name ?? '',
            phoneNumber: currentOrganization?.phoneNumber ?? ''
        }),
        [
            currentOrganization?.name,
            currentOrganization?.phoneNumber,
            personOfContact?.email,
            personOfContact?.firstName,
            personOfContact?.lastName
        ]
    );

    const handleCheckboxChange = useCallback(
        (isChecked: boolean) => {
            setIsChecked(isChecked);

            const fields: Partial<
                Record<NestedKeys<OperatorMapCompanyDetailsFormSchema>, string | undefined>
            > = {
                [CONTACT_KEY]: organizationCompanyDetails.contact,
                [EMAIL_KEY]: organizationCompanyDetails.email,
                [NAME_KEY]: organizationCompanyDetails.name,
                [PHONE_KEY]: organizationCompanyDetails.phoneNumber
            };

            Object.entries(fields).forEach(([key, value]) => {
                const typedKey = key as NestedKeys<OperatorMapCompanyDetailsFormSchema>;

                handleChange(typedKey, isChecked ? value || '' : '');

                if (isChecked && value) {
                    handleValid(typedKey);
                }
            });

            setResetKey(uuid());
        },
        [handleChange, handleValid, organizationCompanyDetails]
    );

    useEffect(() => {
        if (organizationCompanyDetails) {
            handleCheckboxChange(isChecked);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationCompanyDetails]);

    return {
        handleCheckboxChange,
        isChecked,
        resetKey
    };
};

export default useCompanyDetailsFormData;
