import { useCallback, useMemo, useState } from 'react';

import {
    MapMarker,
    MapPin,
    MapPinIcon,
    MapPinInfoWindow,
    VerticalContainer,
    Wrapper,
    useAdvancedMarkerRef
} from '@rantizo-software/rantizo-ui';

import CustomerContactCard from 'components/CustomerContactCard';
import CustomerMapHeader from 'components/CustomerMapHeader';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CustomerMapPoint: FunctionComponent<Props> = ({
    customer,
    selectedCustomerId,
    setSelectedCustomerId,
    testId = TEST_ID
}) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const position = useMemo(
        () =>
            customer?.address?.location
                ? {
                      lat: customer?.address?.location.coordinates?.[1] as number,
                      lng: customer?.address?.location?.coordinates?.[0] as number
                  }
                : undefined,
        [customer]
    );

    const [isShown, setIsShown] = useState(true);

    const { company, id } = customer;

    const handleOnClick = useCallback(() => {
        setIsShown(!isShown);

        setSelectedCustomerId(id);
    }, [id, isShown, setSelectedCustomerId]);

    return (
        <VerticalContainer testId={testId}>
            {position && (
                <>
                    <MapMarker
                        onClick={handleOnClick}
                        position={position}
                        ref={markerRef}
                        title={customer.company}
                    >
                        {id === selectedCustomerId ? (
                            <Wrapper className={styles.mapPinIcon}>
                                {' '}

                                <MapPinIcon />{' '}
                            </Wrapper>
                        ) : (
                            <MapPin />
                        )}
                    </MapMarker>

                    <MapPinInfoWindow
                        headerContent={<CustomerMapHeader customerId={id} title={company} />}
                        isShown={isShown && id === selectedCustomerId}
                        marker={marker}
                        position={position}
                        setIsShown={setIsShown}
                    >
                        <Wrapper className={styles.customerContactCardWrapper}>
                            <CustomerContactCard customer={customer} />
                        </Wrapper>
                    </MapPinInfoWindow>
                </>
            )}
        </VerticalContainer>
    );
};

export default CustomerMapPoint;
