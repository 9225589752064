import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addFarmButton');

    return {
        ADD_FARM: t('addFarm')
    };
};

export default useTranslation;
