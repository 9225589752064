import { MapMarker, MapPin, useGeoConvert } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const OperatorMapPoint: FunctionComponent<Props> = ({ onClick, operator, testId = TEST_ID }) => {
    const { address, name } = operator;
    const { convertGeoJsontoLatLong } = useGeoConvert();
    const position = convertGeoJsontoLatLong(address.location.coordinates);

    return (
        <MapMarker onClick={onClick} position={position} testId={testId} title={name}>
            <MapPin />
        </MapMarker>
    );
};

export default OperatorMapPoint;
