import { useCallback, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { PageContainer, PageLoader } from '@rantizo-software/rantizo-ui';

import UserDetailsForm from 'components/UserDetailsForm';

import useAccount from 'hooks/useAccount';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { EditUserData, FunctionComponent, Props } from './types';

const EditUserDetailsPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { user } = useAccount();
    const updateUser = useData();
    const navigate = useNavigate();
    const { accountLoginPage, accountPage } = usePageRoutes();
    const { BUTTON, TITLE } = useTranslation();
    const [loading, setLoading] = useState(false);

    const onBack = useCallback(() => {
        navigate(accountPage);
    }, [accountPage, navigate]);

    const handleSubmit = useCallback(
        async (values: EditUserData) => {
            const response = await updateUser(values);

            if (response?.data) {
                onBack();
            }
        },
        [onBack, updateUser]
    );

    const onSubmit = useCallback(
        async (values: EditUserData) => {
            setLoading(true);
            await handleSubmit(values);
            setLoading(false);
        },
        [handleSubmit]
    );

    if (user === undefined) {
        return <PageLoader />;
    }

    if (user === null) {
        return <Navigate replace to={accountLoginPage} />;
    }

    const { firstName, lastName, phoneNumber } = user;

    const initialValues: EditUserData = {
        firstName,
        lastName,
        phone: phoneNumber?.phone
    };

    return (
        <PageContainer testId={testId}>
            <UserDetailsForm
                buttonText={BUTTON}
                initialValues={initialValues}
                isLoading={loading}
                isOnboarding={false}
                onBack={onBack}
                onSubmit={onSubmit}
                title={TITLE}
            />
        </PageContainer>
    );
};

export default EditUserDetailsPage;
