import { Field, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    ContentText,
    ModalOverlay,
    ModalTitle,
    PrimaryButton,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import FormContainer from 'components/FormContainer';
import FormSelect from 'components/deprecating/FormSelect';

import usePageRoutes from 'hooks/usePageRoutes';
import useWorkOrderSummaries from 'hooks/useWorkOrderSummaries';

import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import { DEFAULT_FORM } from './defaults';
import type {
    FunctionComponent,
    Props,
    WorkOrderSelectForm,
    WorkOrderSelection,
    WorkOrderSummary
} from './types';

import styles from './styles.module.scss';

const NewProductUsageReportModal: FunctionComponent<Props> = ({ onClose, testId = TEST_ID }) => {
    const [summaries, setSummaries] = useState<WorkOrderSummary[]>();
    const [loading, setIsLoading] = useState<boolean>(false);
    const { fetchAllWorkOrderSummaries } = useWorkOrderSummaries();

    useEffect(() => {
        const data = async () => {
            setIsLoading(true);

            try {
                const workorderSummariesResponse = await fetchAllWorkOrderSummaries();

                setSummaries(workorderSummariesResponse);
            } catch (error) {
                console.log(error);
                alert(error);
            }
            setIsLoading(false);
        };

        data();
        // eslint-disable-next-line
    }, []);

    const workOrderSelections: WorkOrderSelection[] | undefined = useMemo(
        () =>
            summaries &&
            summaries.map(({ applicationSites, customerName, id, proposedDate }) => {
                const formattedDate = proposedDate
                    ? new Date(proposedDate).toLocaleDateString('en-US')
                    : '';

                const siteName =
                    applicationSites && applicationSites?.length > 0
                        ? applicationSites[0].siteName
                        : '';

                return {
                    label: `Work Order (Created on ${formattedDate} for ${customerName}${siteName ? ` at ${siteName}` : ''})`,
                    value: id
                } as WorkOrderSelection;
            }),
        [summaries]
    );

    const {
        CREATE_PRODUCT_USAGE_REPORT,
        LOADING,
        NEW_PRODUCT_USAGE_REPORT,
        PRODUCT_USAGE_REPORT_SUBTEXT_MESSAGE,
        SELECT_WORK_ORDER
    } = useTranslation();

    const { newProductUsageReportPage } = usePageRoutes();
    const navigate = useNavigate();

    const onFormSubmit = useCallback(
        (values: WorkOrderSelectForm) => navigate(newProductUsageReportPage(values.workOrderId)),
        [navigate, newProductUsageReportPage]
    );

    const schema = useSchema();

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer className={styles.newProductUsageReportContainer}>
                <ModalTitle text={NEW_PRODUCT_USAGE_REPORT} />

                <ContentText text={PRODUCT_USAGE_REPORT_SUBTEXT_MESSAGE} />

                {summaries && (
                    <Formik
                        enableReinitialize
                        initialValues={DEFAULT_FORM}
                        onSubmit={onFormSubmit}
                        validateOnBlur
                        validationSchema={schema}
                    >
                        <FormContainer className={styles.formContainer}>
                            <Field
                                className={styles.field}
                                component={FormSelect}
                                data={workOrderSelections}
                                isEditable={true}
                                name="workOrderId"
                                placeholder={loading ? LOADING : SELECT_WORK_ORDER}
                                searchable
                                selectOnBlur
                            />

                            <PrimaryButton text={CREATE_PRODUCT_USAGE_REPORT} type="submit" />
                        </FormContainer>
                    </Formik>
                )}
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default NewProductUsageReportModal;
