export const ADDRESS_KEY = 'address';

export const COMPANY_NAME_KEY = 'company';

export const CONTACT_FIRST_NAME_KEY = 'contactFirstName';

export const CONTACT_LAST_NAME_KEY = 'contactLastName';

export const EMAIL_KEY = 'email';

export const PHONE_NUMBER_KEY = 'phoneNumber';

export const TEST_ID = 'customerForm';
