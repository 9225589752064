import { SplitContainer } from '@rantizo-software/rantizo-ui';

import MapDownloadButton from 'components/MapDownloadButton';
import MapShareButton from 'components/MapShareButton';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const AAMDownloadShareButtons: FunctionComponent<Props> = ({
    aam,
    chemicalNames,
    jobId,
    legend,
    mapType,
    mapUrl,
    productUsageReportId,
    siteName,
    testId = TEST_ID
}) => (
    <SplitContainer testId={testId}>
        <MapDownloadButton
            aam={aam}
            chemicalNames={chemicalNames}
            legend={legend}
            mapType={mapType}
            mapUrl={mapUrl}
            siteName={siteName}
        />

        <MapShareButton
            aam={aam}
            chemicalNames={chemicalNames}
            jobId={jobId}
            mapType={mapType}
            productUsageReportId={productUsageReportId}
        />
    </SplitContainer>
);

export default AAMDownloadShareButtons;
