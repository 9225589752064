import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('operatorMapCompanyDetailsForm');

    return {
        COMPANY_DETAILS: t('companyDetails'),
        COMPANY_NAME: t('companyName'),
        CONTACT: t('contact'),
        EMAIL: t('email'),
        PHONE_NUMBER: t('phoneNumber'),
        SAME_AS_ORGANIZATION: t('sameAsOrganization')
    };
};

export default useTranslation;
