import { Fragment, useMemo, useState } from 'react';
import implement from 'utils/implement';

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Divider,
    Drawer,
    OperatorListItem,
    PageLoader,
    PrimaryButton,
    SectionContainer,
    VerticalContainer,
    VerticalSpaceBetween
} from '@rantizo-software/rantizo-ui';

import useFeatureAccess from 'hooks/useFeatureAccess';

import useData from './hooks/useData';
import useTranslate from './hooks/useTranslate';

import { MAX_OPERATOR_LOCATIONS, TEST_ID } from './constants';
import type { BooleanMap, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OperatorTileHomePageDrawer: FunctionComponent<Props> = ({
    handlePointClick,
    handleToggle,
    isOpen,
    onAddLocation = implement('onAddLocation'),
    onBack,
    onCollapse,
    onExpand,
    operators,
    testId = TEST_ID
}) => {
    const { handleDelete, handleEdit } = useData();
    const { ADD_LOCATION, BACK } = useTranslate();
    const features = useFeatureAccess();

    const [operatorid, setOperatorId] = useState<string>('');

    const handleToggleWithLoading = async (operatorId: string, enabled: boolean) => {
        setOperatorId(operatorId);
        await handleToggle(operatorId, enabled);
        setOperatorId('');
    };

    const { handler: handleClick, text } = useMemo(() => {
        const booleanMap: BooleanMap = {
            false: {
                handler: onBack,
                text: BACK
            },

            true: {
                handler: onAddLocation,
                text: ADD_LOCATION
            }
        };

        const hasFewOperators =
            features.serviceProviders && operators?.length < MAX_OPERATOR_LOCATIONS;

        return booleanMap[String(hasFewOperators)];
    }, [features.serviceProviders, onAddLocation, onBack, operators?.length, BACK, ADD_LOCATION]);

    return (
        <Drawer isOpen={isOpen} onCollapse={onCollapse} onExpand={onExpand} testId={testId}>
            <VerticalSpaceBetween>
                <VerticalContainer>
                    {operators.map(operator => (
                        <Fragment key={operator.id}>
                            {operatorid === operator.id ? (
                                <VerticalContainer className={styles.verticalContainer}>
                                    <PageLoader />
                                </VerticalContainer>
                            ) : (
                                <OperatorListItem
                                    onToggle={() =>
                                        handleToggleWithLoading(operator.id, !operator.enabled)
                                    }
                                    isEditable={true}
                                    onDelete={() => handleDelete(operator.id)}
                                    onEdit={() => handleEdit(operator.id)}
                                    onNameClick={() => handlePointClick?.(operator.id)}
                                    operator={operator as any}
                                />
                            )}

                            <Divider className={styles.listDivider} />
                        </Fragment>
                    ))}
                </VerticalContainer>

                <SectionContainer className={styles.horizontalContainer}>
                    <PrimaryButton onClick={handleClick} text={text} />
                </SectionContainer>
            </VerticalSpaceBetween>
        </Drawer>
    );
};

export default OperatorTileHomePageDrawer;
