import { HeadingText, Wrapper } from '@rantizo-software/rantizo-ui';

import Link from 'components/Link';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslate from './hooks/useTranslate';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CustomerMapHeader: FunctionComponent<Props> = ({ customerId, testId = TEST_ID, title }) => {
    const { EDIT } = useTranslate();
    const { editCustomersPage } = usePageRoutes();

    return (
        <Wrapper className={styles.wrapper} testId={testId}>
            <HeadingText text={title} />

            <Link className={styles.link} text={EDIT} to={editCustomersPage(customerId)} />
        </Wrapper>
    );
};

export default CustomerMapHeader;
