import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    FormSectionContainer,
    PageContainer,
    PageTitle,
    PrimaryButton
} from '@rantizo-software/rantizo-ui';

import WithBackArrow from 'components/WithBackArrow';
import WithLabelAndInfo from 'components/WithLabelAndInfo';
import Coafield from 'components/form/FormFields/Coafield';
import ExemptionNumberField from 'components/form/FormFields/ExemptionNumberField';
import ExemptionDocketNumberField from 'components/form/FormFields/ExmeptionDocketNumberField';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFormState from 'hooks/useFormState';
import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import {
    COA_FORM_FIELD_KEY,
    FAA_DOCKET_NUMBER_KEY,
    FAA_EXEMPTION_NUMBER_KEY,
    TEST_ID
} from './constants';
import type { FormSchema, FunctionComponent, Organization, Props } from './types';

const CertificatesAndExemptionsPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const navigate = useNavigate();
    const { organizationDetails } = usePageRoutes();

    const {
        COA,
        COA_TOOLTIP,
        ERROR_MESSAGE,
        EXEMPTION_DOCKET_NUMBER,
        EXEMPTION_DOCKET_NUMBER_TOOLTIP,
        EXEMPTION_NUMBER,
        EXEMPTION_NUMBER_TOOLTIP,
        SAVE,
        TITLE
    } = useTranslation();

    const [isSaving, setIsSaving] = useState(false);
    const [formHasErrors, setFormHasErrors] = useState(false);

    const handleBack = useCallback(() => {
        navigate(organizationDetails);
    }, [navigate, organizationDetails]);

    const { currentOrganization, updateMyOrganization } = useCurrentOrganization();

    const { coa, faaDocketNumber, faaExemptionNumber } = currentOrganization as Organization;

    const initialSchema: FormSchema = {
        coa,
        faaDocketNumber,
        faaExemptionNumber
    };

    const handleSave = useCallback(
        async (formData: FormSchema) => {
            setIsSaving(true);

            const { coa, faaDocketNumber, faaExemptionNumber } = formData;
            const payload = {
                ...currentOrganization,
                coa: coa || undefined,
                faaDocketNumber: faaDocketNumber || undefined,
                faaExemptionNumber: faaExemptionNumber || undefined
            } as Organization;

            try {
                await updateMyOrganization(payload);

                handleBack();
            } catch (error) {
                alert(ERROR_MESSAGE);
                console.error(error);
            } finally {
                setIsSaving(false);
            }
        },
        [ERROR_MESSAGE, currentOrganization, handleBack, updateMyOrganization]
    );

    const form = useFormState<FormSchema>({
        initialSchema,
        onFormChange({ hasErrors }) {
            setFormHasErrors(hasErrors);
        },
        onSubmit: handleSave
    });

    const { handleSubmit } = form;

    return (
        <PageContainer testId={testId}>
            <FormSectionContainer>
                <WithBackArrow onClick={handleBack}>
                    <PageTitle text={TITLE} />
                </WithBackArrow>

                <WithLabelAndInfo
                    text={EXEMPTION_DOCKET_NUMBER}
                    tooltipText={EXEMPTION_DOCKET_NUMBER_TOOLTIP}
                >
                    <ExemptionDocketNumberField<FormSchema>
                        form={form}
                        name={FAA_DOCKET_NUMBER_KEY}
                    />
                </WithLabelAndInfo>

                <WithLabelAndInfo text={EXEMPTION_NUMBER} tooltipText={EXEMPTION_NUMBER_TOOLTIP}>
                    <ExemptionNumberField<FormSchema> form={form} name={FAA_EXEMPTION_NUMBER_KEY} />
                </WithLabelAndInfo>

                <WithLabelAndInfo text={COA} tooltipText={COA_TOOLTIP}>
                    <Coafield<FormSchema> form={form} name={COA_FORM_FIELD_KEY} />
                </WithLabelAndInfo>

                <PrimaryButton
                    isDisabled={isSaving || formHasErrors}
                    onClick={handleSubmit}
                    text={SAVE}
                />
            </FormSectionContainer>
        </PageContainer>
    );
};

export default CertificatesAndExemptionsPage;
