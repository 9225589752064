import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('useOperators');

    return {
        CREATE_OPERATOR_TILE_TOAST_MESSAGE: t('createOperatorTileToastMessage'),
        UPDATE_OPERATOR_TILE_TOAST_MESSAGE: t('updateOperatorTileToastMessage')
    };
};

export default useTranslation;
