import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    ContentText,
    Divider,
    Drawer,
    HeadingText,
    HorizontalContainer,
    PrimaryButton,
    SearchInputField,
    SectionContainer,
    VerticalContainer,
    VerticalSpaceBetween
} from '@rantizo-software/rantizo-ui';

import usePageRoutes from 'hooks/usePageRoutes';

import CustomerList from 'pages/CustomersPage/CustomerList';

import useTranslation from './hooks/useTranslation';

import { SEARCH_TERM_KEY, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CustomersDrawer: FunctionComponent<Props> = ({
    customerForm,
    customers,
    fetchCustomerData,
    queryParams,
    selectedCustomerId,
    setSelectedCustomerId,
    testId = TEST_ID
}) => {
    const navigate = useNavigate();
    const { createCustomerPage } = usePageRoutes();
    const handleOnKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                customerForm.handleSubmit();
            }
        },
        [customerForm]
    );

    const { CUSTOMERS, IMPORT, NEW_CUSTOMER, NO_RESULTS } = useTranslation();

    const handleNavigate = () => {
        navigate(createCustomerPage);
    };

    return (
        <Drawer allowCollapse={false} isOpen={true} testId={testId}>
            <VerticalSpaceBetween>
                <VerticalContainer>
                    <SectionContainer className={styles.sectionContainer}>
                        <HeadingText className={styles.headingText} text={CUSTOMERS} />

                        <SearchInputField
                            form={customerForm}
                            name={SEARCH_TERM_KEY}
                            onKeyUp={handleOnKeyUp}
                        />
                    </SectionContainer>

                    <Divider />

                    <CustomerList
                        customers={customers}
                        fetchCustomerData={fetchCustomerData}
                        queryParams={queryParams}
                        selectedCustomerId={selectedCustomerId}
                        setSelectedCustomerId={setSelectedCustomerId}
                    />

                    {customers.objects.length === 0 && <ContentText text={NO_RESULTS} />}
                </VerticalContainer>

                <HorizontalContainer className={styles.horizontalContainer}>
                    <PrimaryButton onClick={handleNavigate} text={NEW_CUSTOMER} />

                    <PrimaryButton onClick={handleNavigate} text={IMPORT} />
                </HorizontalContainer>
            </VerticalSpaceBetween>
        </Drawer>
    );
};

export default CustomersDrawer;
