import { useMemo } from 'react';

import {
    Divider,
    Drawer,
    OperatorCompanyDetailsOverview,
    OperatorDetails,
    PrimaryButton,
    SectionContainer,
    VerticalContainer,
    VerticalSpaceBetween
} from '@rantizo-software/rantizo-ui';

import useTranslate from './hooks/useTranslate';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OperatorTileOverviewDrawer: FunctionComponent<Props> = ({
    isOpen,
    onBack,
    onCollapse,
    onDelete,
    onEdit,
    onExpand,
    operatorResponse,
    testId = TEST_ID
}) => {
    const { BACK } = useTranslate();

    const { address, contact, email, phoneNumber } = useMemo(() => {
        if (!operatorResponse) {
            return {
                acresFlown: 0,
                address: '',
                contact: '',
                description: '',
                email: '',
                hasPart137: false,
                insured: false,
                phoneNumber: '',
                services: [],
                title: '',
                yearsOfService: 0
            };
        }

        const { address, contact, email, phoneNumber } = operatorResponse;

        const { address1, address2, city, state, zipCode } = address;

        const formattedPhoneNumber = phoneNumber
            ? `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
            : '';

        const fullAddress = [address1, address2, city, state, zipCode].filter(Boolean).join(', ');

        if (address1 && address2) {
            fullAddress.replace(`${address1}, `, `${address1} `);
        }

        return {
            address: fullAddress,
            contact,
            email,
            phoneNumber: formattedPhoneNumber
        };
    }, [operatorResponse]);

    return (
        <Drawer isOpen={isOpen} onCollapse={onCollapse} onExpand={onExpand} testId={testId}>
            <VerticalSpaceBetween>
                <VerticalContainer>
                    <OperatorDetails
                        onDelete={onDelete}
                        onEdit={onEdit}
                        operator={operatorResponse}
                        testId={testId}
                    />

                    <Divider />

                    <OperatorCompanyDetailsOverview
                        address={address}
                        contact={contact ?? ''}
                        email={email ?? ''}
                        phoneNumber={phoneNumber}
                    />

                    <Divider />
                </VerticalContainer>

                <SectionContainer className={styles.horizontalContainer}>
                    <PrimaryButton onClick={onBack} text={BACK} />
                </SectionContainer>
            </VerticalSpaceBetween>
        </Drawer>
    );
};

export default OperatorTileOverviewDrawer;
