import { useCallback, useState } from 'react';

import { VerticalContainer } from '@rantizo-software/rantizo-ui';

import WorkOrderFiles from 'components/WorkOrderFiles';
import FormFileUpload from 'components/form/FormFileUpload';

import useUploadFile from 'hooks/useUploadFile';
import useWorkOrderFileDownload from 'hooks/useWorkOrderFileDownload';
import useWorkOrderFileUpload from 'hooks/useWorkOrderFileUpload';

import type {
    FileUpload,
    FunctionComponent,
    Props,
    RantizoFile,
    SaveDocumentRequest
} from './types';

import styles from './styles.module.scss';

const WorkOrderFileUpload: FunctionComponent<Props> = ({
    disabled = false,
    field,
    form,
    isEditable,
    text,
    workOrder
}) => {
    const acceptedFileTypes = 'application/pdf,image/png,image/jpeg,image/gif';

    const { name } = field;
    const { deleteDocument, getUploadUrlResponse, saveDocument } = useWorkOrderFileUpload();
    const { getObjectKeyFromUrl, uploadFile } = useUploadFile();

    const [documentId, setDocumentId] = useState<string | null>(null);
    const [rantizoFile, setRantizoFile] = useState<RantizoFile | undefined>(
        workOrder?.file ?? undefined
    );

    const { getDownloadLink } = useWorkOrderFileDownload();

    const handleUpload = useCallback(
        async ({ fileUpload }: { fileUpload: FileUpload }) => {
            const { data, error } = await getUploadUrlResponse(fileUpload.fileRequest);

            if (error) {
                throw error;
            }

            const uploadUrl = data?.uploadUrl ?? '';
            const objectKey = getObjectKeyFromUrl(uploadUrl);
            const successfulUpload = await uploadFile(uploadUrl, fileUpload.file);

            if (!successfulUpload) {
                throw new Error('Failed to upload');
            }

            return objectKey;
        },
        [getObjectKeyFromUrl, getUploadUrlResponse, uploadFile]
    );

    const handleSaveDocument = useCallback(
        async ({ objectKey }: SaveDocumentRequest) => {
            const { data, error } = await saveDocument({ objectKey });

            if (error) {
                throw new Error('Failed To Save Work Order Document');
            }

            const resourceId = data?.id ?? '';

            setDocumentId(resourceId);
            setRantizoFile(data || undefined);

            return resourceId;
        },
        [saveDocument]
    );

    const onChange = useCallback(
        async (fileUpload: FileUpload) => {
            const objectKey = await handleUpload({ fileUpload });
            const resourceId = await handleSaveDocument({ objectKey });

            await form.setFieldValue(name, resourceId);
        },
        [form, handleSaveDocument, handleUpload, name]
    );

    const onDelete = useCallback(async () => {
        try {
            await form.setFieldValue(name, null);

            if (documentId) {
                await deleteDocument(documentId);
                setDocumentId(null);
            }
        } catch (e) {
            console.log(e);
        }
    }, [deleteDocument, documentId, form, name, setDocumentId]);

    const downloadFile = useCallback(async () => {
        if (rantizoFile) {
            const downloadUrl = await getDownloadLink(rantizoFile.id);

            window.open(downloadUrl, '_blank');
        }
    }, [getDownloadLink, rantizoFile]);

    return (
        <VerticalContainer>
            {!isEditable && (
                <WorkOrderFiles className={styles.workOrderFiles} rantizoFile={rantizoFile} />
            )}

            {isEditable && (
                <FormFileUpload
                    accept={acceptedFileTypes}
                    disabled={documentId != null || disabled}
                    multiple={false}
                    onClick={downloadFile}
                    onFileDelete={onDelete}
                    onFileRequestChange={onChange}
                    text={text}
                />
            )}
        </VerticalContainer>
    );
};

export default WorkOrderFileUpload;
