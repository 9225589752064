import { useCallback, useMemo } from 'react';

import {
    FormSectionContainer,
    DeprecatingPhoneNumberField as PhoneNumberField
} from '@rantizo-software/rantizo-ui';

import BackNextButtons from 'components/BackNextButtons';
import SideBarContentContainer from 'components/SideBarContentContainer';
import WithLabel from 'components/WithLabel';
import WithLabelAndInfo from 'components/WithLabelAndInfo';
import AddressForm from 'components/form/AddressForm';
import Coafield from 'components/form/FormFields/Coafield';
import CompanyNameField from 'components/form/FormFields/CompanyNameField';
import ExemptionNumberField from 'components/form/FormFields/ExemptionNumberField';
import ExemptionDocketNumberField from 'components/form/FormFields/ExmeptionDocketNumberField';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFormState from 'hooks/useFormState';

import useTranslation from './hooks/useTranslation';

import {
    COA_FIELD,
    COMPANY_DETAILS,
    COMPANY_NAME_FIELD,
    DOCKET_NUMBER_FIELD,
    EXEMPTIONS_FIELD,
    EXEMPTION_NUMBER_FIELD,
    PHONE_NUMBER_FIELD,
    TEST_ID
} from './constants';
import type { CompanyDetailsForm, FunctionComponent, Props } from './types';

const CompanyDetails: FunctionComponent<Props> = ({
    formData,
    isEditable,
    isSaveDisabled,
    onChange,
    onNext,
    onSave,
    testId = TEST_ID
}) => {
    const { currentOrganization } = useCurrentOrganization();
    const {
        address1,
        address2,
        city: orgCity,
        coa: orgCoa,
        faaDocketNumber: orgFaaDocketNumber,
        faaExemptionNumber: orgFaaExemptionNumber,
        name: orgName,
        phoneNumber: orgPhoneNumber,
        state: orgState,
        zipCode: orgZipCode
    } = currentOrganization || {};

    const { addressLine1, addressLine2, city, coa, name, phoneNumber, state, zipCode } =
        formData.companyDetails || {};

    const { docketNumber, exemptionNumber } = formData.exemptions || {};

    const initialSchema: CompanyDetailsForm = useMemo(
        () => ({
            ...formData,
            companyDetails: {
                addressLine1: addressLine1 || address1,
                addressLine2: addressLine2 || address2,
                city: city || orgCity,
                coa: coa || orgCoa,
                name: name || orgName,
                phoneNumber: phoneNumber || orgPhoneNumber,
                state: state || orgState,
                zipCode: zipCode || orgZipCode
            },
            exemptions: {
                docketNumber: docketNumber || (orgFaaDocketNumber ?? undefined),
                exemptionNumber: exemptionNumber || (orgFaaExemptionNumber ?? undefined)
            }
        }),
        [
            formData,
            addressLine1,
            address1,
            addressLine2,
            address2,
            city,
            orgCity,
            coa,
            orgCoa,
            name,
            orgName,
            phoneNumber,
            orgPhoneNumber,
            state,
            orgState,
            zipCode,
            orgZipCode,
            exemptionNumber,
            orgFaaDocketNumber,
            docketNumber,
            orgFaaExemptionNumber
        ]
    );

    const {
        COA,
        COA_TOOLTIP,
        COMPANY_NAME,
        EXEMPTION_DOCKET_NUMBER,
        EXEMPTION_DOCKET_NUMBER_TOOLTIP,
        EXEMPTION_NUMBER,
        EXEMPTION_NUMBER_TOOLTIP,
        PHONE_NUMBER
    } = useTranslation();

    const form = useFormState<CompanyDetailsForm>({
        initialSchema,
        onFormChange(data) {
            onChange?.(data);
        }
    });

    const { formErrors } = form;

    const hasErrors = Object.values(formErrors).some(error =>
        Object.values(error).some(fieldError => fieldError !== undefined)
    );

    const handleNext = useCallback(async () => {
        if (isEditable) {
            onSave();
        }
        onNext?.();
    }, [isEditable, onNext, onSave]);

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer>
                <FormSectionContainer>
                    <WithLabel text={COMPANY_NAME}>
                        <CompanyNameField<CompanyDetailsForm>
                            form={form}
                            isEditable={isEditable}
                            isRequired={true}
                            name={`${COMPANY_DETAILS}.${COMPANY_NAME_FIELD}`}
                        />
                    </WithLabel>

                    <AddressForm
                        formName={COMPANY_DETAILS}
                        isDisabled={false}
                        isEditable={isEditable}
                        parentForm={form}
                    />

                    <WithLabel text={PHONE_NUMBER}>
                        <PhoneNumberField
                            form={form}
                            isEditable={isEditable}
                            name={`${COMPANY_DETAILS}.${PHONE_NUMBER_FIELD}`}
                        />
                    </WithLabel>

                    <WithLabelAndInfo
                        displayTooltip={isEditable}
                        text={EXEMPTION_DOCKET_NUMBER}
                        tooltipText={EXEMPTION_DOCKET_NUMBER_TOOLTIP}
                    >
                        <ExemptionDocketNumberField
                            form={form}
                            isEditable={isEditable}
                            name={`${EXEMPTIONS_FIELD}.${DOCKET_NUMBER_FIELD}`}
                        />
                    </WithLabelAndInfo>

                    <WithLabelAndInfo
                        displayTooltip={isEditable}
                        text={EXEMPTION_NUMBER}
                        tooltipText={EXEMPTION_NUMBER_TOOLTIP}
                    >
                        <ExemptionNumberField<CompanyDetailsForm>
                            form={form}
                            isEditable={isEditable}
                            name={`${EXEMPTIONS_FIELD}.${EXEMPTION_NUMBER_FIELD}`}
                        />
                    </WithLabelAndInfo>

                    <WithLabelAndInfo
                        displayTooltip={isEditable}
                        text={COA}
                        tooltipText={COA_TOOLTIP}
                    >
                        <Coafield<CompanyDetailsForm>
                            form={form}
                            isEditable={isEditable}
                            name={`${COMPANY_DETAILS}.${COA_FIELD}`}
                        />
                    </WithLabelAndInfo>
                </FormSectionContainer>

                <BackNextButtons
                    isNextDisabled={isSaveDisabled || hasErrors}
                    isNextLoading={false}
                    onNext={handleNext}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default CompanyDetails;
