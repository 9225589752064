import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { workOrderStatusOptions } from 'types/workOrders';

import { FormSectionContainer, PrimaryButton, SplitContainer } from '@rantizo-software/rantizo-ui';

import FormSelectTeamMembers from 'components/FormSelectTeamMembers';
import WithLabelAndInfo from 'components/WithLabelAndInfo';
import FormSelect from 'components/deprecating/FormSelect';
import FormDate from 'components/form/FormDate';
import FormTime from 'components/form/FormTime';
import WorkOrderNumberInput from 'components/form/WorkOrderNumberInput';
import WorkOrderFileUpload from 'components/work-order/WorkOrderFileUpload';

import useAccount from 'hooks/useAccount';
import useAgrianPdfExtractor from 'hooks/useAgrainPdfExtractor';
import useFeatureFlags from 'hooks/useFeatureFlags';

import useTranslation from './hooks/useTranslation';

import {
    APPLICATOR_DROPDOWN_TEST_ID,
    EXPIRATION_DATE_FIELD_TEST_ID,
    PROPOSED_DATE_FIELD_TEST_ID,
    SCHEDULED_DATE_FIELD_TEST_ID,
    SCHEDULED_TIME_FIELD_TEST_ID,
    TEST_ID,
    WORK_ORDER_FILE_UPLOAD_BUTTON_TEST_ID,
    WORK_ORDER_NUMBER_FIELD_TEST_ID,
    WORK_ORDER_STATUS_SELECT_TEST_ID
} from './constants';
import type { FunctionComponent, Props, WorkOrderForm } from './types';

const Details: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID,
    workOrder
}) => {
    const { user } = useAccount();
    const { PDF_EXTRACTION } = useFeatureFlags();
    const {
        AUTO_FILL_ERROR,
        AUTO_FILL_WORK_ORDER,
        AUTO_FILL_WORK_ORDER_DISCLAIMER,
        LABELS,
        PLACEHOLDERS,
        WORK_ORDER_BUTTON
    } = useTranslation();

    const {
        setFieldValue,
        setValues,
        values: { fileId, scheduledDate, status }
    } = useFormikContext<WorkOrderForm>();

    useEffect(() => {
        if (user?.id !== undefined) {
            const currentId = user?.id;

            setValues(s => {
                if (s.applicatorInformation === '') {
                    return { ...s, applicatorInformation: currentId };
                }

                return s;
            });
        }
    }, [setValues, user?.id]);

    useEffect(() => {
        if (isEditable && scheduledDate === workOrder?.scheduledDate) {
            // prevent auto-set when setting values in duplicate
            return;
        }

        if (isEditable && scheduledDate && status === 'PENDING') {
            setFieldValue('status', 'SCHEDULED');
        } else if (isEditable && !scheduledDate && status === 'SCHEDULED') {
            setFieldValue('status', 'PENDING');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduledDate]);

    const { extractWorkOrderFromPdf } = useAgrianPdfExtractor();

    const handleAutoFill = useCallback(async () => {
        try {
            setIsAutoFillDisabled(true);
            const extractedWorkOrder = await extractWorkOrderFromPdf(fileId ?? '');

            setValues(extractedWorkOrder as WorkOrderForm);
        } catch (error) {
            console.error(error);
            alert(AUTO_FILL_ERROR);
        }
        setIsAutoFillDisabled(false);
    }, [AUTO_FILL_ERROR, extractWorkOrderFromPdf, fileId, setValues]);

    const [isAutoFillDisabled, setIsAutoFillDisabled] = useState(false);

    return (
        <FormSectionContainer testId={testId}>
            <WorkOrderNumberInput
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                name="workOrderNumber"
                testId={WORK_ORDER_NUMBER_FIELD_TEST_ID}
            />

            <Field
                component={WorkOrderFileUpload}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                name="fileId"
                testid={WORK_ORDER_FILE_UPLOAD_BUTTON_TEST_ID}
                text={WORK_ORDER_BUTTON}
                workOrder={workOrder}
            />

            {fileId && PDF_EXTRACTION && isEditable && (
                <WithLabelAndInfo
                    text={AUTO_FILL_WORK_ORDER}
                    tooltipText={AUTO_FILL_WORK_ORDER_DISCLAIMER}
                >
                    <PrimaryButton
                        isDisabled={isAutoFillDisabled}
                        onClick={handleAutoFill}
                        text={AUTO_FILL_WORK_ORDER}
                    />
                </WithLabelAndInfo>
            )}

            <Field
                component={FormSelect}
                data={workOrderStatusOptions}
                isEditable={isEditable}
                label={LABELS.STATUS}
                name="status"
                required={isEditable}
                testid={WORK_ORDER_STATUS_SELECT_TEST_ID}
            />

            <SplitContainer>
                <Field
                    component={FormDate}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.PROPOSED_DATE}
                    name="proposedDate"
                    placeholder={PLACEHOLDERS.PROPOSED_DATE}
                    required={isEditable}
                    testId={PROPOSED_DATE_FIELD_TEST_ID}
                />

                <Field
                    clearable={true}
                    component={FormDate}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.EXPIRATION_DATE}
                    name="expirationDate"
                    placeholder={PLACEHOLDERS.EXPIRATION_DATE}
                    testId={EXPIRATION_DATE_FIELD_TEST_ID}
                />
            </SplitContainer>

            <SplitContainer>
                <Field
                    clearable={true}
                    component={FormDate}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.SCHEDULED_DATE}
                    name="scheduledDate"
                    placeholder={PLACEHOLDERS.SCHEDULED_DATE}
                    testId={SCHEDULED_DATE_FIELD_TEST_ID}
                />

                <Field
                    component={FormTime}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.SCHEDULED_TIME}
                    name="scheduledTime"
                    placeholder={PLACEHOLDERS.SCHEDULED_TIME}
                    testId={SCHEDULED_TIME_FIELD_TEST_ID}
                />
            </SplitContainer>

            <Field
                component={FormSelectTeamMembers}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                label={LABELS.APPLICATOR_INFORMATION}
                name="applicatorInformation"
                placeholder={PLACEHOLDERS.APPLICATOR_INFORMATION}
                required={isEditable}
                testId={APPLICATOR_DROPDOWN_TEST_ID}
            />
        </FormSectionContainer>
    );
};

export default Details;
