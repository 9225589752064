import { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    BodyText,
    Checkbox,
    ContentText,
    Divider,
    Drawer,
    HorizontalContainer,
    PrimaryButton,
    SectionContainer,
    SplitContainer,
    VerticalSpaceBetween,
    Wrapper
} from '@rantizo-software/rantizo-ui';

import { useDevice } from 'components/DeviceProvider';

import usePageRoutes from 'hooks/usePageRoutes';

import type { PageKeys } from 'pages/OperatorTileSignUpFlowPage/types';

import useTranslation from './hooks/useTranslation';

import { CONSENT_KEY, TEST_ID } from './constants';
import type { ChangeEvent, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const JoinServiceProviderMapDrawer: FunctionComponent<Props> = ({
    activePage,
    form,
    isOpen,
    isSubmitDisabled,
    loading,
    onCollapse,
    onExpand,
    onSubmit,
    setActivePage,
    testId = TEST_ID
}) => {
    const { COMPANY, CONSENT_LABEL, LOCATION, SERVICE, SUBMIT } = useTranslation();

    const { homePage } = usePageRoutes();
    const { deviceType } = useDevice();

    const navigate = useNavigate();

    const handleSectionClick = useCallback(
        (key: PageKeys) => {
            setActivePage(key);
        },
        [setActivePage]
    );

    const sections: { key: PageKeys; label: string }[] = [
        { key: 'companyDetails', label: COMPANY },
        { key: 'locationDetails', label: LOCATION },
        { key: 'serviceDetails', label: SERVICE }
    ];

    const { getValues, handleChange } = form;

    const [isChecked, setIsChecked] = useState(getValues(CONSENT_KEY) as boolean);

    const handleConsentChange = useCallback(
        (checked: boolean) => {
            setIsChecked(checked);
            handleChange(CONSENT_KEY, checked);
        },
        [handleChange]
    );

    const onBack = useCallback(() => {
        if (navigate) {
            navigate(homePage);
        }
    }, [navigate, homePage]);

    const isAllowedCollapse = deviceType === 'mobile' ? true : false;

    return (
        <Drawer
            allowCollapse={isAllowedCollapse}
            className={styles.drawer}
            isOpen={isOpen}
            onCollapse={onCollapse}
            onExpand={onExpand}
            testId={testId}
        >
            <VerticalSpaceBetween className={styles.verticalSpaceBetween}>
                <Wrapper>
                    {sections.map(({ key, label }) => (
                        <Fragment key={key}>
                            <SectionContainer
                                className={`${activePage === key ? styles.active : ''} ${styles.sectionContainer}`}
                                onClick={() => handleSectionClick(key)}
                            >
                                <ContentText text={label} />
                            </SectionContainer>

                            <Divider />
                        </Fragment>
                    ))}
                </Wrapper>

                <SectionContainer>
                    <HorizontalContainer className={styles.horizontalContainer}>
                        <Checkbox
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                handleConsentChange(event.target.checked)
                            }
                            className={styles.checkbox}
                            isChecked={isChecked}
                        />

                        <BodyText text={CONSENT_LABEL} />
                    </HorizontalContainer>

                    <SplitContainer>
                        <PrimaryButton onClick={onBack} text={'Back'} />

                        <PrimaryButton
                            isDisabled={isSubmitDisabled || loading}
                            isLoading={loading}
                            onClick={onSubmit}
                            text={SUBMIT}
                        />
                    </SplitContainer>
                </SectionContainer>
            </VerticalSpaceBetween>
        </Drawer>
    );
};

export default JoinServiceProviderMapDrawer;
