import { CREATE_SHAPEFILE_API } from 'components/DownloadShapeFile/constants';

import useFetch from 'hooks/useFetch';
import useFlights from 'hooks/useFlights';

import { NO_AAMID } from './constants';
import type { FetchData, RequestShapeFileResponse, ResolveShapefileUrl } from './types';

const useData = () => {
    const { authenticatedGet, authenticatedPost } = useFetch();
    const { fetchFlightsForAAM } = useFlights();

    const fetchData: FetchData = async (aamId: string | null, product: string) => {
        if (!aamId) {
            return { data: null, error: NO_AAMID };
        }

        const fetchedFlights = await fetchFlightsForAAM({ aamId });

        const { data: flightsData, error: flightsError } = fetchedFlights;
        const foundFlights = flightsData?.length;

        if (!foundFlights || flightsError) {
            if (!foundFlights) {
                console.error('No flights were found to generate a SHP file');
            }

            return fetchedFlights;
        }

        return await authenticatedPost<RequestShapeFileResponse>(
            CREATE_SHAPEFILE_API,
            JSON.stringify({
                flightIds: flightsData,
                formatType: 'FIELD_VIEW',
                product: product,
                systemOfMeasurement: 'IMPERIAL'
            })
        );
    };

    const resolveShapefileUrl: ResolveShapefileUrl = async ({ shapeFileId }) =>
        await authenticatedGet(`${CREATE_SHAPEFILE_API}/${shapeFileId}`);

    return {
        fetchData,
        resolveShapefileUrl
    };
};

export default useData;
