import { AAM_MIN_START } from 'config';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { backendUnitMap, massRates } from 'types/units.ts';

import { trimAndNullIfEmptyOrUndefined } from '@@utils/data';
import { constructUtcDateTime } from '@@utils/dates';
import { round } from '@@utils/math';

import useApplicationSite from 'hooks/useApplicationSite';
import useConvert from 'hooks/useConvert';
import useCustomer from 'hooks/useCustomer';
import useFetch from 'hooks/useFetch';
import useGrower from 'hooks/useGrower';
import useProductUsageReports from 'hooks/useProductUsageReports';

import { WORK_ORDER_API } from './constants';
import type {
    ApplicationSite,
    RawApplicationSite,
    WorkOrder,
    WorkOrderAcreageUnit,
    WorkOrderChemicalRequest,
    WorkOrderForm,
    WorkOrderTargetRateUnit
} from './types';

const useData = () => {
    const { workOrderId } = useParams();
    const { authenticatedPost, authenticatedPut } = useFetch();

    const {
        convertDaysAndHoursToMilliseconds,
        convertToLitersPerSquareMeter,
        convertToSquareMeters
    } = useConvert();

    const { formSiteEqualWithApi, handleApplicationSites } = useApplicationSite();
    const { handleCustomer } = useCustomer();
    const { handleGrower } = useGrower();
    const { fetchAllProductUsageReportsForWorkOrder } = useProductUsageReports();

    const matchApiSitesToFormSites = useCallback(
        (formSites: ApplicationSite[], apiSites: RawApplicationSite[]) =>
            formSites.reduce((acc, formSite) => {
                let matchedSite: RawApplicationSite | null = null;

                for (const apiSite of apiSites) {
                    if (formSiteEqualWithApi(formSite, apiSite)) {
                        matchedSite = apiSite;
                        break;
                    }
                }

                if (matchedSite !== null) {
                    acc.push(matchedSite);
                }

                return acc;
            }, [] as RawApplicationSite[]),
        [formSiteEqualWithApi]
    );

    const createFinalSubmission = useCallback(
        async (
            values: WorkOrderForm,
            oldValues: WorkOrder | undefined,
            isDuplicate: boolean | undefined = false
        ) => {
            const isNew = !oldValues || isDuplicate;

            if (!values?.customer) {
                return false;
            }

            const sitesToCreate = isNew
                ? values.applicationSites
                : values.applicationSites.filter(
                      ({ id: newId }) =>
                          oldValues?.applicationSites.find(({ id: oldId }) => oldId === newId) ===
                          undefined
                  );

            let applicationSites = await handleApplicationSites(sitesToCreate, true);

            if (!isNew) {
                const sitesToUpdate = values.applicationSites.filter(({ id: newId }) =>
                    Boolean(oldValues?.applicationSites.find(({ id: oldId }) => oldId === newId))
                );

                const updatedSites = await handleApplicationSites(sitesToUpdate, false);

                // To preserve sort order
                applicationSites = updatedSites.concat(applicationSites);
            }

            const chemicals = values.chemicals.reduce((acc, chemical) => {
                const chemicalId = trimAndNullIfEmptyOrUndefined(chemical.chemicalId);
                const signalWord = trimAndNullIfEmptyOrUndefined(chemical.signalWord);
                const pest = trimAndNullIfEmptyOrUndefined(chemical.pest);
                const { sprayRateUnit } = chemical;

                const rate = chemical.sprayRate;

                if (chemicalId) {
                    const rateWithUnit = rate
                        ? { unit: backendUnitMap[sprayRateUnit], value: rate }
                        : null;
                    const useMass = massRates.includes(sprayRateUnit);

                    acc.push({
                        chemicalId,
                        massRate: useMass ? rateWithUnit : null,
                        pest,
                        signalWord,
                        sprayRate: !useMass ? rateWithUnit : null
                    });
                }

                return acc;
            }, [] as WorkOrderChemicalRequest[]);
            const customerId = await handleCustomer(values.customer, isNew);
            const growerId = await handleGrower(
                values.grower?.sameAsCustomer
                    ? { ...values.customer, id: values.grower.id }
                    : values.grower,
                isNew
            );

            const {
                applicationSites: formApplicationSites,
                applicatorInformation,
                appliedAcres,
                chemicalCost,
                commodity,
                expirationDate,
                fieldApplicationFlatFee,
                fieldApplicationHours,
                fieldApplicationRate,
                fieldApplicationRateUnit,
                fileId,
                invoiceItems,
                invoicedApplicationSites,
                notes,
                otherDescription,
                otherFee,
                preHarvestInterval,
                proposedAcres,
                proposedAcresUnit,
                proposedDate,
                reEntryIntervalDays,
                reEntryIntervalHours,
                scheduledDate = null,
                scheduledTime = '',
                status,
                targetSprayRate,
                targetSprayRateUnit,
                taxAmount,
                taxAmountUnit,
                workOrderNumber
            } = values;

            const invoiceSites = matchApiSitesToFormSites(
                formApplicationSites.filter(({ id }) =>
                    Boolean(invoicedApplicationSites.find(invoiceSiteId => invoiceSiteId === id))
                ),
                applicationSites
            );

            const payloadInvoiceItems =
                otherDescription && otherFee
                    ? [...invoiceItems, { amountDollars: otherFee, description: otherDescription }]
                    : invoiceItems;

            const payload = {
                applicationSites: applicationSites.map(({ id }) => id),
                applicatorId: applicatorInformation,
                chemicals,
                customerId,
                expirationDate,
                fileId,
                growerId,
                invoice: {
                    applicationSites: invoiceSites.map(({ id }) => id),
                    appliedArea: round(convertToSquareMeters(Number(appliedAcres), 'ac'), 2),
                    chemicalCostDollars: chemicalCost ?? 0,
                    fieldApplicationFlatFeeDollars: fieldApplicationFlatFee ?? 0,
                    fieldApplicationHours: fieldApplicationHours ?? 0,
                    fieldApplicationRate: fieldApplicationRate ?? 0,
                    fieldApplicationRateUnits: fieldApplicationRateUnit,
                    invoiceItems: payloadInvoiceItems,
                    taxAmount: taxAmount ?? 0,
                    taxAmountUnits: taxAmountUnit
                },
                notes: trimAndNullIfEmptyOrUndefined(notes),
                preHarvestInterval: convertDaysAndHoursToMilliseconds(preHarvestInterval, null),
                proposedArea: proposedAcres
                    ? convertToSquareMeters(
                          proposedAcres,
                          proposedAcresUnit as WorkOrderAcreageUnit
                      )
                    : null,
                proposedDate,
                reEntryInterval: convertDaysAndHoursToMilliseconds(
                    reEntryIntervalDays,
                    reEntryIntervalHours
                ),
                scheduledDate:
                    scheduledDate !== null
                        ? constructUtcDateTime(
                              scheduledDate,
                              scheduledTime,
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                              new Date(AAM_MIN_START)
                          )
                        : null,
                siteCommodity: trimAndNullIfEmptyOrUndefined(commodity),
                targetSprayRate: targetSprayRate
                    ? convertToLitersPerSquareMeter(
                          targetSprayRate,
                          targetSprayRateUnit as WorkOrderTargetRateUnit
                      )
                    : null,
                workOrderNumber: trimAndNullIfEmptyOrUndefined(workOrderNumber),
                workOrderStatus: status
            };

            const createOrUpdateUrl = isNew ? WORK_ORDER_API : `${WORK_ORDER_API}/${workOrderId}`;

            const { error } = await (isNew ? authenticatedPost : authenticatedPut)(
                createOrUpdateUrl,
                JSON.stringify(payload)
            );

            if (error) {
                throw new Error(error);
            }

            return true;
        },
        [
            authenticatedPost,
            authenticatedPut,
            convertDaysAndHoursToMilliseconds,
            convertToLitersPerSquareMeter,
            convertToSquareMeters,
            handleApplicationSites,
            handleCustomer,
            handleGrower,
            matchApiSitesToFormSites,
            workOrderId
        ]
    );

    const fetchProductUsageReports = useCallback(
        async (workOrderId: string) => {
            const { data, error } = await fetchAllProductUsageReportsForWorkOrder(workOrderId);

            if (error) {
                alert(error);
            }

            return data;
        },
        [fetchAllProductUsageReportsForWorkOrder]
    );

    return {
        createFinalSubmission,
        fetchProductUsageReports
    };
};

export default useData;
