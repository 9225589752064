import type { Point } from 'geojson';
import { type FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
    HorizontalContainer,
    Map,
    PageContainer,
    WithDrawerContainer
} from '@rantizo-software/rantizo-ui';

import OperatorDetailsMapPoint from 'components/OperatorDetailsMapPoint';

import useData from './hooks/useData';

import OperatorMapViewDrawer from './components/OperatorTileOverviewDrawer';
import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const OperatorTileOverviewPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { id } = useParams();

    const {
        handleBack,
        handleDelete,
        handleEdit,
        handleOnCollapse,
        handleOnExpand,
        isOpen,
        mapCenter,
        operatorResponse
    } = useData(id ?? '');

    const serviceTypes = useMemo(() => {
        if (!operatorResponse?.services) {
            return [];
        }

        return operatorResponse.services.flatMap(service => service.operatorServiceType);
    }, [operatorResponse]);

    const address = operatorResponse?.address;
    const name = operatorResponse?.name;

    const location = address?.location;

    return (
        <PageContainer testId={testId}>
            <WithDrawerContainer isOpen={isOpen}>
                <HorizontalContainer className={styles.horizontalContainer}>
                    <Map
                        center={mapCenter}
                        key={mapCenter?.lng}
                        mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
                    >
                        {name && location && (
                            <OperatorDetailsMapPoint
                                key={operatorResponse.id}
                                location={location as Point}
                                services={serviceTypes}
                                title={name}
                            />
                        )}
                    </Map>
                </HorizontalContainer>

                {operatorResponse && (
                    <OperatorMapViewDrawer
                        isOpen={isOpen}
                        onBack={handleBack}
                        onCollapse={handleOnCollapse}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        onExpand={handleOnExpand}
                        operatorResponse={operatorResponse}
                    />
                )}
            </WithDrawerContainer>
        </PageContainer>
    );
};

export default OperatorTileOverviewPage;
