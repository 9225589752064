import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('customerDeleteButton');

    return {
        DELETE_BUTTON_TEXT: t('deleteButtonText'),
        DELETE_CONFIRMATION_BODY: t('deleteConfirmationBody'),
        DELETE_CONFIRMATION_TITLE: t('deleteConfirmationTitle')
    };
};

export default useTranslation;
