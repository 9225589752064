import { FormSectionContainer } from '@rantizo-software/rantizo-ui';

import InputLabel from 'components/InputLabel';
import CompanyContactFields from 'components/deprecating/CompanyContactFields';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const CustomerInformation: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { TITLE } = useTranslation();

    return (
        <FormSectionContainer testId={testId}>
            <InputLabel isRequired={isEditable} text={TITLE} />

            <CompanyContactFields
                isDisabled={hasProductUsageReport}
                isEditable={isEditable}
                namePrefix="customer."
            />
        </FormSectionContainer>
    );
};

export default CustomerInformation;
