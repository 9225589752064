import { useMemo } from 'react';

import {
    HorizontalContainer,
    Map,
    PageContainer,
    WithDrawerContainer
} from '@rantizo-software/rantizo-ui';

import CustomerMapPoint from 'components/CustomerMapPoint';

import useData from './hooks/useData';

import CustomersDrawer from './CustomersDrawer';
import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const CustomersPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const {
        center,
        customerSearchForm,
        customers,
        fetchCustomerData,
        queryParams,
        selectedCustomerId,
        setSelectedCustomerId,
        zoomLevel
    } = useData();

    const customerPoints = useMemo(
        () =>
            customers.objects.map(customer => (
                <CustomerMapPoint
                    customer={customer}
                    key={`${customer.id}+${selectedCustomerId}`}
                    selectedCustomerId={selectedCustomerId}
                    setSelectedCustomerId={setSelectedCustomerId}
                />
            )),
        [customers.objects, selectedCustomerId, setSelectedCustomerId]
    );

    return (
        <PageContainer testId={testId}>
            <WithDrawerContainer isOpen>
                <CustomersDrawer
                    customerForm={customerSearchForm}
                    customers={customers}
                    fetchCustomerData={fetchCustomerData}
                    queryParams={queryParams}
                    selectedCustomerId={selectedCustomerId}
                    setSelectedCustomerId={setSelectedCustomerId}
                />

                <HorizontalContainer>
                    <Map
                        center={center}
                        key={`${center?.lat}+${center?.lng}+${zoomLevel}`}
                        mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
                        zoom={zoomLevel}
                    >
                        {customerPoints}
                    </Map>
                </HorizontalContainer>
            </WithDrawerContainer>
        </PageContainer>
    );
};

export default CustomersPage;
