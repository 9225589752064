import type { CustomerV2Response } from 'generatedTypes/workOrder/models';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { InfiniteScroll, PageLoader, VerticalContainer } from '@rantizo-software/rantizo-ui';

import CustomerListItem from 'components/CustomerListItem';

import { TEST_ID } from './constants';
import type { FunctionComonent, Props } from './types';

import styles from './styles.module.scss';

const CustomerList: FunctionComonent<Props> = ({
    customers,
    fetchCustomerData,
    queryParams,
    selectedCustomerId,
    setSelectedCustomerId,
    testId = TEST_ID
}: Props) => {
    const customerItemsRefs = useRef<Record<string, HTMLDivElement | null>>({});

    useEffect(() => {
        if (selectedCustomerId && customerItemsRefs.current[selectedCustomerId]) {
            customerItemsRefs.current[selectedCustomerId]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [selectedCustomerId]);

    const listItems = useMemo(
        () =>
            customers.objects.map((customer: CustomerV2Response) => (
                <div
                    key={customer.id}
                    ref={element => (customerItemsRefs.current[customer.id] = element)}
                >
                    <CustomerListItem
                        address={customer.address ?? {}}
                        isSelected={customer.id === selectedCustomerId}
                        name={customer.company}
                        numberOfFarms={0}
                        numberOfFields={0}
                        onClick={() => setSelectedCustomerId(customer.id)}
                        phoneNumber={customer.phoneNumber}
                    />
                </div>
            )),
        [customers.objects, selectedCustomerId, setSelectedCustomerId]
    );

    const handleFetchNextPage = useCallback(async () => {
        await fetchCustomerData({ ...queryParams, pageToken: customers.nextPageToken });
    }, [customers.nextPageToken, fetchCustomerData, queryParams]);

    return (
        <VerticalContainer testId={testId}>
            <InfiniteScroll
                className={styles.infiniteScroll}
                fetchNextPage={handleFetchNextPage}
                hasNextPage={customers.hasNext}
                loader={<PageLoader />}
                nextPageToken={customers.nextPageToken}
            >
                {listItems}
            </InfiniteScroll>
        </VerticalContainer>
    );
};

export default CustomerList;
