import { memo, useCallback, useEffect } from 'react';

import {
    DeprecatingWithErrorMessage as WithErrorMessage,
    useValidation
} from '@rantizo-software/rantizo-ui';

import AcresFlownDropdown from 'components/AcresFlownDropdown';

import useTranslation from './hooks/useTranslation';

import type { FormFieldProps } from './types';

const AcresFlownDropdownField = <T extends object>(props: FormFieldProps<T>) => {
    const { form, isDisabled = false, isEditable = true, isRequired = false, name } = props;
    const { getFieldError, getValues, handleChange, handleError, handleValid, register } = form;

    const { ref } = register<HTMLInputElement>(name);

    const { REQUIRED_ERROR_MESSAGE } = useTranslation();

    const { validateRequired } = useValidation();

    const validateValue = useCallback(
        (value: string) => {
            const isValid = validateRequired(isRequired)(value);

            if (!isValid) {
                handleError(name, { message: REQUIRED_ERROR_MESSAGE });
            }

            return isValid;
        },
        [REQUIRED_ERROR_MESSAGE, handleError, isRequired, name, validateRequired]
    );

    const handleFieldChange = (value: string) => {
        if (!name) {
            return;
        }

        handleChange(name, value);

        if (validateValue(value)) {
            handleValid(name);
        }
    };

    useEffect(() => {
        validateValue(ref.current.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fieldErrors = getFieldError(name);

    return (
        <WithErrorMessage text={fieldErrors ? fieldErrors[0].message : ''}>
            <AcresFlownDropdown
                hasError={!!fieldErrors}
                isDisabled={isDisabled}
                isEditable={isEditable}
                onChange={handleFieldChange}
                onError={(message?: string) => handleError(name, { message })}
                ref={ref}
                selectedValue={getValues(name) as number}
            />
        </WithErrorMessage>
    );
};

AcresFlownDropdownField.displayName = 'AcresFlownDropdownField';

export default memo(
    AcresFlownDropdownField,
    (prevProps, nextProps) =>
        prevProps.name === nextProps.name &&
        prevProps.form.formErrors === nextProps.form.formErrors &&
        prevProps.isEditable === nextProps.isEditable
) as typeof AcresFlownDropdownField;
