import { useEffect } from 'react';

import { PageLoader } from '@rantizo-software/rantizo-ui';

import useLogout from 'hooks/useLogout';

import type { FunctionComponent } from './types';

const LogoutPage: FunctionComponent = () => {
    const { logout } = useLogout();

    useEffect(() => {
        logout();
    }, [logout]);

    return <PageLoader />;
};

export default LogoutPage;
