import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { OperatorResponse } from '@rantizo-software/rantizo-ui';

import useBack from 'hooks/useBack';
import useOperators from 'hooks/useOperators';
import usePageRoutes from 'hooks/usePageRoutes';

const useData = (operatorId: string) => {
    const [isOpen, setIsOpen] = useState(true);

    const { deleteOperatorById, fetchOperatorById } = useOperators();

    const [mapCenter, setMapCenter] = useState({ lat: 41.6297185, lng: -91.5704319 });

    const [operatorResponse, setOperatorResponse] = useState<OperatorResponse>();

    const { editOperatorMapPage } = usePageRoutes();

    const navigate = useNavigate();

    const fetchOperator = useCallback(
        async (id: string) => {
            try {
                const result = await fetchOperatorById(id);

                setOperatorResponse(result);

                const { address } = result;

                if (address) {
                    const lat = address.location.coordinates[1];
                    const lng = address.location.coordinates[0];

                    setMapCenter({ lat: lat, lng: lng });
                }
            } catch (e) {
                console.error(e);
                alert('Error Fetching Operator Try again later');
            }
        },
        [fetchOperatorById]
    );

    useEffect(() => {
        if (operatorId) {
            fetchOperator(operatorId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operatorId]);

    const handleOnCollapse = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleOnExpand = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const back = useBack();

    const handleBack = useCallback(() => {
        back(false);
    }, [back]);

    const handleDelete = useCallback(async () => {
        try {
            await deleteOperatorById(operatorId);
            handleBack();
        } catch (e) {
            console.error(e);
            alert('Error Deleting Operator Try again later');
        }
    }, [deleteOperatorById, handleBack, operatorId]);

    const handleEdit = useCallback(async () => {
        navigate(editOperatorMapPage(operatorId));
    }, [editOperatorMapPage, navigate, operatorId]);

    return {
        handleBack,
        handleDelete,
        handleEdit,
        handleOnCollapse,
        handleOnExpand,
        isOpen,
        mapCenter,
        operatorResponse
    };
};

export default useData;
