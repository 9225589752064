import { useCallback, useMemo, useState } from 'react';

import { round } from '@@utils/math';
import { FormSectionContainer, PrimaryButton } from '@rantizo-software/rantizo-ui';

import LargeText from 'components/LargeText';
import SideBarContentContainer from 'components/SideBarContentContainer';
import WithLabel from 'components/WithLabel';
import ApplicationDateTimeForm from 'components/form/ApplicationDateTimeForm';
import DistanceInput from 'components/form/DistanceInput';
import MultiFileUpload from 'components/form/MultiFileUpload';

import useConvert from 'hooks/useConvert';
import useXag from 'hooks/useXag';

import useTranslation from './hooks/useTranslation';
import useXagFileUpload from './hooks/useXagFileUpload';

import { TEST_ID } from './constants';
import type { FunctionComponent, NumberWithUnit, Props } from './types';

const XagFileUploadTab: FunctionComponent<Props> = ({
    onBack,
    onFlightsCreated,
    selectedAircraft,
    testId = TEST_ID
}) => {
    const {
        addUploadedFile,
        getPresignedUrl,
        getUploadedFiles,
        modifyFileRequest,
        setUploadedFiles,
        validateFile
    } = useXagFileUpload({ selectedAircraft });

    const {
        ACCEPTED_FILE_TYPES,
        ASSOCIATED_APPLICATION_DATE,
        BACK,
        FILE_TYPE,
        FILE_UPLOADS,
        SAVE_TO_FLIGHT_LOG,
        START_DATE_ERROR,
        SWATH_WIDTH,
        UNKNOWN_ERROR,
        ZERO_SWATH_WIDTH
    } = useTranslation();
    const [isProcessingFlight, setIsProcessingFlight] = useState<boolean>(false);

    const { createFlights, createKmlFlight } = useXag();

    const { convertFeetToMeters, convertMetersToFeet } = useConvert();

    const defaultSwathWidth = useMemo(
        () => ({
            unit: 'FEET',
            value: round(convertMetersToFeet(selectedAircraft.defaultSwathWidthMeters ?? 0.0), 2)
        }),
        [selectedAircraft, convertMetersToFeet]
    );

    const [swathWidth, setSwathWidth] = useState<NumberWithUnit | undefined>(defaultSwathWidth);

    const [startTime, setStartTime] = useState<string | null>(null);

    const isReadyToProcess = useMemo(
        () =>
            swathWidth?.value !== undefined && getUploadedFiles().length > 0 && startTime !== null,
        [getUploadedFiles, startTime, swathWidth?.value]
    );

    const handleStartTimeChange = useCallback((date: string | null) => {
        setStartTime(date);
    }, []);

    const createFlightsFromFiles = useCallback(async () => {
        setIsProcessingFlight(true);

        try {
            let swathWidthMeters = swathWidth?.value ?? 0.0;

            if (swathWidth?.unit === 'FEET') {
                swathWidthMeters = convertFeetToMeters(swathWidthMeters);
            }

            if (swathWidthMeters === 0.0) {
                alert(ZERO_SWATH_WIDTH);

                return;
            }

            if (startTime === null) {
                alert(START_DATE_ERROR);

                return;
            }

            const flights = await Promise.all(
                getUploadedFiles().map(async ({ fileName, objectKey }) => {
                    if (fileName.toUpperCase().endsWith('.KML')) {
                        return await createKmlFlight({
                            objectKey,
                            startTime: new Date(startTime)
                        });
                    } else {
                        return await createFlights({
                            objectKey,
                            swathWidthMeters
                        });
                    }
                })
            );

            onFlightsCreated(flights.flat());
        } catch (error) {
            alert(UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsProcessingFlight(false);
        }
    }, [
        START_DATE_ERROR,
        UNKNOWN_ERROR,
        ZERO_SWATH_WIDTH,
        convertFeetToMeters,
        createFlights,
        createKmlFlight,
        getUploadedFiles,
        onFlightsCreated,
        startTime,
        swathWidth?.unit,
        swathWidth?.value
    ]);

    return (
        <SideBarContentContainer testId={testId}>
            <FormSectionContainer>
                <WithLabel text={FILE_TYPE}>
                    <LargeText text={ACCEPTED_FILE_TYPES} />
                </WithLabel>

                <WithLabel text={ASSOCIATED_APPLICATION_DATE}>
                    <ApplicationDateTimeForm onDateChange={handleStartTimeChange} />
                </WithLabel>

                <WithLabel text={SWATH_WIDTH}>
                    <DistanceInput
                        onChange={(value: NumberWithUnit, _onError) => {
                            setSwathWidth(value);
                        }}
                        value={defaultSwathWidth}
                    />
                </WithLabel>

                <WithLabel text={FILE_UPLOADS}>
                    <MultiFileUpload
                        acceptedFileTypes={'.csv, .kml, .zip'}
                        addUploadedFile={addUploadedFile}
                        getPresignedUrl={getPresignedUrl}
                        getUploadedFiles={getUploadedFiles}
                        isEditable={true}
                        modifyFileRequest={modifyFileRequest}
                        setUploadedFiles={setUploadedFiles}
                        validateFile={validateFile}
                    />
                </WithLabel>

                <PrimaryButton isDisabled={isProcessingFlight} onClick={onBack} text={BACK} />

                <PrimaryButton
                    onClick={() => {
                        createFlightsFromFiles();
                    }}
                    isDisabled={isProcessingFlight || !isReadyToProcess}
                    text={SAVE_TO_FLIGHT_LOG}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default XagFileUploadTab;
