import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('emailField');

    return {
        INVALID_EMAIL: t('invalidEmail'),
        REQUIRED_ERROR_MESSAGE: t('requiredErrorMessage'),
        TOO_LONG: t('tooLong'),
        TOO_SHORT: t('tooShort')
    };
};

export default useTranslation;
