import { BodyText, Button } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const IconTextButton = ({ children, className, onClick, testId = TEST_ID, text }: Props) => (
    <Button className={`${styles.button} ${className}`} onClick={onClick} testId={testId}>
        {children}

        <BodyText className={styles.bodyText}>{text}</BodyText>
    </Button>
);

export default IconTextButton;
