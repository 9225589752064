import useTranslation from '../useTranslate';
import type { AddressRequestState } from 'generatedTypes/workOrder/models';
import type { AddressRequest } from 'generatedTypes/workOrder/models/addressRequest';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { useGeocoding } from '@rantizo-software/rantizo-ui';
import useForm from '@rantizo-software/rantizo-ui/dist/deprecating/hooks/useForm';

import type { CustomerFormSchema } from 'components/form/CustomerForm/types';

import useCustomers from 'hooks/useCustomers';
import usePageRoutes from 'hooks/usePageRoutes';
import useToast from 'hooks/useToast';

import {
    ADDRESS_LINE_ONE_KEY,
    ADDRESS_LINE_TWO_KEY,
    CITY_KEY,
    COMPANY_KEY,
    CONTACT_FIRST_NAME_KEY,
    CONTACT_LAST_NAME_KEY,
    DEFAULT_ADDRESS_COUNTRY,
    EMAIL_KEY,
    PHONE_NUMBER_KEY,
    STATE_KEY,
    ZIP_CODE_KEY
} from './constants';

const useData = () => {
    const { id } = useParams<{ id: string }>();

    const { ON_FETCH_ERROR, ON_SAVE_ERROR, ON_SAVE_SUCCESS } = useTranslation();

    const { broadcastToast } = useToast();

    const navigate = useNavigate();

    const [canSubmit, setCanSubmit] = useState(false);

    const { customersPage } = usePageRoutes();

    const { createCustomer, fetchCustomer, updateCustomer } = useCustomers();

    const [resetKey, setResetKey] = useState<string>(uuid());

    const [isOpen, setIsOpen] = useState(false);

    const form = useForm<CustomerFormSchema>({
        initialSchema: {
            address: {
                addressLine1: null,
                addressLine2: null,
                city: null,
                state: null,
                zipCode: null
            },
            company: '',
            contactFirstName: undefined,
            contactLastName: undefined,
            email: undefined,
            phoneNumber: undefined
        },
        onFormChange: ({ hasErrors }) => setCanSubmit(!hasErrors)
    });

    const fetchCustomerFormData = useCallback(async () => {
        if (id) {
            try {
                const customer = await fetchCustomer(id);

                const { address, company, contact, email, phoneNumber } = customer;

                const { address1, address2, city, state, zipCode } = address;

                if (customer) {
                    const contactData = contact?.split(' ');
                    let contactFirstName = contact;
                    let contactLastName = '';

                    if (contactData?.length >= 2) {
                        contactFirstName = contactData[0];
                        contactLastName = contactData[1];
                    }

                    const existingCustomerData = {
                        address: {
                            addressLine1: address1,
                            addressLine2: address2,
                            city,
                            state,
                            zipCode
                        },
                        company,
                        contactFirstName: contactFirstName,
                        contactLastName: contactLastName,
                        email,
                        phoneNumber
                    };

                    form.handleChange(
                        ADDRESS_LINE_ONE_KEY,
                        existingCustomerData.address.addressLine1
                    );
                    form.handleValid(ADDRESS_LINE_ONE_KEY);

                    form.handleChange(
                        ADDRESS_LINE_TWO_KEY,
                        existingCustomerData.address.addressLine2
                    );
                    form.handleValid(ADDRESS_LINE_TWO_KEY);

                    form.handleChange(CITY_KEY, existingCustomerData.address.city);
                    form.handleValid(CITY_KEY);

                    form.handleChange(STATE_KEY, existingCustomerData.address.state);
                    form.handleValid(STATE_KEY);

                    form.handleChange(ZIP_CODE_KEY, existingCustomerData.address.zipCode);
                    form.handleValid(ZIP_CODE_KEY);

                    form.handleChange(
                        CONTACT_FIRST_NAME_KEY,
                        existingCustomerData.contactFirstName
                    );
                    form.handleValid(CONTACT_FIRST_NAME_KEY);

                    form.handleChange(CONTACT_LAST_NAME_KEY, existingCustomerData.contactLastName);
                    form.handleValid(CONTACT_LAST_NAME_KEY);

                    form.handleChange(EMAIL_KEY, existingCustomerData.email);
                    form.handleValid(EMAIL_KEY);

                    form.handleChange(PHONE_NUMBER_KEY, existingCustomerData.phoneNumber);
                    form.handleValid(PHONE_NUMBER_KEY);

                    form.handleChange(COMPANY_KEY, existingCustomerData.company);
                    form.handleValid(COMPANY_KEY);

                    setResetKey(uuid());
                }
            } catch (error) {
                broadcastToast({ text: ON_FETCH_ERROR, type: 'error' });
                console.error(error);
            }
        }
    }, [ON_FETCH_ERROR, broadcastToast, fetchCustomer, form, id]);

    useEffect(
        () => {
            fetchCustomerFormData();
            setResetKey(uuid());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const { geocode } = useGeocoding();

    const createPayloadFromForm = useCallback(async () => {
        const { address, company, contactFirstName, contactLastName, email, phoneNumber } =
            form.getValues() as CustomerFormSchema;

        const { addressLine1, addressLine2, city, state, zipCode } = address;

        const addressComponents = [addressLine1, addressLine2, city, state, zipCode].filter(
            Boolean
        );

        const formattedAddress =
            addressComponents.length > 0 ? addressComponents.join(', ') : undefined;

        let addressRequest: AddressRequest = {
            address1: addressLine1 ?? undefined,
            address2: addressLine2 ?? undefined,
            city: city ?? undefined,
            country: DEFAULT_ADDRESS_COUNTRY,
            location: undefined,
            placeId: undefined,
            state: (state as AddressRequestState) ?? undefined,
            zipCode: zipCode ?? undefined
        };

        if (formattedAddress) {
            const result = await geocode(formattedAddress);

            if (result) {
                addressRequest = {
                    ...addressRequest,
                    location: {
                        coordinates: [
                            result.geometry.location.lng(),
                            result.geometry.location.lat()
                        ],
                        type: 'Point'
                    },
                    placeId: result.place_id
                };
            }
        }

        const contact =
            contactFirstName || contactLastName
                ? `${contactFirstName ?? ''} ${contactLastName ?? ''}`
                : undefined;

        return {
            address: addressRequest,
            company,
            contact: contact,
            email,
            phoneNumber
        };
    }, [form, geocode]);

    const handleSubmit = useCallback(async () => {
        try {
            setCanSubmit(false);
            const payload = await createPayloadFromForm();

            if (id) {
                await updateCustomer(id, payload);
            } else {
                await createCustomer(payload);
            }
            broadcastToast({ text: ON_SAVE_SUCCESS, type: 'success' });
            navigate(customersPage);
        } catch (error) {
            console.error(error);
            broadcastToast({ text: ON_SAVE_ERROR, type: 'error' });
        } finally {
            setCanSubmit(true);
        }
    }, [
        ON_SAVE_ERROR,
        ON_SAVE_SUCCESS,
        broadcastToast,
        createCustomer,
        createPayloadFromForm,
        customersPage,
        id,
        navigate,
        updateCustomer
    ]);

    const handleOnSaveClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleOnSaveOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return {
        canSubmit,
        fetchCustomerFormData,
        form,
        handleOnSaveClose,
        handleOnSaveOpen,
        handleSubmit,
        isOpen,
        resetKey
    };
};

export default useData;
