import { useCallback, useState } from 'react';

import type { RequestShapeFileResponse } from 'components/DownloadShapeFile/hooks/useData/types';

import useFetch from 'hooks/useFetch';
import useFlights from 'hooks/useFlights';
import useServerSentEvents from 'hooks/useServerSentEvents';

import { CREATE_SHAPEFILE_API, SHAPE_FILE_JOB_URL } from './constants';
import type { EventSourceErrorHandler, EventSourceMessageHandler } from './types';

const useShapeFile = ({
    aamId,
    handleShapeFile
}: {
    aamId: string;
    handleShapeFile: ({ shapeFile }: { shapeFile: string }) => Promise<void>;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { authenticatedGet, authenticatedPost } = useFetch();
    const { fetchFlightsForAAM } = useFlights();
    const { closeAuthenticatedConnection, openAuthenticatedConnection } = useServerSentEvents();

    const fetchShapefileUrl = useCallback(
        async ({ shapeFileId }: { shapeFileId: string }) =>
            await authenticatedGet<{ shapeFile: string }>(`${CREATE_SHAPEFILE_API}/${shapeFileId}`),
        [authenticatedGet]
    );

    const handleError: EventSourceErrorHandler = useCallback(
        async error => {
            closeAuthenticatedConnection();
            console.log(error);
        },
        [closeAuthenticatedConnection]
    );

    const handleMessage: EventSourceMessageHandler = useCallback(
        async event => {
            const message: RequestShapeFileResponse = JSON.parse(event.data);

            if (message.status === 'COMPLETE') {
                const { shapeFileId } = message;

                closeAuthenticatedConnection();

                if (!shapeFileId) {
                    return;
                }

                const { data } = await fetchShapefileUrl({ shapeFileId });

                handleShapeFile({ shapeFile: data?.shapeFile ?? '' });
            } else if (message.status === 'PENDING') {
                return;
            }
        },
        [closeAuthenticatedConnection, fetchShapefileUrl, handleShapeFile]
    );

    const fetchFlights = useCallback(
        async (aamId: string) => {
            const fetchedFlights = await fetchFlightsForAAM({ aamId });
            const { data: flightsData, error: flightsError } = fetchedFlights;
            const foundFlights = flightsData?.length;

            if (!foundFlights || flightsError) {
                if (!foundFlights) {
                    console.error('No flights were found to generate a SHP file');
                }

                return fetchedFlights;
            }

            return flightsData;
        },
        [fetchFlightsForAAM]
    );

    const createShapefile = useCallback(
        async (aamId: string | null, product: string) => {
            if (!aamId) {
                return { data: null, error: 'no aam id' };
            }

            const flightsData = await fetchFlights(aamId);

            return await authenticatedPost<RequestShapeFileResponse>(
                CREATE_SHAPEFILE_API,
                JSON.stringify({
                    flightIds: flightsData,
                    formatType: 'FIELD_VIEW',
                    product: product,
                    shapeFileType: 'AS_APPLIED_MAP',
                    systemOfMeasurement: 'IMPERIAL'
                })
            );
        },
        [authenticatedPost, fetchFlights]
    );

    const initiateConnection = useCallback(async () => {
        setIsLoading(true);

        try {
            const { data } = await createShapefile(aamId, 'fieldView');

            await openAuthenticatedConnection(`${SHAPE_FILE_JOB_URL}/${data?.id}`, {
                handleError: handleError,
                handleMessage: handleMessage
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }, [createShapefile, aamId, openAuthenticatedConnection, handleError, handleMessage]);

    return {
        createShapefile,
        fetchShapefileUrl,
        initiateConnection,
        isLoading
    };
};

export default useShapeFile;
