/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * AcreConnect API
 * AcreConnect API documentation
 * OpenAPI spec version: 1.0
 */
import type {
  GetOperatorsParams,
  OperatorEnabledRequest,
  OperatorRequest,
  OperatorResponse,
  PagedResponseOperatorResponse
} from '../models'


export type getOperatorResponse = {
  data: OperatorResponse;
  status: number;
  headers: Headers;
}

export const getGetOperatorUrl = (id: string,) => {


  return `/api/operators/${id}`
}

export const getOperator = async (id: string, options?: RequestInit): Promise<getOperatorResponse> => {
  
  const res = await fetch(getGetOperatorUrl(id),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


export type updateOperatorResponse = {
  data: OperatorResponse;
  status: number;
  headers: Headers;
}

export const getUpdateOperatorUrl = (id: string,) => {


  return `/api/operators/${id}`
}

export const updateOperator = async (id: string,
    operatorRequest: OperatorRequest, options?: RequestInit): Promise<updateOperatorResponse> => {
  
  const res = await fetch(getUpdateOperatorUrl(id),
  {      
    ...options,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      operatorRequest,)
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


export type deleteOperatorResponse = {
  data: void;
  status: number;
  headers: Headers;
}

export const getDeleteOperatorUrl = (id: string,) => {


  return `/api/operators/${id}`
}

export const deleteOperator = async (id: string, options?: RequestInit): Promise<deleteOperatorResponse> => {
  
  const res = await fetch(getDeleteOperatorUrl(id),
  {      
    ...options,
    method: 'DELETE'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


export type updateOperatorEnabledStatusResponse = {
  data: OperatorResponse;
  status: number;
  headers: Headers;
}

export const getUpdateOperatorEnabledStatusUrl = (id: string,) => {


  return `/api/operators/${id}/enabled`
}

export const updateOperatorEnabledStatus = async (id: string,
    operatorEnabledRequest: OperatorEnabledRequest, options?: RequestInit): Promise<updateOperatorEnabledStatusResponse> => {
  
  const res = await fetch(getUpdateOperatorEnabledStatusUrl(id),
  {      
    ...options,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      operatorEnabledRequest,)
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


export type getOperatorsResponse = {
  data: PagedResponseOperatorResponse;
  status: number;
  headers: Headers;
}

export const getGetOperatorsUrl = (params: GetOperatorsParams,) => {
  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    
    if (value !== undefined) {
      normalizedParams.append(key, value === null ? 'null' : value.toString())
    }
  });

  return normalizedParams.size ? `/api/operators?${normalizedParams.toString()}` : `/api/operators`
}

export const getOperators = async (params: GetOperatorsParams, options?: RequestInit): Promise<getOperatorsResponse> => {
  
  const res = await fetch(getGetOperatorsUrl(params),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


export type createOperatorResponse = {
  data: OperatorResponse;
  status: number;
  headers: Headers;
}

export const getCreateOperatorUrl = () => {


  return `/api/operators`
}

export const createOperator = async (operatorRequest: OperatorRequest, options?: RequestInit): Promise<createOperatorResponse> => {
  
  const res = await fetch(getCreateOperatorUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...options?.headers },
    body: JSON.stringify(
      operatorRequest,)
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


