import { Field, Formik } from 'formik';

import {
    BodyText,
    FormSectionContainer,
    PageTitle,
    SubmitButton,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import ErrorMessage from 'components/ErrorMessage';
import FormContainer from 'components/FormContainer';
import OnboardingBodyText from 'components/OnboardingBodyText';
import OnboardingButtonContainer from 'components/OnboardingButtonContainer';
import OnboardingLogout from 'components/OnboardingLogout';
import OnboardingPageTitle from 'components/OnboardingPageTitle';
import Page from 'components/Page';
import AddressFields from 'components/deprecating/AddressFields';
import FormInput from 'components/deprecating/FormInput';
import FormPhoneInput from 'components/deprecating/FormPhoneInput';

import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import { COMPANY_NAME_TEST_ID, PHONE_TEST_ID, TEST_ID } from './constants';
import type { FormikProps, FunctionComponent, InferType, Props } from './types';

import styles from './styles.module.scss';

const CompanyDetailsForm: FunctionComponent<Props> = ({
    buttonText,
    description,
    initialValues,
    isLoading,
    isOnboarding,
    onBack,
    onSubmit,
    testId = TEST_ID,
    title
}) => {
    const { PLACEHOLDERS } = useTranslation();
    const companySchema = useSchema();

    type CompanySchema = InferType<typeof companySchema>;

    const titleComponent =
        title &&
        (isOnboarding ? (
            <OnboardingPageTitle className={styles.onboardingTitle} text={title} />
        ) : (
            <PageTitle text={title} />
        ));

    const descriptionComponent =
        description &&
        (isOnboarding ? (
            <OnboardingBodyText className={styles.onboardingDescription} text={description} />
        ) : (
            <BodyText text={description} />
        ));

    return (
        <Formik
            data-testid={testId}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={companySchema}
        >
            {({ isSubmitting }: FormikProps<CompanySchema>) => (
                <FormContainer>
                    <Page onBack={onBack} testId={testId} title={titleComponent}>
                        <FormSectionContainer>
                            <VerticalContainer className={styles.verticalContainer}>
                                {descriptionComponent}

                                <FormSectionContainer>
                                    <Field
                                        component={FormInput}
                                        label={PLACEHOLDERS.NAME}
                                        name="companyName"
                                        testId={COMPANY_NAME_TEST_ID}
                                        type="text"
                                    />

                                    <AddressFields />

                                    <Field
                                        className={styles.formPhoneInput}
                                        component={FormPhoneInput}
                                        label={PLACEHOLDERS.PHONE}
                                        name="phone"
                                        placeholder={PLACEHOLDERS.OPTIONAL}
                                        testId={PHONE_TEST_ID}
                                        type="text"
                                    />

                                    <ErrorMessage name="location" />
                                </FormSectionContainer>
                            </VerticalContainer>

                            <OnboardingButtonContainer>
                                <SubmitButton
                                    isDisabled={isSubmitting || isLoading}
                                    isLoading={isSubmitting || isLoading}
                                    text={buttonText}
                                />

                                {isOnboarding && <OnboardingLogout />}
                            </OnboardingButtonContainer>
                        </FormSectionContainer>
                    </Page>
                </FormContainer>
            )}
        </Formik>
    );
};

export default CompanyDetailsForm;
