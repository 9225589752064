import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('createCustomerPage');

    return {
        CUSTOMER: t('customer'),
        ON_FETCH_ERROR: t('onFetchError'),
        ON_SAVE_ERROR: t('onSaveError'),
        ON_SAVE_SUCCESS: t('onSaveSuccess'),
        SAVE: t('save')
    };
};

export default useTranslation;
