import IconTextButton from '../IconTextButton';

import { GrowerIcon } from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const AddGrowerButton = ({ className, onClick, testId = TEST_ID }: Props) => {
    const { ADD_GROWER } = useTranslation();

    return (
        <IconTextButton
            className={`${className} ${styles.iconTextButton}`}
            onClick={onClick}
            testId={testId}
            text={ADD_GROWER}
        >
            <GrowerIcon className={styles.growerIcon} />
        </IconTextButton>
    );
};

export default AddGrowerButton;
