import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('operatorMapPoint');

    return {
        AERIAL_APPLICATION: t('aerialApplication'),
        AERIAL_IMAGING: t('aerialImaging')
    };
};

export default useTranslation;
