const translations = {
    companyDetails: 'Company Details',
    companyName: 'Company Name',
    contact: 'Contact',
    email: 'Email Address',
    phoneNumber: 'Phone Number',
    sameAsOrganization: 'Same as Organization Details'
};

export default translations;
