export const generateNumericDropdownItems = (start: number, end: number, step: number) => {
    const items = [];
    const plusLabel = end + step;

    for (let i = start; i <= end; i += step) {
        items.push({ label: `${i}`, type: 'number', value: i });
    }

    if (plusLabel) {
        items.push({ label: `${plusLabel}+`, type: 'number', value: plusLabel });
    }

    return items;
};
