import { useCallback, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    ContentText,
    HorizontalContainer,
    InfoIcon,
    PrimaryButton
} from '@rantizo-software/rantizo-ui';

import DestructiveActionButton from 'components/DestructiveActionButton';
import DisconnectConfirmationModal from 'components/DisconnectConfirmationModal';
import ItemContainer from 'components/ItemContainer';
import LabelPrimaryText from 'components/LabelPrimaryText';
import ListText from 'components/ListText';
import SmallText from 'components/SmallText';
import JohnDeereOpsCenterIcon from 'components/icons/JohnDeereOpsCenterIcon';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import JohnDeereOrganizationsListTable from './components/JohnDeereOrganizationsListTable';
import { CODE, MANAGE_CONNECTIONS_PAGE, SOURCE, TEST_ID, UNDEFINED } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const JohnDeereConnectionListItem = ({ testId = TEST_ID }: Props) => {
    const [disconnectConfirmationModal, setDisconnectConfirmationModal] = useState(false);
    const {
        AUTHORIZED_ORGANIZATIONS_EXPLAINER_TEXT,
        AUTHORIZED_ORGANIZATIONS_LABEL,
        CONNECT,
        DISCONNECT,
        JOHN_DEERE_DESCRIPTION,
        JOHN_DEERE_TITLE
    } = useTranslation();

    const [searchParams] = useSearchParams();
    const code = useRef(searchParams.get(CODE) === UNDEFINED ? undefined : searchParams.get(CODE));
    const source = useRef(
        searchParams.get(SOURCE) === UNDEFINED ? undefined : searchParams.get(SOURCE)
    );

    const {
        fetchTokenOrRedirectAndLogIn,
        handleDeleteJohnDeereConnection,
        isJohnDeereConnected,
        isJohnDeereLoading,
        organizations
    } = useData({ code, source });

    const handleCloseConfirmationModal = useCallback(() => {
        setDisconnectConfirmationModal(false);
    }, []);

    return (
        <ItemContainer testId={testId}>
            <HorizontalContainer className={styles.connectionListItem}>
                {disconnectConfirmationModal && (
                    <DisconnectConfirmationModal
                        onClose={handleCloseConfirmationModal}
                        onConfirm={() => handleDeleteJohnDeereConnection()}
                    />
                )}

                <JohnDeereOpsCenterIcon />

                <HorizontalContainer className={styles.nestedText}>
                    <ListText className={styles.listText} text={JOHN_DEERE_TITLE} />

                    <ContentText text={JOHN_DEERE_DESCRIPTION} />
                </HorizontalContainer>

                {isJohnDeereConnected ? (
                    <DestructiveActionButton
                        isLoading={isJohnDeereLoading}
                        onClick={() => setDisconnectConfirmationModal(true)}
                        text={DISCONNECT}
                    />
                ) : (
                    <PrimaryButton
                        className={styles.primaryButton}
                        isLoading={isJohnDeereLoading}
                        onClick={() => fetchTokenOrRedirectAndLogIn(MANAGE_CONNECTIONS_PAGE)}
                        text={CONNECT}
                    />
                )}
            </HorizontalContainer>

            {isJohnDeereConnected && !isJohnDeereLoading && (
                <>
                    <LabelPrimaryText
                        className={styles.labelPrimaryText}
                        text={AUTHORIZED_ORGANIZATIONS_LABEL}
                    />

                    <HorizontalContainer className={styles.horizontalContainer}>
                        <InfoIcon className={styles.infoIcon} />

                        <SmallText className={styles.smallText}>
                            {AUTHORIZED_ORGANIZATIONS_EXPLAINER_TEXT}

                            <a
                                href="https://docs.rantizo.com/docs/authorize-organizations"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Learn More
                            </a>
                        </SmallText>
                    </HorizontalContainer>

                    <JohnDeereOrganizationsListTable
                        data={organizations}
                        isLoading={isJohnDeereLoading}
                    />
                </>
            )}
        </ItemContainer>
    );
};

export default JohnDeereConnectionListItem;
