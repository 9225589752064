import { FormSectionContainer, HorizontalContainer } from '@rantizo-software/rantizo-ui';
import PhoneNumberField from '@rantizo-software/rantizo-ui/dist/deprecating/components/fields/PhoneNumberField';

import AddressForm from 'components/form/AddressForm';
import CompanyNameField from 'components/form/FormFields/CompanyNameField';
import EmailField from 'components/form/FormFields/EmailField';
import TextField from 'components/form/FormFields/TextField';

import {
    ADDRESS_KEY,
    COMPANY_NAME_KEY,
    CONTACT_FIRST_NAME_KEY,
    CONTACT_LAST_NAME_KEY,
    EMAIL_KEY,
    PHONE_NUMBER_KEY,
    TEST_ID
} from './constants';
import type { CustomerFormSchema, FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CustomerForm: FunctionComponent<Props> = ({ form, isDisabled = false, testId = TEST_ID }) => (
    <FormSectionContainer testId={testId}>
        <HorizontalContainer className={styles.horizontalContainer}>
            <CompanyNameField<CustomerFormSchema>
                form={form}
                isDisabled={isDisabled}
                isRequired={true}
                name={COMPANY_NAME_KEY}
            />

            <TextField<CustomerFormSchema>
                form={form}
                isDisabled={isDisabled}
                name={CONTACT_FIRST_NAME_KEY}
            />

            <TextField<CustomerFormSchema>
                form={form}
                isDisabled={isDisabled}
                name={CONTACT_LAST_NAME_KEY}
            />
        </HorizontalContainer>

        <AddressForm formName={ADDRESS_KEY} isDisabled={isDisabled} parentForm={form} />

        <HorizontalContainer className={styles.horizontalContainer}>
            <PhoneNumberField form={form} isDisabled={isDisabled} name={PHONE_NUMBER_KEY} />

            <EmailField<CustomerFormSchema> form={form} isDisabled={isDisabled} name={EMAIL_KEY} />
        </HorizontalContainer>
    </FormSectionContainer>
);

export default CustomerForm;
