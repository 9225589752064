import { Overlay } from '@rantizo-software/rantizo-ui';

import FlatModal from 'components/FlatModal';
import OnboardingBodyText from 'components/OnboardingBodyText';
import OnboardingPageTitle from 'components/OnboardingPageTitle';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const AlertModal: FunctionComponent<Props> = ({ testId = TEST_ID, text = '', title = '' }) => (
    <Overlay testId={testId}>
        <FlatModal>
            <OnboardingPageTitle text={title} />

            <OnboardingBodyText text={text} />
        </FlatModal>
    </Overlay>
);

export default AlertModal;
