import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { PageContainer, PageLoader, VerticalContainer } from '@rantizo-software/rantizo-ui';

import ExitWorkOrderModal from 'components/ExitWorkOrderModal';
import FormContainer from 'components/FormContainer';
import FormNavigationButtons from 'components/FormNavigationButtons';
import PageWithSidebarHeader from 'components/PageWithSidebarHeader';
import SidebarOrDropdownLayout from 'components/SidebarOrDropdownLayout';
import WorkOrderFooter from 'components/WorkOrderFooter';
import WorkOrderMenu from 'components/WorkOrderMenu';
import ApplicationSite from 'components/work-order/ApplicationSite';
import ChemicalInformation from 'components/work-order/ChemicalInformation';
import CustomerInformation from 'components/work-order/CustomerInformation';
import Details from 'components/work-order/Details';
import GrowerInformation from 'components/work-order/GrowerInformation';
import InvoiceAssistant from 'components/work-order/InvoiceAssistant';
import Notes from 'components/work-order/Notes';
import SiteCommodity from 'components/work-order/SiteCommodity';

import useBack from 'hooks/useBack';
import usePageRoutes from 'hooks/usePageRoutes';
import useWorkOrder from 'hooks/useWorkOrder';

import useData from './hooks/useData';
import useSchema from './hooks/useSchema';
import useTranslation from './hooks/useTranslation';

import { defaultForm, defaultValidationMap } from './defaults';
import type {
    FunctionComponent,
    ProductUsageReport,
    Props,
    WorkOrderContentMap,
    WorkOrderForm,
    WorkOrderSection,
    WorkOrderSidebarItems,
    WorkOrderValidationMap
} from './types';

import styles from './styles.module.scss';

const WorkOrderPage: FunctionComponent<Props> = ({
    isDuplicate,
    isLoading,
    startEditable = false,
    title = '',
    workOrder
}) => {
    const { workOrderId } = useParams();
    const { deleteWorkOrder } = useWorkOrder();
    const back = useBack();
    const { createFinalSubmission, fetchProductUsageReports } = useData();
    const { schemaMap, validateSchema } = useSchema();

    const {
        CHEMICAL,
        COMMODITY,
        CUSTOMER,
        DELETE_ERROR,
        DETAILS,
        GROWER,
        INVOICE_ASSISTANT,
        NOTES,
        PRODUCT_USAGE_REPORT_DELETE_WARNING,
        PRODUCT_USAGE_REPORT_WARNING,
        SAVE_TO_SCHEDULE,
        SITE,
        SUBMISSION_CONFLICT,
        SUBMISSION_ERROR,
        TITLE
    } = useTranslation();

    const [showExitModal, setShowExitModal] = useState(false);

    const hasWorkOrder = Boolean(workOrder);
    const pageTitle = title || TITLE;

    const [section, setSection] = useState<WorkOrderSection>('details');
    const [validationMap, setValidationMap] =
        useState<WorkOrderValidationMap>(defaultValidationMap);
    const [isEditable, setIsEditable] = useState(!hasWorkOrder || startEditable);

    const [hasProductUsageReport, setHasProductUsageReport] = useState(false);
    const [productUsageReports, setProductUsageReports] = useState<ProductUsageReport[]>([]);

    const [sidebarItems, setSidebarItems] = useState<WorkOrderSidebarItems>({
        chemical: {
            index: 4,
            isDisabled: false,
            isInvalid: !validationMap.chemical,
            isViewOnly: !isEditable,
            isVisited: false,
            nextSection: 'commodity',
            previousSection: 'site',
            title: CHEMICAL
        },
        commodity: {
            index: 5,
            isDisabled: false,
            isInvalid: !validationMap.commodity,
            isViewOnly: !isEditable,
            isVisited: false,
            nextSection: 'notes',
            previousSection: 'chemical',
            title: COMMODITY
        },
        customer: {
            index: 1,
            isDisabled: false,
            isInvalid: !validationMap.customer,
            isViewOnly: !isEditable,
            isVisited: false,
            nextSection: 'grower',
            previousSection: 'details',
            title: CUSTOMER
        },
        details: {
            index: 0,
            isDisabled: false,
            isInvalid: !validationMap.details,
            isViewOnly: !isEditable,
            isVisited: true,
            nextSection: 'customer',
            title: DETAILS
        },
        grower: {
            index: 2,
            isDisabled: false,
            isInvalid: !validationMap.grower,
            isViewOnly: !isEditable,
            isVisited: false,
            nextSection: 'site',
            previousSection: 'customer',
            title: GROWER
        },
        invoice: {
            index: 7,
            isDisabled: false,
            isInvalid: !validationMap.invoice,
            isViewOnly: !isEditable,
            isVisited: false,
            previousSection: 'notes',
            title: INVOICE_ASSISTANT
        },
        notes: {
            index: 6,
            isDisabled: false,
            isInvalid: !validationMap.notes,
            isViewOnly: !isEditable,
            isVisited: false,
            nextSection: 'invoice',
            previousSection: 'commodity',
            title: NOTES
        },
        site: {
            index: 3,
            isDisabled: false,
            isInvalid: !validationMap.site,
            isViewOnly: !isEditable,
            isVisited: false,
            nextSection: 'chemical',
            previousSection: 'grower',
            title: SITE
        }
    });

    const [isFinalSubmit, setIsFinalSubmit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const { workOrderDuplicatePage } = usePageRoutes();

    const duplicateLink = workOrderDuplicatePage(workOrderId ?? '');

    const onDuplicate = useCallback(() => {
        navigate(duplicateLink);
    }, [duplicateLink, navigate]);

    const canFinalSubmit = useMemo(
        () =>
            hasWorkOrder ||
            (sidebarItems.details.isVisited &&
                sidebarItems.customer.isVisited &&
                sidebarItems.grower.isVisited),
        [
            hasWorkOrder,
            sidebarItems.customer.isVisited,
            sidebarItems.details.isVisited,
            sidebarItems.grower.isVisited
        ]
    );

    const emptyForm = useMemo(
        () => ({
            ...defaultForm,
            applicationSites: [
                {
                    ...defaultForm.applicationSites[0],
                    id: uuid()
                }
            ]
        }),
        []
    );

    const onSubmit = useCallback(
        async (
            values: WorkOrderForm,
            finalSubmit: boolean,
            section: string,
            onNext?: VoidFunction
        ) => {
            const workOrderSection = section as WorkOrderSection;

            if (!finalSubmit && onNext) {
                const sectionValid = await validateSchema(values, workOrderSection);

                setValidationMap(map => ({ ...map, section: sectionValid }));
                onNext();

                return;
            }

            const validateForms = {
                chemical: await validateSchema(values, 'chemical'),
                commodity: await validateSchema(values, 'commodity'),
                customer: await validateSchema(values, 'customer'),
                details: await validateSchema(values, 'details'),
                grower: await validateSchema(values, 'grower'),
                invoice: await validateSchema(values, 'invoice'),
                notes: await validateSchema(values, 'notes'),
                site: await validateSchema(values, 'site')
            };

            setValidationMap(validateForms);

            try {
                setIsSubmitting(true);
                await createFinalSubmission(values, workOrder, isDuplicate);
                back();
            } catch (e: unknown) {
                if (e instanceof Error && e.message?.includes('409')) {
                    alert(SUBMISSION_CONFLICT(values.workOrderNumber || ''));
                } else {
                    alert(SUBMISSION_ERROR);
                }
                setIsSubmitting(false);
            }
        },

        [
            SUBMISSION_CONFLICT,
            SUBMISSION_ERROR,
            back,
            createFinalSubmission,
            isDuplicate,
            validateSchema,
            workOrder
        ]
    );

    const onSectionChange = useCallback(
        (section: string) => {
            const workOrderSection = section as WorkOrderSection;

            if (sidebarItems[workOrderSection].isVisited || !isEditable || hasWorkOrder) {
                setSection(workOrderSection);
            }
        },
        [isEditable, sidebarItems, hasWorkOrder]
    );

    const setSectionAndVisitedMap = useCallback(
        (section: string) => {
            const workOrderSection = section as WorkOrderSection;

            if (!sidebarItems[workOrderSection].isVisited) {
                setSidebarItems(s => ({
                    ...s,
                    [workOrderSection]: {
                        ...s[workOrderSection],
                        isDisabled: false,
                        isVisited: true
                    }
                }));
            }

            setSection(workOrderSection);
        },
        [sidebarItems]
    );

    const handleBackButton = useMemo(() => {
        const previousSection = sidebarItems[section]?.previousSection;

        return previousSection
            ? () => {
                  setSectionAndVisitedMap(previousSection);
              }
            : undefined;
    }, [section, setSectionAndVisitedMap, sidebarItems]);

    const handleNextButton = useMemo(() => {
        const nextSection = sidebarItems[section]?.nextSection;

        return nextSection
            ? () => {
                  setSectionAndVisitedMap(nextSection);
              }
            : undefined;
    }, [section, setSectionAndVisitedMap, sidebarItems]);

    const onFormSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (values: any) => {
            onSubmit(values, isFinalSubmit, section, handleNextButton);

            setIsFinalSubmit(false);
        },
        [handleNextButton, isFinalSubmit, onSubmit, setIsFinalSubmit, section]
    );

    const contentMap: WorkOrderContentMap = {
        chemical: (
            <ChemicalInformation
                hasProductUsageReport={hasProductUsageReport}
                isEditable={isEditable}
            />
        ),
        commodity: (
            <SiteCommodity hasProductUsageReport={hasProductUsageReport} isEditable={isEditable} />
        ),
        customer: (
            <CustomerInformation
                hasProductUsageReport={hasProductUsageReport}
                isEditable={isEditable}
            />
        ),
        details: (
            <Details
                hasProductUsageReport={hasProductUsageReport}
                isEditable={isEditable}
                workOrder={workOrder}
            />
        ),
        grower: (
            <GrowerInformation
                hasProductUsageReport={hasProductUsageReport}
                isEditable={isEditable}
            />
        ),
        invoice: (
            <InvoiceAssistant isEditable={isEditable} productUsageReports={productUsageReports} />
        ),
        notes: <Notes hasProductUsageReport={hasProductUsageReport} isEditable={isEditable} />,
        site: (
            <ApplicationSite
                hasProductUsageReport={hasProductUsageReport}
                isEditable={isEditable}
            />
        )
    };

    const handleDelete = useCallback(async () => {
        if (workOrderId) {
            try {
                if (hasProductUsageReport) {
                    alert(PRODUCT_USAGE_REPORT_DELETE_WARNING);

                    return;
                }
                await deleteWorkOrder(workOrderId);
                back();
            } catch (error) {
                console.error(error);
                alert(DELETE_ERROR);
            }
        }
    }, [
        workOrderId,
        hasProductUsageReport,
        deleteWorkOrder,
        back,
        PRODUCT_USAGE_REPORT_DELETE_WARNING,
        DELETE_ERROR
    ]);

    const goBack = useCallback(() => {
        if (isEditable) {
            setShowExitModal(true);
        } else {
            back();
        }
    }, [back, setShowExitModal, isEditable]);

    const handleEdit = useCallback(() => {
        setIsEditable(true);
    }, []);

    const loadProductUsageReports = useCallback(async () => {
        if (workOrderId) {
            const productUsageReports = (await fetchProductUsageReports(workOrderId)) || [];

            if (productUsageReports.length > 0 && isEditable && !isDuplicate) {
                alert(PRODUCT_USAGE_REPORT_WARNING);
            }
            setHasProductUsageReport(productUsageReports.length > 0 && !isDuplicate);
            setProductUsageReports(productUsageReports);
        }
    }, [
        PRODUCT_USAGE_REPORT_WARNING,
        fetchProductUsageReports,
        isDuplicate,
        isEditable,
        workOrderId
    ]);

    useEffect(() => {
        loadProductUsageReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workOrderId, isEditable]);

    return (
        <PageContainer>
            <VerticalContainer className={styles.parentVerticalContainer}>
                <PageWithSidebarHeader onBack={goBack} text={pageTitle} useX={isEditable}>
                    {hasWorkOrder && (
                        <WorkOrderMenu
                            includeEdit={!isEditable}
                            includeView={false}
                            onDelete={handleDelete}
                            onDuplicate={onDuplicate}
                            onEdit={handleEdit}
                        />
                    )}
                </PageWithSidebarHeader>

                <SidebarOrDropdownLayout
                    currentSection={section}
                    onSectionChange={onSectionChange}
                    sidebarItems={sidebarItems}
                >
                    <VerticalContainer>
                        {isLoading ? (
                            <PageLoader />
                        ) : (
                            <Formik
                                enableReinitialize
                                initialValues={workOrder || emptyForm}
                                onSubmit={onFormSubmit}
                                validateOnBlur={isEditable}
                                validationSchema={isEditable && schemaMap[section]}
                            >
                                <FormNavigationButtons
                                    hasNext={handleNextButton !== undefined}
                                    isEditable={isEditable}
                                    isLoading={isSubmitting}
                                    onBack={handleBackButton}
                                    saveButtonDisabled={!canFinalSubmit}
                                    saveButtonText={SAVE_TO_SCHEDULE}
                                    setFinalSubmit={() => setIsFinalSubmit(true)}
                                >
                                    <FormContainer>{contentMap[section]}</FormContainer>
                                </FormNavigationButtons>
                            </Formik>
                        )}

                        {hasWorkOrder && !isEditable && <WorkOrderFooter workOrder={workOrder} />}
                    </VerticalContainer>
                </SidebarOrDropdownLayout>

                {showExitModal && (
                    <ExitWorkOrderModal
                        onClose={() => setShowExitModal(false)}
                        onConfirm={() => back()}
                    />
                )}
            </VerticalContainer>
        </PageContainer>
    );
};

export default WorkOrderPage;
