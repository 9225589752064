import { Button } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DeclineButton: FunctionComponent<Props> = ({
    className = '',
    isDisabled = false,
    onClick,
    testId = TEST_ID,
    text
}) => (
    <Button
        className={`${styles.declineButton} ${className}`}
        isDisabled={isDisabled}
        onClick={onClick}
        testId={testId}
        text={text}
    />
);

export default DeclineButton;
