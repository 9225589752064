import { useCallback, useEffect, useMemo, useState } from 'react';

import { InfiniteScroll, PageContainer, PageLoader } from '@rantizo-software/rantizo-ui';

import DatedList from 'components/DatedList';
import ListHeader from 'components/ListHeader';
import ListPageLayout from 'components/ListPageLayout';
import NewProductUsageReportModal from 'components/NewProductUsageReportModal';
import ProductUsageReportCard from 'components/ProductUsageReportCard';
import ProductUsageReportDeleteConfirmationModal from 'components/ProductUsageReportDeleteConfirmationModal';
import ProductUsageReportListPageLayoutHeader from 'components/ProductUsageReportListPageLayoutHeader';
import WithFeatureGate from 'components/WithFeatureGate';

import useProductUsageReportDrafts from 'hooks/useProductUsageReportDrafts';
import useProductUsageReportSummaries from 'hooks/useProductUsageReportSummaries';
import useProductUsageReports from 'hooks/useProductUsageReports';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslate';

import { DELETE_ERROR, FEATURE_PRODUCT_USAGE_REPORTS, TEST_ID } from './constants';
import type {
    DatedListItem,
    DeleteModal,
    FunctionComponent,
    Key,
    ProductUsageReportSummary,
    ProductUsageReportSummaryApiResponse,
    ProductUsageReportTab,
    Props,
    SetStateAction
} from './types';

import styles from './styles.module.scss';

const ProductUsageReportListPage: FunctionComponent<Props> = ({ newPur, testId = TEST_ID }) => {
    const {
        CONTACT,
        GROWER,
        PHONE_NUMBER,
        PRODUCT_USAGE_REPORT_ERROR,
        PRODUCT_USAGE_REPORTS,
        SITE_NAME,
        STATUS,
        TIME
    } = useTranslation();

    const { fetchProductUsageReportDraftSummaries, fetchProductUsageReportSummaries } =
        useProductUsageReportSummaries();

    const { deleteProductUsageReport } = useProductUsageReports();

    const { deleteProductUsageReportDraft } = useProductUsageReportDrafts();

    const [currentTab, setCurrentTab] = useState<ProductUsageReportTab>('reports');

    const handleTabChange = useCallback((tab: ProductUsageReportTab) => setCurrentTab(tab), []);

    const [loading, setLoading] = useState(true);

    const [showCreateModal, setShowCreateModal] = useState(false);

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<DeleteModal>({
        display: false,
        idToDelete: null
    });

    const { groupByDate } = useData();

    const [reports, setReports] = useState<ProductUsageReportSummaryApiResponse>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });

    const [draftReports, setDraftReports] = useState<ProductUsageReportSummaryApiResponse>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });

    const fetchReportData = useCallback(
        async (hasMore: boolean, nextToken: string) => {
            try {
                const response = await fetchProductUsageReportSummaries(
                    hasMore ? nextToken : undefined
                );

                const { hasNext, nextPageToken, objects: newReports } = response;

                setReports((currentState: ProductUsageReportSummaryApiResponse) => {
                    const newState = [...currentState.objects, ...newReports];

                    return {
                        hasNext,
                        nextPageToken,
                        objects: newState
                    } as ProductUsageReportSummaryApiResponse;
                });
            } catch (e) {
                console.log(e);
                alert(PRODUCT_USAGE_REPORT_ERROR);
            }
        },
        [fetchProductUsageReportSummaries, PRODUCT_USAGE_REPORT_ERROR]
    );

    const fetchDraftReportData = useCallback(
        async (hasMore: boolean, nextToken: string) => {
            try {
                const response = await fetchProductUsageReportDraftSummaries(
                    hasMore ? nextToken : undefined
                );

                const { hasNext, nextPageToken, objects: newReports } = response;

                setDraftReports((currentState: ProductUsageReportSummaryApiResponse) => {
                    const newState = [...currentState.objects, ...newReports];

                    return {
                        hasNext,
                        nextPageToken,
                        objects: newState
                    } as ProductUsageReportSummaryApiResponse;
                });
            } catch (e) {
                console.log(e);
                alert(PRODUCT_USAGE_REPORT_ERROR);
            }
        },
        [fetchProductUsageReportDraftSummaries, PRODUCT_USAGE_REPORT_ERROR]
    );

    const initialFetch = useCallback(async () => {
        await fetchReportData(false, '');
        await fetchDraftReportData(false, '');
        setLoading(false);

        if (newPur) {
            setShowCreateModal(true);
        }
    }, [fetchReportData, fetchDraftReportData, newPur]);

    const updateReports = useCallback(
        (reports: SetStateAction<ProductUsageReportSummaryApiResponse>) =>
            currentTab === 'drafts' ? setDraftReports(reports) : setReports(reports),
        [currentTab]
    );

    const handleClose = useCallback(
        () => setDeleteConfirmationModal({ display: false, idToDelete: null }),
        []
    );

    const closeNewProductUsageReportModal = useCallback(() => setShowCreateModal(false), []);

    const handleDelete = useCallback(async () => {
        const { idToDelete } = deleteConfirmationModal;

        if (idToDelete) {
            try {
                const deleteReport =
                    currentTab === 'drafts'
                        ? deleteProductUsageReportDraft
                        : deleteProductUsageReport;

                await deleteReport(idToDelete);

                updateReports((currentState: ProductUsageReportSummaryApiResponse) => ({
                    ...currentState,
                    objects: currentState.objects.filter(report => report.id !== idToDelete)
                }));
            } catch (error) {
                console.error(error);
                alert(DELETE_ERROR);
            }
        }
    }, [
        deleteConfirmationModal,
        deleteProductUsageReport,
        updateReports,
        deleteProductUsageReportDraft,
        currentTab
    ]);

    useEffect(
        () => {
            initialFetch();
        },
        // eslint-disable-next-line
        []
    );

    const productUsageReportCards = useMemo(() => {
        const draftsByDate = groupByDate(
            (currentTab === 'reports' ? reports : draftReports).objects
        );
        const productUsageReportDateItems = {} as DatedListItem;

        const updateReports = (reports: ProductUsageReportSummaryApiResponse) =>
            currentTab === 'drafts' ? setDraftReports(reports) : setReports(reports);

        Object.keys(draftsByDate).map(date => {
            const datedItems = draftsByDate[date];

            productUsageReportDateItems[date] = [
                <div className={styles.labelContainer} key={date}>
                    <ListHeader text={TIME} />

                    <ListHeader text={CONTACT} />

                    <ListHeader text={GROWER} />

                    <ListHeader text={PHONE_NUMBER} />

                    <ListHeader text={SITE_NAME} />

                    <ListHeader text={STATUS} />
                </div>
            ].concat(
                datedItems.map(
                    (report: ProductUsageReportSummary, index: Key | null | undefined) => (
                        <ProductUsageReportCard
                            isDraft={currentTab === 'drafts'}
                            key={index}
                            onDelete={setDeleteConfirmationModal}
                            summary={report}
                            updateReports={updateReports}
                        />
                    )
                )
            );
        });

        return productUsageReportDateItems;
    }, [
        groupByDate,
        currentTab,
        reports,
        draftReports,
        TIME,
        CONTACT,
        GROWER,
        PHONE_NUMBER,
        SITE_NAME,
        STATUS,
        setReports
    ]);
    const displayProductUsageReportCards = useMemo(
        () =>
            currentTab === 'reports' ? reports.objects.length > 0 : draftReports.objects.length > 0,
        [currentTab, reports, draftReports]
    );

    return (
        <PageContainer testId={testId}>
            <ListPageLayout className={styles.productUsageReportContainer}>
                <ProductUsageReportListPageLayoutHeader
                    onAddClick={() => setShowCreateModal(true)}
                    onTabChange={handleTabChange}
                    pageTitle={PRODUCT_USAGE_REPORTS}
                />

                <WithFeatureGate feature={FEATURE_PRODUCT_USAGE_REPORTS} />

                {loading && <PageLoader />}

                {showCreateModal && (
                    <NewProductUsageReportModal onClose={closeNewProductUsageReportModal} />
                )}

                {deleteConfirmationModal.display && (
                    <ProductUsageReportDeleteConfirmationModal
                        onClose={handleClose}
                        onConfirm={handleDelete}
                    />
                )}

                {displayProductUsageReportCards && (
                    <InfiniteScroll
                        fetchNextPage={async () => {
                            if (currentTab === 'reports') {
                                await fetchReportData(reports.hasNext, reports.nextPageToken);
                            } else {
                                await fetchDraftReportData(
                                    draftReports.hasNext,
                                    draftReports.nextPageToken
                                );
                            }
                        }}
                        hasNextPage={
                            currentTab === 'reports' ? reports.hasNext : draftReports.hasNext
                        }
                        className={styles.list}
                        loader={<PageLoader />}
                        nextPageToken={reports.nextPageToken}
                    >
                        <DatedList items={productUsageReportCards} />
                    </InfiniteScroll>
                )}
            </ListPageLayout>
        </PageContainer>
    );
};

export default ProductUsageReportListPage;
