import { useCallback } from 'react';

import {
    ContentText,
    DeleteButton,
    ModalOverlay,
    ModalTitle,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const DeleteConfirmationModal: FunctionComponent<Props> = ({
    buttonText = null,
    className,
    onClose,
    onConfirm,
    testId = TEST_ID,
    text,
    title
}) => {
    const { BUTTON_TEXT } = useTranslation();

    const handleDelete = useCallback(() => {
        onConfirm();
        onClose();
    }, [onClose, onConfirm]);

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer
                className={`${styles.deleteConfirmationModalContainer} ${className}`}
            >
                <ModalTitle text={title} />

                <ContentText className={styles.subText} text={text} />

                <DeleteButton onClick={handleDelete} text={buttonText || BUTTON_TEXT} />
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default DeleteConfirmationModal;
