import { forwardRef, useCallback, useEffect, useState } from 'react';

import {
    DeprecatingDropdown as Dropdown,
    DeprecatingWithErrorMessage as WithErrorMessage
} from '@rantizo-software/rantizo-ui';

import useFormError from 'hooks/useFormError';

import { DEFAULT_TIMEZONE, MINUTES_PER_HOUR, TEST_ID } from './constants';
import type { Props } from './types';

const generateTimezones = () => [
    {
        label: 'CT',
        offset: -6,
        type: '',
        value: 'America/Chicago'
    },
    {
        label: 'ET',
        offset: -5,
        type: '',
        value: 'America/New_York'
    },
    {
        label: 'MT',
        offset: -7,
        type: '',
        value: 'America/Denver'
    },
    {
        label: 'PT',
        offset: -8,
        type: '',
        value: 'America/Los_Angeles'
    }
];

const TimezoneDropdown = forwardRef<HTMLSelectElement, Props>(
    (
        {
            className = '',
            hasError = false,
            isRequired = false,
            label = '',
            onChange,
            onError,
            onSubmit,
            onValid,
            selected,
            testId = TEST_ID,
            value
        },
        ref
    ) => {
        const { clearError, errorMessage, handleError } = useFormError(onError);

        const timezones = generateTimezones();

        const getUserTimezoneValue = useCallback(() => {
            const userOffset = new Date().getTimezoneOffset() / -MINUTES_PER_HOUR;

            const timezone = timezones.find(tz => tz.offset === userOffset);

            return timezone ? timezone.value : DEFAULT_TIMEZONE;
        }, [timezones]);

        const [defaultTimezone, setDefaultTimezone] = useState<string>('');

        const handleOnChange = useCallback(
            (newValue: unknown, handleOnError: typeof onError, index: number) => {
                clearError();

                onChange?.(newValue, handleOnError, index);
            },
            [clearError, onChange]
        );

        useEffect(() => {
            const userTimezone = getUserTimezoneValue();

            setDefaultTimezone(userTimezone);

            const index = timezones.findIndex(tz => tz.value === userTimezone);

            if (index !== -1) {
                handleOnChange(userTimezone, handleError, index);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <WithErrorMessage className={className} text={errorMessage}>
                <Dropdown
                    className={className}
                    hasError={hasError}
                    isRequired={isRequired}
                    items={timezones}
                    label={label}
                    onChange={handleOnChange}
                    onError={handleError}
                    onSubmit={onSubmit}
                    onValid={onValid}
                    ref={ref}
                    selected={selected}
                    testId={testId}
                    value={value || defaultTimezone}
                />
            </WithErrorMessage>
        );
    }
);

TimezoneDropdown.displayName = 'TimezoneDropdown';

export default TimezoneDropdown;
