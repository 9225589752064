const translations = {
    acresFlown: 'Acres Flown',
    droneAerialApplication: 'Drone Aerial Application',
    droneImagery: 'Drone Imagery',
    hoursFlown: 'Hours Flown',
    insuranceLabel: 'Do you meet federal, state, and local insurance requirements?',
    part137Label: 'Do you hold a Part 137 certification for drone operations?',
    serviceDetails: 'Service Details',
    servicesOffered: 'Services Offered',
    tellUsAboutYourBuisness: 'Tell us about your business',
    yearsInService: 'Years in Service',
    yesCheckmarkLabel: 'Yes, I meet all requirements'
};

export default translations;
