import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PageContainer, PageLoader } from '@rantizo-software/rantizo-ui';

import ControllerForm from 'components/ControllerForm';

import useBack from 'hooks/useBack';
import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { ControllerFormType, FunctionComponent, Props } from './types';

const EditControllerPage: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { organizationEquipmentController } = usePageRoutes();
    const back = useBack(organizationEquipmentController);
    const { editController, fetchController } = useData();
    const { controllerId } = useParams();
    const [controller, setController] = useState<ControllerFormType>();
    const [submitting, setSubmitting] = useState(false);
    const { API_ERROR, TITLE } = useTranslation();

    const handleSubmit = useCallback(
        async (values: ControllerFormType) => {
            setSubmitting(true);

            try {
                const response = await editController(values, controllerId ?? '');

                if (response) {
                    back();
                }
            } catch (error: unknown) {
                console.log(error);
                alert(API_ERROR);
            }

            setSubmitting(false);
        },
        [API_ERROR, back, controllerId, editController]
    );

    useEffect(() => {
        const fetch = async () => {
            const data = await fetchController(controllerId ?? '');

            setController(data);
        };

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!controllerId) {
        back();
    }

    return (
        <PageContainer testId={testId}>
            {!controller && <PageLoader />}

            {controller && (
                <ControllerForm
                    initialValues={controller}
                    isExisting
                    isLoading={submitting}
                    onBack={back}
                    onSubmit={handleSubmit}
                    title={TITLE}
                />
            )}
        </PageContainer>
    );
};

export default EditControllerPage;
