export const ADDRESS_KEY = 'address';

export const ADDRESS_LINE_ONE_KEY = `${ADDRESS_KEY}.addressLine1`;

export const ADDRESS_LINE_TWO_KEY = `${ADDRESS_KEY}.addressLine2`;

export const CITY_KEY = `${ADDRESS_KEY}.city`;

export const COMPANY_KEY = 'company';

export const CONTACT_FIRST_NAME_KEY = 'contactFirstName';

export const CONTACT_LAST_NAME_KEY = 'contactLastName';

export const DEFAULT_ADDRESS_COUNTRY = 'US';

export const EMAIL_KEY = 'email';

export const PHONE_NUMBER_KEY = 'phoneNumber';

export const STATE_KEY = `${ADDRESS_KEY}.state`;

export const ZIP_CODE_KEY = `${ADDRESS_KEY}.zipCode`;
