import type { OperatorMapConsentFormSchema } from '../components/JoinServiceProviderMapDrawer/types';
import {
    AREA_FLOWN,
    CONTACT,
    DEFAULT_AREA_UNIT,
    DESCRIPTION,
    EMAIL,
    HAS_PART_137,
    HAS_PUBLIC_INFORMATION_CONSENT,
    HOURS_FLOWN,
    IS_INSURED,
    LOCATION_KEY,
    NAME,
    PHONE_NUMBER,
    PLACE_ID,
    SEARCH_INPUT_KEY,
    SERVICES,
    YEARS_OF_SERVICE
} from '../constants';
import type { ServiceResponse } from 'generatedTypes/operator-map/models';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormDeprecating as useForm } from '@rantizo-software/rantizo-ui';

import type { OperatorMapCompanyDetailsFormSchema } from 'components/form/OperatorMapCompanyDetailsForm/types';
import type { OperatorMapLocationDetailsFormSchema } from 'components/form/OperatorMapLocationDetailsForm/types';
import type { OperatorMapServiceDetailsFormSchema } from 'components/form/OperatorMapServiceDetailsForm/types';

import useOperators from 'hooks/useOperators';
import usePageRoutes from 'hooks/usePageRoutes';

const useData = (id: string | undefined) => {
    const navigate = useNavigate();
    const { providerMapViewPage } = usePageRoutes();

    const { createOperator, fetchOperatorById, loading, updateOperator } = useOperators();

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const companyDetailsFormHasErrors = useRef(false);
    const serviceDetailsFormHasErrors = useRef(false);

    const companyDetailsForm = useForm<OperatorMapCompanyDetailsFormSchema>({
        initialSchema: {
            contact: '',
            email: '',
            name: '',
            phoneNumber: ''
        },
        onFormChange(data) {
            const { hasErrors } = data;

            companyDetailsFormHasErrors.current = hasErrors;
            checkCanSubmit();
        }
    });

    const locationDetailsForm = useForm<OperatorMapLocationDetailsFormSchema>({
        initialSchema: {
            location: undefined,
            placeId: '',
            searchInput: ''
        },
        onFormChange() {
            checkCanSubmit();
        }
    });

    const operatorMapServiceDetailsForm = useForm<OperatorMapServiceDetailsFormSchema>({
        initialSchema: {
            areaFlown: 0,
            description: null,
            hasPart137: false,
            hoursFlown: 0,
            isInsured: false,
            services: [],
            yearsOfService: 0
        },
        onFormChange(data) {
            const { hasErrors } = data;

            serviceDetailsFormHasErrors.current = hasErrors;
            checkCanSubmit();
        }
    });

    const consentForm = useForm<OperatorMapConsentFormSchema>({
        initialSchema: {
            hasPublicInformationConsent: false
        },
        onFormChange() {
            checkCanSubmit();
        }
    });

    const checkCanSubmit = useCallback(() => {
        const isConsentChecked = consentForm.getValues(HAS_PUBLIC_INFORMATION_CONSENT) as boolean;
        const placeId = locationDetailsForm.getValues(PLACE_ID);

        const hasErrors =
            companyDetailsFormHasErrors.current || serviceDetailsFormHasErrors.current;

        if (isConsentChecked && !hasErrors && placeId) {
            if (isSubmitDisabled) {
                setIsSubmitDisabled(false);
            }
        } else if (!isSubmitDisabled) {
            setIsSubmitDisabled(true);
        }
    }, [consentForm, isSubmitDisabled, locationDetailsForm]);

    const fetchOperator = useCallback(async () => {
        if (id) {
            try {
                const response = await fetchOperatorById(id);

                if (response.address) {
                    const { address1, address2, city, location, state, zipCode } = response.address;

                    locationDetailsForm.handleChange(
                        SEARCH_INPUT_KEY,
                        `${address1 ?? ''} ${address2 ?? ''} ${city ?? ''} ${state ?? ''} ${zipCode ?? ''}`
                    );
                    locationDetailsForm.handleValid(SEARCH_INPUT_KEY);
                    locationDetailsForm.handleChange(LOCATION_KEY, location);
                    locationDetailsForm.handleValid(LOCATION_KEY);
                    locationDetailsForm.handleChange(PLACE_ID, response.address.locationId);
                }

                if (response.contact) {
                    companyDetailsForm.handleChange(CONTACT, response.contact);
                    companyDetailsForm.handleValid(CONTACT);
                }

                if (response.email) {
                    companyDetailsForm.handleChange(EMAIL, response.email);
                    companyDetailsForm.handleValid(EMAIL);
                }

                if (response.name) {
                    companyDetailsForm.handleChange(NAME, response.name);
                    companyDetailsForm.handleValid(NAME);
                }

                if (response.phoneNumber) {
                    companyDetailsForm.handleChange(PHONE_NUMBER, response.phoneNumber);
                    companyDetailsForm.handleValid(PHONE_NUMBER);
                }

                if (response.areaFlown) {
                    operatorMapServiceDetailsForm.handleChange(
                        AREA_FLOWN,
                        response.areaFlown.value
                    );
                    operatorMapServiceDetailsForm.handleValid(AREA_FLOWN);
                }

                if (response.description) {
                    operatorMapServiceDetailsForm.handleChange(DESCRIPTION, response.description);
                    operatorMapServiceDetailsForm.handleValid(DESCRIPTION);
                }

                if (response.hasPart137) {
                    operatorMapServiceDetailsForm.handleChange(HAS_PART_137, response.hasPart137);
                    operatorMapServiceDetailsForm.handleValid(HAS_PART_137);
                }

                if (response.hoursFlown) {
                    operatorMapServiceDetailsForm.handleChange(HOURS_FLOWN, response.hoursFlown);
                    operatorMapServiceDetailsForm.handleValid(HOURS_FLOWN);
                }

                if (response.isInsured) {
                    operatorMapServiceDetailsForm.handleChange(IS_INSURED, response.isInsured);
                    operatorMapServiceDetailsForm.handleValid(IS_INSURED);
                }

                if (response.services) {
                    operatorMapServiceDetailsForm.handleChange(
                        SERVICES,
                        response.services.map((service: ServiceResponse) => service.id)
                    );
                    operatorMapServiceDetailsForm.handleValid(SERVICES);
                }

                if (response.yearsOfService) {
                    operatorMapServiceDetailsForm.handleChange(
                        YEARS_OF_SERVICE,
                        response.yearsOfService
                    );
                    operatorMapServiceDetailsForm.handleValid(YEARS_OF_SERVICE);
                }

                if (response.hasPublicInformationConsent) {
                    consentForm.handleChange(
                        HAS_PUBLIC_INFORMATION_CONSENT,
                        response.hasPublicInformationConsent
                    );
                    consentForm.handleValid(HAS_PUBLIC_INFORMATION_CONSENT);
                }
            } catch (error) {
                console.error('Error fetching operator:', error);
            }
        }
    }, [
        companyDetailsForm,
        consentForm,
        fetchOperatorById,
        id,
        locationDetailsForm,
        operatorMapServiceDetailsForm
    ]);

    useEffect(() => {
        fetchOperator();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createPayload = useCallback(() => {
        const {
            areaFlown,
            description,
            hasPart137,
            hoursFlown,
            isInsured,
            services,
            yearsOfService
        } = operatorMapServiceDetailsForm.getValues() as OperatorMapServiceDetailsFormSchema;

        const { contact, email, name, phoneNumber } =
            companyDetailsForm.getValues() as OperatorMapCompanyDetailsFormSchema;

        const { hasPublicInformationConsent } =
            consentForm.getValues() as OperatorMapConsentFormSchema;

        const { placeId } = locationDetailsForm.getValues() as OperatorMapLocationDetailsFormSchema;

        return {
            address: {
                locationId: placeId
            },
            areaFlown: {
                unit: DEFAULT_AREA_UNIT,
                value: areaFlown
            },
            contact: contact,
            description: description,
            email: email,
            hasPart137: hasPart137,
            hasPublicInformationConsent: hasPublicInformationConsent,
            hoursFlown: hoursFlown,
            isInsured: isInsured,
            name: name,
            phoneNumber: phoneNumber,
            services: services,
            yearsOfService: yearsOfService
        };
    }, [companyDetailsForm, consentForm, locationDetailsForm, operatorMapServiceDetailsForm]);

    const handleSubmit = useCallback(async () => {
        try {
            const payload = createPayload();

            const response = id ? await updateOperator(id, payload) : await createOperator(payload);

            navigate(providerMapViewPage(response.id));
        } catch (error) {
            alert('Error Submitting Form Please Try Again Later');
            console.error(error);
        }
    }, [createOperator, createPayload, id, navigate, providerMapViewPage, updateOperator]);

    return {
        companyDetailsForm,
        consentForm,
        handleSubmit,
        isSubmitDisabled,
        loading,
        locationDetailsForm,
        operatorMapServiceDetailsForm
    };
};

export default useData;
