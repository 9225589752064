import { type FunctionComponent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    HorizontalContainer,
    Map,
    PageContainer,
    WithDrawerContainer
} from '@rantizo-software/rantizo-ui';

import OperatorMapPoint from 'components/OperatorMapPoint';

import usePageRoutes from 'hooks/usePageRoutes';

import useData from './hooks/useData';

import OperatorTileHomePageDrawer from './OperatorTileHomePageDrawer';
import { TEST_ID } from './constants';
import type { Props } from './types';

import styles from './styles.module.scss';

const OperatorTileHomePage: FunctionComponent<Props> = ({ operators, testId = TEST_ID }) => {
    const { handleBack, handleOnCollapse, handleOnExpand, handleToggle, isOpen, loading } =
        useData();

    const navigate = useNavigate();
    const { providerMapViewPage } = usePageRoutes();

    const firstOperator = operators[0];

    const mapCenter = useMemo(() => {
        if (firstOperator?.address?.location?.coordinates) {
            return {
                lat: Number(firstOperator.address.location.coordinates[1]),
                lng: Number(firstOperator.address.location.coordinates[0])
            };
        }
    }, [firstOperator]);

    const handlePointClick = useCallback(
        (id: string) => {
            navigate(providerMapViewPage(id));
        },
        [navigate, providerMapViewPage]
    );

    const handleAddLocation = useCallback(() => {
        navigate('/provider-map', { state: { source: 'add-location' } });
    }, [navigate]);

    return (
        <PageContainer testId={testId}>
            <WithDrawerContainer isOpen={isOpen}>
                <HorizontalContainer className={styles.horizontalContainer}>
                    <Map
                        center={mapCenter}
                        key={mapCenter?.lng}
                        mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
                    >
                        {operators?.map(operator => (
                            <OperatorMapPoint
                                key={operator.id}
                                onClick={() => handlePointClick(operator.id)}
                                operator={operator}
                            />
                        ))}
                    </Map>
                </HorizontalContainer>

                {operators && (
                    <OperatorTileHomePageDrawer
                        handlePointClick={handlePointClick}
                        handleToggle={handleToggle}
                        isOpen={isOpen}
                        loadingOperators={loading}
                        onAddLocation={handleAddLocation}
                        onBack={handleBack}
                        onCollapse={handleOnCollapse}
                        onExpand={handleOnExpand}
                        operators={operators}
                    />
                )}
            </WithDrawerContainer>
        </PageContainer>
    );
};

export default OperatorTileHomePage;
