import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Drawer,
    HeadingText,
    HorizontalContainer,
    PrimaryButton,
    SectionContainer,
    VerticalSpaceBetween
} from '@rantizo-software/rantizo-ui';

import AddFarmButton from 'components/iconTextButtons/AddFarmButton';
import AddFieldButton from 'components/iconTextButtons/AddFieldButton';
import AddGrowerButton from 'components/iconTextButtons/AddGrowerButton';

import usePageRoutes from 'hooks/usePageRoutes';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CreateCustomerDrawer: FunctionComponent<Props> = ({ testId = TEST_ID }) => {
    const { BACK, CREATE } = useTranslation();

    const navigate = useNavigate();

    const { customersPage } = usePageRoutes();

    const addFarm = () => {
        // TODO: add logic
    };

    const addField = () => {
        // TODO: add logic
    };

    const addGrower = () => {
        // TODO: add logic
    };

    const handleBack = useCallback(() => {
        navigate(customersPage);
    }, [customersPage, navigate]);

    return (
        <Drawer allowCollapse={false} className={styles.drawer} isOpen={true} testId={testId}>
            <VerticalSpaceBetween>
                <SectionContainer>
                    <HeadingText className={styles.headingText} text={CREATE} />

                    <HorizontalContainer>
                        <AddFarmButton onClick={addFarm} />

                        <AddFieldButton onClick={addField} />

                        <AddGrowerButton onClick={addGrower} />
                    </HorizontalContainer>
                </SectionContainer>

                <SectionContainer className={styles.sectionContainer}>
                    <PrimaryButton onClick={handleBack} text={BACK} />
                </SectionContainer>
            </VerticalSpaceBetween>
        </Drawer>
    );
};

export default CreateCustomerDrawer;
