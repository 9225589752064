import { useCallback } from 'react';

import useAccessToken from 'hooks/useAccessToken';
import useToast from 'hooks/useToast';

import type { HttpRequestOptions, UseFetchParams } from './types';

const API_HOST = import.meta.env.VITE_RANTIZO_API_BASE_URL;

const useFetch = (params: UseFetchParams | undefined = undefined) => {
    const { apiHost, headers, retrieveToken } = params ?? {
        apiHost: API_HOST,
        headers: { 'Content-Type': 'application/json' },
        retrieveToken: undefined
    };

    const { fetchToken } = useAccessToken();

    const { broadcastToast } = useToast();

    const fetchAccessToken: () => Promise<string | undefined> = retrieveToken ?? fetchToken;

    const handleLoadingAndErrors = async <T = unknown>({
        callback,
        setIsLoading
    }: {
        callback: () => Promise<T | null | void>;
        setIsLoading: (loading: boolean) => void;
    }) => {
        setIsLoading(true);

        try {
            const response = await callback();

            return response as T;
        } catch (error) {
            broadcastToast({
                text: JSON.stringify(error),
                type: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const resolveHost = useCallback(
        (url: string) => (url.startsWith('/') ? `${apiHost}${url}` : url),
        [apiHost]
    );

    const httpRequest = useCallback(
        async <T, D = undefined>(
            url: string,
            requestData?: D | Uint8Array,
            options: HttpRequestOptions = {},
            isBinary: boolean = false
        ) => {
            const requestOptions: RequestInit = {
                body: requestData as BodyInit,
                cache: 'no-cache',
                redirect: 'follow',
                ...options,
                headers: new Headers({
                    ...headers,
                    ...options.headers
                })
            };

            const endpoint = resolveHost(url);
            let data: T | null = null;
            let error: string | null = null;

            try {
                const response = await fetch(endpoint, requestOptions);

                if (!response.ok) {
                    throw new Error(`HTTP Error: ${response.status}`);
                }

                if (isBinary) {
                    const body = await response.arrayBuffer();

                    data = body ? (new Uint8Array(body) as T) : null;
                } else {
                    const text = await response.text();

                    if (text) {
                        data = JSON.parse(text) as T;

                        const locationHeader = response.headers.get('location');

                        if (locationHeader && typeof data === 'object' && data !== null) {
                            (data as Record<string, unknown>).location = locationHeader;
                        }
                    }
                }
            } catch (e) {
                error = e instanceof Error ? e.message : 'Unknown error';
            }

            return { data, error };
        },
        [resolveHost, headers]
    );

    const httpGet = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data?: D, options = {}) => {
            const requestOptions = {
                ...options,
                method: 'GET'
            };

            return await httpRequest<T, D>(url, data, requestOptions);
        },
        [httpRequest]
    );

    const httpDelete = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data?: D, options = {}) => {
            const requestOptions = {
                ...options,
                method: 'DELETE'
            };

            return await httpRequest<T, D>(url, data, requestOptions);
        },
        [httpRequest]
    );

    const httpPost = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: D, options = {}, isBinary = false) => {
            const requestOptions = {
                headers: headers,

                ...options,
                method: 'POST'
            };

            return await httpRequest<T, D>(url, data, requestOptions, isBinary);
        },
        [headers, httpRequest]
    );

    const httpPut = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: D, options = {}) => {
            const requestOptions = {
                headers: headers,
                ...options,
                method: 'PUT'
            };

            return await httpRequest<T, D>(url, data, requestOptions);
        },
        [headers, httpRequest]
    );

    const httpPutBinary = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: Uint8Array, options = {}) => {
            const requestOptions = {
                ...options,
                method: 'PUT'
            };

            return await httpRequest<T, D>(url, data, requestOptions);
        },
        [httpRequest]
    );

    const httpGetBinary = useCallback(async (url = '') => {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        return uint8Array;
    }, []);

    const authenticatedRequest = useCallback(
        async <T, D>(
            httpMethod: typeof httpRequest,
            url = '',
            data: D,
            options = {},
            isBinary = false
        ) => {
            const token = await fetchAccessToken();

            if (!token) {
                return {
                    data: null,
                    error: 'User not authenticated'
                };
            }

            const response = await httpMethod<T, D>(
                url,
                data,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    ...options
                },
                isBinary
            );

            if (response?.error) {
                return {
                    data: null,
                    error: response.error
                };
            }

            return response;
        },
        [fetchAccessToken]
    );

    const authenticatedGet = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: any = {}, options = {}) => {
            data = undefined;

            return await authenticatedRequest<T, D>(httpGet, url, data, options);
        },
        [authenticatedRequest, httpGet]
    );

    const authenticatedDelete = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: any = {}, options = {}) => {
            data = undefined;

            return await authenticatedRequest<T, D>(httpDelete, url, data, options);
        },
        [authenticatedRequest, httpDelete]
    );

    const authenticatedPost = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: D, options = {}) =>
            await authenticatedRequest<T, D>(httpPost, url, data, options),
        [authenticatedRequest, httpPost]
    );

    const authenticatedPut = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: D, options = {}) =>
            await authenticatedRequest<T, D>(httpPut, url, data, options),
        [authenticatedRequest, httpPut]
    );

    const authenticatedBinaryPost = useCallback(
        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
        async <T = any, D = any>(url = '', data: D, options = {}) =>
            await authenticatedRequest<T, D>(httpPost, url, data, options, true),
        [authenticatedRequest, httpPost]
    );

    return {
        authenticatedBinaryPost,
        authenticatedDelete,
        authenticatedGet,
        authenticatedPost,
        authenticatedPut,
        handleLoadingAndErrors,
        httpGet,
        httpGetBinary,
        httpPost,
        httpPut,
        httpPutBinary,
        resolveHost
    };
};

export default useFetch;
