import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('addFieldButton');

    return {
        ADD_FIELD: t('addField')
    };
};

export default useTranslation;
