import { useEffect, useState } from 'react';

import { useGrowthBook } from '@growthbook/growthbook-react';

import { INITIAL_STATE } from './constants';

const useFeatureFlags = () => {
    const growthbook = useGrowthBook();
    const [features, setFeatures] = useState(INITIAL_STATE);
    const rawFeatures = growthbook?.getFeatures();

    useEffect(() => {
        if (rawFeatures && growthbook?.ready) {
            const mappedFeatures: Record<string, boolean> = {};

            for (const key of Object.keys(rawFeatures)) {
                mappedFeatures[key] = growthbook.evalFeature(key).on;
            }

            setFeatures(features => ({ ...features, ...mappedFeatures }));
        }
    }, [rawFeatures, growthbook]);

    return {
        CUSTOMERS: features.customers,
        FAA_REPORTS: features.faa_reports,
        FIELD_VIEW: features.field_view,
        FLIGHT_LOG_LIST: features.flight_log_list,
        INTERCOM_CHAT: features.intercom_chat,
        JOHN_DEERE: features.john_deere,
        MANAGE_CONNECTIONS: features.manage_connections,
        OPERATOR_MAP_TILE: features.operator_map_tile,
        PDF_EXTRACTION: features.pdf_extraction,
        PRODUCT_USAGE_REPORT_DELETE: features.product_usage_report_delete,
        PRODUCT_USAGE_REPORT_EDIT: features.product_usage_report_edit
    };
};

export default useFeatureFlags;
