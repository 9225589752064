export const AAM_PAGE = '/aam';

export const ACCOUNT_DETAILS = '/account/details';

export const ACCOUNT_LOGIN_PAGE = '/account/login';

export const ACCOUNT_LOGOUT_PAGE = '/account/logout';

export const ACCOUNT_PAGE = '/account';

export const ACCOUNT_SETTINGS = '/account/settings';

export const ACM_PAGE = '/acm';

export const CUSTOMERS_PAGE = '/customers';

export const CREATE_CUSTOMER_PAGE = '/create-customer';

export const CREATE_FAA_REPORT_PAGE = '/faa-reports/create';

export const CREATE_ORGANIZATAION_PAGE = '/verify/organization/new';

export const DRONE_FLEET_PAGE = '/drone-fleet';

export const EDIT_AIRCRAFT_PAGE = '/organization/edit/aircraft';

export const EDIT_CERTIFICATIONS_PAGE = '/organization/edit/certifications';

export const EDIT_COMPANY_DETAILS_PAGE = '/organization/edit/company';

export const EDIT_CONTROLLER_PAGE = '/organization/edit/controller';

export const EDIT_PERSON_OF_CONTACT_PAGE = '/organization/edit/contact';

export const EDIT_USER_DETAILS_PAGE = '/account/edit/user';

export const EQUIPMENT_PAGE = '/organization/edit/equipment';

export const FAA_REPORTS_PAGE = '/faa-reports';

export const FLIGHT_LOG_PAGE = '/flight-logs';

export const FLY_FOR_RANTIZO_PAGE = '/fly-for-rantizo';

export const HOME_PAGE = '/';

export const MANAGE_CONNECTIONS_PAGE = '/connections';

export const MANAGE_ORGANIZATION_PAGE = '/organization';

export const MAPS_PAGE = '/maps';

export const MAP_GENERATION_PAGE = '/generate';

export const OPERATOR_MAP_PAGE = '/provider-map';

export const ORGANIZATION_DETAILS = '/organization/details';

export const ORGANIZATION_EQUIPMENT = '/organization/equipment';

export const ORGANIZATION_EQUIPMENT_AIRCRAFT = '/organization/equipment/aircraft';

export const ORGANIZATION_EQUIPMENT_CONTROLLER = '/organization/equipment/controller';

export const ORGANIZATION_NEW_AIRCRAFT = '/organization/new/aircraft';

export const ORGANIZATION_NEW_CONTROLLER = '/organization/new/controller';

export const ORGANIZATION_SUBSCRIPTION = '/organization/subscription';

export const ORGANIZATION_TEAM_DETAILS = '/organization/team';

export const ORGANIZATION_TEAM_DETAILS_ACTIVE = '/organization/team/active';

export const ORGANIZATION_TEAM_DETAILS_DISABLED = '/organization/team/disabled';

export const ORGANIZATION_TEAM_DETAILS_INVITED = '/organization/team/invited';

export const PRODUCT_USAGE_REPORT_PAGE = '/product-usage-report';

export const PROVIDER_MAP = '/provider-map';

export const RANTIZO_SHOP_PAGE = '/rantizo-shop';

export const TERMS_OF_SERVICE_PAGE = '/terms-of-service';

export const UPLOAD_FLIGHT_LOG = '/upload-flight-log';

export const VERIFY_ACCOUNT_PAGE = '/verify/account';

export const VERIFY_EMAIL_PAGE = '/verify/email';

export const VERIFY_ORGANIZATION_PAGE = '/verify/organization';

export const VIEW_FAA_REPORT_PAGE = '/faa-reports/view';

export const WORK_ORDER_PAGE = '/work-order';
