import type { ChangeEvent } from 'react';

import {
    Checkbox,
    ContentText,
    FormSectionContainer,
    HorizontalContainer,
    PageTitle,
    DeprecatingPhoneNumberField as PhoneNumberField
} from '@rantizo-software/rantizo-ui';

import WithLabel from 'components/WithLabel';
import CompanyNameField from 'components/form/FormFields/CompanyNameField';
import EmailField from 'components/form/FormFields/EmailField';
import TextField from 'components/form/FormFields/TextField';

import useTranslation from './hooks/useTranslation';

import { CONTACT_KEY, EMAIL_KEY, NAME_KEY, PHONE_KEY, TEST_ID } from './constants';
import type { FunctionComponent, OperatorMapCompanyDetailsFormSchema, Props } from './types';

import styles from './styles.module.scss';

const OperatorMapCompanyDetailsForm: FunctionComponent<Props> = ({
    form,
    handleCheckboxChange,
    isChecked,
    isDisabled = false,
    isEditable = true,
    resetKey,
    testId = TEST_ID
}) => {
    const { COMPANY_DETAILS, COMPANY_NAME, CONTACT, EMAIL, PHONE_NUMBER, SAME_AS_ORGANIZATION } =
        useTranslation();

    return (
        <FormSectionContainer className={styles.formContainer} key={resetKey} testId={testId}>
            <PageTitle className={styles.title} text={COMPANY_DETAILS} />

            <WithLabel text={COMPANY_NAME}>
                <CompanyNameField<OperatorMapCompanyDetailsFormSchema>
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    isRequired
                    name={NAME_KEY}
                />
            </WithLabel>

            <WithLabel text={CONTACT}>
                <TextField<OperatorMapCompanyDetailsFormSchema>
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    name={CONTACT_KEY}
                />
            </WithLabel>

            <WithLabel text={PHONE_NUMBER}>
                <PhoneNumberField
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    name={PHONE_KEY}
                />
            </WithLabel>

            <WithLabel text={EMAIL}>
                <EmailField<OperatorMapCompanyDetailsFormSchema>
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    name={EMAIL_KEY}
                />
            </WithLabel>

            <HorizontalContainer>
                <Checkbox
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleCheckboxChange(event.target.checked)
                    }
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                />

                <ContentText text={SAME_AS_ORGANIZATION} />
            </HorizontalContainer>
        </FormSectionContainer>
    );
};

export default OperatorMapCompanyDetailsForm;
