import { type ChangeEvent, useState } from 'react';

import {
    Checkbox,
    ContentText,
    FormSectionContainer,
    HorizontalContainer,
    MultiSelectDropdownField,
    PageTitle,
    TextAreaWithCountField
} from '@rantizo-software/rantizo-ui';

import WithLabel from 'components/WithLabel';
import AcresFlownDropdownField from 'components/form/FormFields/AcresFlownDropdownField';
import HoursFlownDropdownField from 'components/form/FormFields/HoursFlownDropdownField';
import YearsOfServiceDropdownField from 'components/form/FormFields/YearsOfServiceDropdownField';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import {
    AREA_FLOWN_KEY,
    DESCRIPTION_KEY,
    HAS_PART_137_KEY,
    HOURS_FLOWN_KEY,
    IS_INSURED_KEY,
    SERVICES_KEY,
    TEST_ID,
    YEARS_OF_SERVICE_KEY
} from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OperatorMapServiceDetailsForm: FunctionComponent<Props> = ({
    form,
    isDisabled = false,
    isEditable = true,
    testId = TEST_ID
}) => {
    const {
        ACRES_FLOWN,
        HOURS_FLOWN,
        INSURANCE_LABEL,
        PART_137_LABEL,
        SERVICE_DETAILS,
        SERVICES_OFFERED,
        TELL_US_ABOUT_YOUR_BUISNESS,
        YEARS_IN_SERVICE,
        YES_CHECKMARK_LABEL
    } = useTranslation();

    const [hasPart137Checked, setHasPart137Checked] = useState(
        (form.getValues(HAS_PART_137_KEY) as boolean | undefined) ?? false
    );
    const [isInsuredChecked, setIsInsuredChecked] = useState(
        (form.getValues(IS_INSURED_KEY) as boolean | undefined) ?? false
    );

    const { serviceItems } = useData();

    return (
        <FormSectionContainer className={styles.formContainer} testId={testId}>
            <PageTitle text={SERVICE_DETAILS} />

            <WithLabel text={TELL_US_ABOUT_YOUR_BUISNESS}>
                <TextAreaWithCountField
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    name={DESCRIPTION_KEY}
                />
            </WithLabel>

            <HorizontalContainer>
                <WithLabel text={YEARS_IN_SERVICE}>
                    <YearsOfServiceDropdownField
                        form={form}
                        isDisabled={isDisabled}
                        isEditable={isEditable}
                        name={YEARS_OF_SERVICE_KEY}
                    />
                </WithLabel>

                <WithLabel text={HOURS_FLOWN}>
                    <HoursFlownDropdownField
                        form={form}
                        isDisabled={isDisabled}
                        isEditable={isEditable}
                        name={HOURS_FLOWN_KEY}
                    />
                </WithLabel>

                <WithLabel text={ACRES_FLOWN}>
                    <AcresFlownDropdownField
                        form={form}
                        isDisabled={isDisabled}
                        isEditable={isEditable}
                        name={AREA_FLOWN_KEY}
                    />
                </WithLabel>
            </HorizontalContainer>

            <WithLabel text={SERVICES_OFFERED}>
                <MultiSelectDropdownField
                    form={form}
                    isDisabled={isDisabled}
                    isEditable={isEditable}
                    items={serviceItems}
                    name={SERVICES_KEY}
                />
            </WithLabel>

            <WithLabel text={INSURANCE_LABEL}>
                <HorizontalContainer>
                    <Checkbox
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.handleChange(IS_INSURED_KEY, event.target.checked ?? false);
                            setIsInsuredChecked(event.target.checked);
                        }}
                        isChecked={isInsuredChecked}
                        isDisabled={isDisabled}
                    />

                    <ContentText text={YES_CHECKMARK_LABEL} />
                </HorizontalContainer>
            </WithLabel>

            <WithLabel text={PART_137_LABEL}>
                <HorizontalContainer>
                    <Checkbox
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.handleChange(HAS_PART_137_KEY, event.target.checked ?? false);
                            setHasPart137Checked(event.target.checked);
                        }}
                        isChecked={hasPart137Checked}
                        isDisabled={isDisabled}
                    />

                    <ContentText text={YES_CHECKMARK_LABEL} />
                </HorizontalContainer>
            </WithLabel>
        </FormSectionContainer>
    );
};

export default OperatorMapServiceDetailsForm;
