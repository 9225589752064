import { INPUT_CHARS_MAX } from 'config';
import { Field } from 'formik';
import { acreageUnitOptions, targetSprayRateUnitOptions } from 'types/workOrders';

import { rem } from '@mantine/core';
import { FormSectionContainer, SplitContainer } from '@rantizo-software/rantizo-ui';

import UnitLabel from 'components/UnitLabel';
import FormInput from 'components/deprecating/FormInput';
import FormNumberInput from 'components/deprecating/FormNumberInput';
import FormSelect from 'components/deprecating/FormSelect';
import FormMinimalSelect from 'components/form/FormMinimalSelect';

import useTranslation from './hooks/useTranslation';

import { DAYS_LIST, HOURS_LIST, TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SiteCommodity: FunctionComponent<Props> = ({
    hasProductUsageReport = false,
    isEditable,
    testId = TEST_ID
}) => {
    const { LABELS, UNITS } = useTranslation();

    const unitClass = isEditable ? styles.unitDropdown : styles.unitDisplay;
    const timeClass = isEditable ? '' : styles.timeDisplay;

    return (
        <FormSectionContainer testId={testId}>
            <Field
                component={FormInput}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                label={LABELS.COMMODITY}
                max={INPUT_CHARS_MAX}
                name="commodity"
            />

            <SplitContainer>
                <Field
                    rightSection={
                        <Field
                            className={unitClass}
                            component={FormMinimalSelect}
                            data={acreageUnitOptions}
                            disabled={hasProductUsageReport}
                            isEditable={isEditable}
                            name="proposedAcresUnit"
                            rightSection={<></>}
                            rightSectionWidth={'0px'}
                        />
                    }
                    component={FormNumberInput}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.PROPOSED_ACRES}
                    name="proposedAcres"
                    rightSectionWidth={rem('70px')}
                />

                <Field
                    rightSection={
                        <Field
                            className={unitClass}
                            component={FormMinimalSelect}
                            data={targetSprayRateUnitOptions}
                            disabled={hasProductUsageReport}
                            isEditable={isEditable}
                            name="targetSprayRateUnit"
                            rightSection={<></>}
                            rightSectionWidth={'0px'}
                        />
                    }
                    component={FormNumberInput}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.TARGET_SPRAY_RATE}
                    name="targetSprayRate"
                    rightSectionWidth={rem('70px')}
                />
            </SplitContainer>

            <SplitContainer>
                <Field
                    className={timeClass}
                    component={FormSelect}
                    data={DAYS_LIST}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.RE_ENTRY_INTERVAL_DAYS}
                    name="reEntryIntervalDays"
                    rightSection={<UnitLabel label={UNITS.DAYS} />}
                    rightSectionWidth={rem('40px')}
                />

                <Field
                    className={timeClass}
                    component={FormSelect}
                    data={HOURS_LIST}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label=" "
                    name="reEntryIntervalHours"
                    rightSection={<UnitLabel label={UNITS.HOURS} />}
                    rightSectionWidth={rem('50px')}
                />
            </SplitContainer>

            <SplitContainer>
                <Field
                    className={timeClass}
                    component={FormSelect}
                    data={DAYS_LIST}
                    disabled={hasProductUsageReport}
                    isEditable={isEditable}
                    label={LABELS.PRE_HARVEST_INTERVAL}
                    name="preHarvestInterval"
                    rightSection={<UnitLabel label={UNITS.DAYS} />}
                />
            </SplitContainer>
        </FormSectionContainer>
    );
};

export default SiteCommodity;
