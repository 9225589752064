import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('operatorMapLocationDetailsForm');

    return {
        BUTTON_TEXT: t('buttonText'),
        MAP_PIN_INFO_WINDOW_BODY_TEXT: t('mapPinInfoWindowBodyText')
    };
};

export default useTranslation;
