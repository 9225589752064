import { PageContainer, VerticalContainer } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const ProductUsageReportContentContainer: FunctionComponent<Props> = ({
    children,
    testId = TEST_ID
}) => (
    <PageContainer testId={testId}>
        <VerticalContainer className={styles.productUsageReportContentContainer}>
            {children}
        </VerticalContainer>
    </PageContainer>
);

export default ProductUsageReportContentContainer;
