import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('customersDrawer');

    return {
        CUSTOMERS: t('customers'),
        IMPORT: t('import'),
        NEW_CUSTOMER: t('newCustomer'),
        NO_RESULTS: t('noResults')
    };
};

export default useTranslation;
