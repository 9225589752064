import { INPUT_CHARS_MAX } from 'config';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GALLONS_PER_ACRE } from 'types/units.ts';

import { CloseButton, FormSectionContainer } from '@rantizo-software/rantizo-ui';

import RowSpaceBetween from 'components/RowSpaceBetween';
import FormInput from 'components/deprecating/FormInput';
import FormNumberInput from 'components/deprecating/FormNumberInput';
import FormSelect from 'components/deprecating/FormSelect';
import FormTextArea from 'components/deprecating/FormTextArea';
import FormFieldWithWiderUnit from 'components/form/FormFieldWithWiderUnit';

import useChemical from 'hooks/useChemical';

import useTranslation from './hooks/useTranslation';

import { TEST_ID, signalWordValues, sprayRateUnitOptions } from './constants';
import type {
    ChemicalProduct,
    ChemicalSearchResult,
    FunctionComponent,
    Props,
    SelectData,
    WorkOrderForm
} from './types';

import styles from './styles.module.scss';

const ChemicalInformationFields: FunctionComponent<Props> = ({
    canClose = false,
    hasProductUsageReport = false,
    index,
    isEditable = true,
    onClose,
    testId = TEST_ID
}) => {
    const { setFieldValue, values } = useFormikContext<WorkOrderForm>();
    const { fetchChemicalProduct, fetchChemicalProducts } = useChemical();
    const [chemicalProducts, setChemicalProducts] = useState<ChemicalSearchResult[]>([]);
    const [pestData, setPestData] = useState<string[]>([]);

    const isSearching = useRef<boolean>(false);
    const previousSearchTerm = useRef<string>('');

    const abortController = useMemo(() => new AbortController(), []);

    const { ACTIVE_INGREDIENTS, CHEMICAL_TO_APPLY, EPA_NUMBER, PEST_CONDITION, RATE, SIGNAL_WORD } =
        useTranslation();

    const setChemicals = useCallback(
        async (searchTerm: string) => {
            try {
                const products = await fetchChemicalProducts(searchTerm, abortController.signal);

                setChemicalProducts(products);
                isSearching.current = false;
            } catch (exception) {
                console.error(exception);
                alert('Unable to search for chemicals, please try again later');
            }
        },
        [fetchChemicalProducts, abortController.signal]
    );

    const onSearchChange = useCallback(
        (searchTerm: string) => {
            if (searchTerm.length >= 3 && searchTerm !== previousSearchTerm.current) {
                if (isSearching) {
                    abortController.abort();
                }
                isSearching.current = true;
                previousSearchTerm.current = searchTerm;
                setChemicals(searchTerm);
            }
        },
        [previousSearchTerm, isSearching, setChemicals, abortController]
    );

    const labelNames = useMemo(
        () =>
            chemicalProducts.reduce((acc, chemical) => {
                acc.push({
                    label: chemical.labelName,
                    value: chemical.id
                });

                return acc;
            }, [] as SelectData[]),
        [chemicalProducts]
    );

    const addPests = useCallback(
        (product: ChemicalProduct) => {
            const pests = product.pests.reduce((acc, pest) => {
                acc.push(pest.name);

                return acc;
            }, [] as string[]);

            setPestData(pests);
        },
        [setPestData]
    );

    const onChemicalSelected = useCallback(
        async (value: string) => {
            const products = chemicalProducts.filter(f => f.id == value);

            if (products.length > 0) {
                const productListing = products[0];
                const product = (await fetchChemicalProduct(productListing.id)) as ChemicalProduct;

                const ingredients = product.ingredients.reduce((acc, ingredient) => {
                    acc.push(ingredient.name);

                    return acc;
                }, [] as string[]);

                await setFieldValue(`chemicals.${index}.chemicalProduct`, product);
                await setFieldValue(`chemicals.${index}.epaNumber`, product.epaNumber);
                await setFieldValue(`chemicals.${index}.activeIngredients`, ingredients);
                addPests(product);
            } else {
                await setFieldValue(`chemicals.${index}.epaNumber`, '');
                await setFieldValue(`chemicals.${index}.activeIngredients`, []);
                setPestData([]);
            }
        },
        [addPests, chemicalProducts, fetchChemicalProduct, index, setFieldValue]
    );

    useEffect(
        () => {
            if (values.chemicals.length - 1 >= index) {
                if (values.chemicals[index].chemicalProduct) {
                    const { chemicalProduct } = values.chemicals[index];

                    setChemicalProducts([chemicalProduct]);
                    addPests(chemicalProduct);
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <FormSectionContainer className={styles.formContainer} testId={testId}>
            <Field
                label={
                    canClose ? (
                        <RowSpaceBetween>
                            {CHEMICAL_TO_APPLY}

                            {isEditable && (
                                <CloseButton className={styles.closeButton} onClick={onClose} />
                            )}
                        </RowSpaceBetween>
                    ) : (
                        CHEMICAL_TO_APPLY
                    )
                }
                className={styles.labelName}
                component={FormSelect}
                data={labelNames}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                max={INPUT_CHARS_MAX}
                name={`chemicals.${index}.chemicalId`}
                onChange={onChemicalSelected}
                onSearchChange={onSearchChange}
                searchable
            />

            <Field
                component={FormInput}
                disabled
                isEditable={isEditable}
                label={EPA_NUMBER}
                max={INPUT_CHARS_MAX}
                name={`chemicals.${index}.epaNumber`}
            />

            <Field
                component={FormSelect}
                data={signalWordValues}
                disabled={hasProductUsageReport}
                isEditable={isEditable}
                label={SIGNAL_WORD}
                max={INPUT_CHARS_MAX}
                name={`chemicals.${index}.signalWord`}
                searchable
            />

            <Field
                className={styles.activeIngredientTextArea}
                component={FormTextArea}
                disabled
                isEditable={isEditable}
                key={'activeIngredients.' + index}
                label={ACTIVE_INGREDIENTS}
                name={`chemicals.${index}.activeIngredients`}
                value={values.chemicals[index].activeIngredients}
            />

            <Field
                component={FormSelect}
                data={pestData}
                disabled={pestData.length === 0 || hasProductUsageReport}
                isEditable={isEditable}
                label={PEST_CONDITION}
                max={INPUT_CHARS_MAX}
                name={`chemicals.${index}.pest`}
                searchable
            />

            <FormFieldWithWiderUnit
                defaultUnitValue={GALLONS_PER_ACRE}
                disabled={hasProductUsageReport}
                fieldComponent={FormNumberInput}
                fieldLabel={RATE}
                fieldName={`chemicals.${index}.sprayRate`}
                inputMode={'decimal'}
                isEditable={isEditable}
                unitName={`chemicals.${index}.sprayRateUnit`}
                unitValues={sprayRateUnitOptions}
            />
        </FormSectionContainer>
    );
};

export default ChemicalInformationFields;
