import { useCallback, useState } from 'react';

import useBack from 'hooks/useBack';
import useOperators from 'hooks/useOperators';

import { DEFAULT_SIZE } from 'pages/OperatorTilePage/constants';

const useData = () => {
    const [isOpen, setIsOpen] = useState(true);

    const { fetchAllOperators, loading, operators, updateOperatorEnabled } = useOperators();

    const handleOnCollapse = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const handleOnExpand = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const back = useBack();

    const handleBack = useCallback(() => {
        back(false);
    }, [back]);

    const handleToggle = useCallback(
        async (operatorId: string, enabled: boolean) => {
            try {
                const payload = { enabled };
                const params = { pageable: { size: DEFAULT_SIZE } };

                await updateOperatorEnabled(operatorId, payload);

                await fetchAllOperators(params);
            } catch (error) {
                console.error(`Error toggling operator ${operatorId}:`, error);
            }
        },
        [updateOperatorEnabled, fetchAllOperators]
    );

    return {
        handleBack,
        handleOnCollapse,
        handleOnExpand,
        handleToggle,
        isOpen,
        loading,
        operators
    };
};

export default useData;
