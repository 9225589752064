import type {
    GetCustomersParams,
    PagedResponseCustomerV2Response
} from 'generatedTypes/workOrder/models';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { type LatLongObject, useFormDeprecating as useForm } from '@rantizo-software/rantizo-ui';
import * as turf from '@turf/turf';

import useCustomers from 'hooks/useCustomers';
import useLocation from 'hooks/useLocation';

import { CUSTOMER_PAGE_SIZE } from './constants';
import type { CustomerSearchForm } from './types';

const useData = () => {
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>(undefined);

    const [customers, setCustomers] = useState<PagedResponseCustomerV2Response>({
        hasNext: false,
        nextPageToken: '',
        objects: []
    });

    const { fetchCustomers } = useCustomers();

    const { currentLocation, fetchCurrentLocation } = useLocation();

    const [queryParams, setQueryParams] = useState<GetCustomersParams>({
        pageable: {
            size: CUSTOMER_PAGE_SIZE,
            sort: ['company']
        }
    });

    const customerSearchForm = useForm<CustomerSearchForm>({
        initialSchema: {
            searchTerm: undefined
        },

        onSubmit(formData) {
            const { searchTerm } = formData;

            const newQueryParams = {
                ...queryParams,
                pageToken: undefined,
                searchTerm: searchTerm
            };

            setQueryParams(newQueryParams);

            fetchCustomerData(newQueryParams, true);
        }
    });

    const customerPoints = useMemo(
        () =>
            customers.objects
                .map(customer => customer.address?.location)
                .filter(location => location !== undefined && location?.coordinates !== undefined)
                .map(location => ({
                    geometry: {
                        coordinates: location?.coordinates,
                        type: 'Point'
                    },
                    type: 'Feature'
                })),
        [customers.objects]
    );

    const center = useMemo(() => {
        if (selectedCustomerId) {
            const selectedCustomer = customers.objects.find(
                customer => customer.id === selectedCustomerId
            );

            if (selectedCustomer) {
                return {
                    lat: selectedCustomer.address?.location?.coordinates?.[1],
                    lng: selectedCustomer.address?.location?.coordinates?.[0]
                } as LatLongObject;
            }
        } else {
            if (customerPoints.length > 0) {
                const centerPoint = turf.center({
                    features: customerPoints,
                    type: 'FeatureCollection'
                });

                return {
                    lat: centerPoint.geometry.coordinates[1],
                    lng: centerPoint.geometry.coordinates[0]
                } as LatLongObject;
            }
        }

        return {
            lat: currentLocation[1],
            lng: currentLocation[0]
        } as LatLongObject;
    }, [currentLocation, customerPoints, customers.objects, selectedCustomerId]);

    const zoomLevel = useMemo(() => {
        const MAX_ZOOM = 18;
        const MIN_ZOOM = 1;
        const BUFFFER_FACTOR = 1.3;

        if (selectedCustomerId) {
            return MAX_ZOOM;
        }

        if (customerPoints.length > 0) {
            const featureCollection = {
                features: customerPoints,
                type: 'FeatureCollection'
            };

            const bbox = turf.bbox(featureCollection);

            const diagonalDistance =
                turf.distance([bbox[0], bbox[1]], [bbox[2], bbox[3]]) * BUFFFER_FACTOR;

            const zoom = Math.max(
                MIN_ZOOM,
                Math.min(MAX_ZOOM, Math.floor(MAX_ZOOM - Math.log2(diagonalDistance)))
            );

            return zoom;
        }

        return MAX_ZOOM;
    }, [customerPoints, selectedCustomerId]);

    const clearCustomerData = useCallback(() => {
        setCustomers({
            hasNext: false,
            nextPageToken: '',
            objects: []
        });

        setQueryParams({
            pageable: {
                size: CUSTOMER_PAGE_SIZE,
                sort: ['company']
            }
        });
    }, []);

    const fetchCustomerData = useCallback(
        async (params: GetCustomersParams, isFirstFetch?: boolean) => {
            try {
                const response = await fetchCustomers(params);

                if (isFirstFetch) {
                    setCustomers(response);
                    setSelectedCustomerId(undefined);
                } else {
                    setCustomers({
                        ...response,
                        objects: [...customers.objects, ...response.objects]
                    });
                }

                setQueryParams({ ...params, pageToken: response.nextPageToken });
            } catch (e) {
                console.error(e);
            }
        },
        [customers.objects, fetchCustomers]
    );

    useEffect(
        () => {
            fetchCurrentLocation();
            clearCustomerData();
            fetchCustomerData(queryParams, true);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return {
        center,
        customerSearchForm,
        customers,
        fetchCustomerData,
        queryParams,
        selectedCustomerId,
        setSelectedCustomerId,
        zoomLevel
    };
};

export default useData;
