import { useCallback } from 'react';

import { PageContainer, PageLoader, VerticalContainer } from '@rantizo-software/rantizo-ui';

import PageWithSidebarContainer from 'components/PageWithSidebarContainer';
import PageWithSidebarHeader from 'components/PageWithSidebarHeader';
import SidebarMenu from 'components/SidebarMenu';

import useBack from 'hooks/useBack';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const PageWithSidebar: FunctionComponent<Props> = props => {
    const {
        children,
        isEditable,
        isLoading,
        menuComponent = null,
        onExit,
        sidebarItems,
        state,
        testId = TEST_ID,
        title = ''
    } = props;

    const back = useBack();

    const onSectionChange = useCallback(
        (sidebarItem: string): void => {
            if (!isEditable && !sidebarItems[sidebarItem].isDisabled) {
                state.setSideBarItem(sidebarItem);
            }
        },
        [isEditable, sidebarItems, state]
    );

    const goBack = useCallback(() => {
        if (onExit) {
            onExit?.();
        } else {
            back();
        }
    }, [back, onExit]);

    return (
        <PageContainer testId={testId}>
            <VerticalContainer className={styles.verticalContainer}>
                <PageWithSidebarHeader onBack={goBack} text={title} useX={isEditable}>
                    {menuComponent}
                </PageWithSidebarHeader>

                <PageWithSidebarContainer>
                    <SidebarMenu
                        currentSection={state.sideBarItem}
                        onSectionChange={onSectionChange}
                        sidebarItems={sidebarItems}
                    />

                    <VerticalContainer>{isLoading ? <PageLoader /> : children}</VerticalContainer>
                </PageWithSidebarContainer>
            </VerticalContainer>
        </PageContainer>
    );
};

export default PageWithSidebar;
