import { DEFAULT_SIZE } from '../constants';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useOperators from 'hooks/useOperators';
import usePageRoutes from 'hooks/usePageRoutes';

const useData = () => {
    const { deleteOperatorById, fetchAllOperators } = useOperators();
    const { editOperatorMapPage } = usePageRoutes();
    const navigate = useNavigate();

    const handleDelete = useCallback(
        async (operatorId: string) => {
            try {
                const params = { pageable: { size: DEFAULT_SIZE } };

                await deleteOperatorById(operatorId);
                fetchAllOperators(params);
            } catch (e) {
                console.error(e);
                alert('Error Deleting Operator Try again later');
            }
        },
        [deleteOperatorById, fetchAllOperators]
    );

    const handleEdit = useCallback(
        (operatorId: string) => {
            navigate(editOperatorMapPage(operatorId));
        },
        [editOperatorMapPage, navigate]
    );

    return {
        handleDelete,
        handleEdit
    };
};

export default useData;
