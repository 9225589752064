/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * AcreConnect API
 * AcreConnect API documentation
 * OpenAPI spec version: 1.0
 */
import type {
  GetOperatorServicesParams,
  PagedResponseServiceResponse
} from '../models'


export type getOperatorServicesResponse = {
  data: PagedResponseServiceResponse;
  status: number;
  headers: Headers;
}

export const getGetOperatorServicesUrl = (params: GetOperatorServicesParams,) => {
  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    
    if (value !== undefined) {
      normalizedParams.append(key, value === null ? 'null' : value.toString())
    }
  });

  return normalizedParams.size ? `/api/operator-services?${normalizedParams.toString()}` : `/api/operator-services`
}

export const getOperatorServices = async (params: GetOperatorServicesParams, options?: RequestInit): Promise<getOperatorServicesResponse> => {
  
  const res = await fetch(getGetOperatorServicesUrl(params),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data, headers: res.headers }
}


