import type { CustomerV2Request, GetCustomersParams } from 'generatedTypes/workOrder/models';
import { useCallback, useState } from 'react';

import useFetch from 'hooks/useFetch';
import useQuerystring from 'hooks/useQuerystring';

import { ENDPOINT } from './constants';

const useCustomers = () => {
    const { authenticatedDelete, authenticatedGet, authenticatedPost, authenticatedPut } =
        useFetch();

    const [isLoading, setIsLoading] = useState(false);

    const { addQuery } = useQuerystring({});

    const createCustomer = useCallback(
        async (request: CustomerV2Request) => {
            const { data, error } = await authenticatedPost(ENDPOINT, JSON.stringify(request));

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPost]
    );

    const updateCustomer = useCallback(
        async (id: string, request: CustomerV2Request) => {
            const { data, error } = await authenticatedPut(
                `${ENDPOINT}/${id}`,
                JSON.stringify(request)
            );

            if (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }

            return data;
        },
        [authenticatedPut]
    );

    const fetchCustomers = useCallback(
        async (params: GetCustomersParams) => {
            try {
                setIsLoading(true);
                const { boundingBox, pageToken, pageable, searchTerm } = params;

                const { size, sort } = pageable;

                let queryObject = {};

                if (searchTerm) {
                    queryObject = { searchTerm };
                }

                if (boundingBox) {
                    queryObject = { ...queryObject, boundingBox };
                }

                if (sort) {
                    queryObject = { ...queryObject, sort };
                }

                if (size) {
                    queryObject = { ...queryObject, size };
                }

                if (pageToken) {
                    queryObject = { ...queryObject, pageToken };
                }

                const queryParams = addQuery(queryObject);

                const { data, error } = await authenticatedGet(
                    `${ENDPOINT}?${queryParams?.toString()}`
                );

                if (error) {
                    throw new Error(JSON.stringify(error));
                }

                return data;
            } catch (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            } finally {
                setIsLoading(false);
            }
        },
        [addQuery, authenticatedGet]
    );

    const deleteCustomer = useCallback(
        async (customerId: string) => {
            const { error } = await authenticatedDelete<void>(`${ENDPOINT}/${customerId}`);

            if (error) {
                console.error(error);
                throw new Error(error);
            }
        },
        [authenticatedDelete]
    );

    const fetchCustomer = useCallback(
        async (id: string) => {
            try {
                const { data, error } = await authenticatedGet(`${ENDPOINT}/${id}`);

                if (error) {
                    throw Error(JSON.stringify(error));
                }

                return data;
            } catch (error) {
                console.error(error);
                throw new Error(JSON.stringify(error));
            }
        },
        [authenticatedGet]
    );

    return {
        createCustomer,
        deleteCustomer,
        fetchCustomer,
        fetchCustomers,
        isLoading,
        updateCustomer
    };
};

export default useCustomers;
