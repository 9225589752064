import type { Sources } from 'pages/ManageConnectionsPage/types';

import {
    AAM_PAGE,
    ACCOUNT_DETAILS,
    ACCOUNT_LOGIN_PAGE,
    ACCOUNT_LOGOUT_PAGE,
    ACCOUNT_PAGE,
    ACCOUNT_SETTINGS,
    ACM_PAGE,
    CREATE_CUSTOMER_PAGE,
    CREATE_FAA_REPORT_PAGE,
    CREATE_ORGANIZATAION_PAGE,
    CUSTOMERS_PAGE,
    DRONE_FLEET_PAGE,
    EDIT_AIRCRAFT_PAGE,
    EDIT_CERTIFICATIONS_PAGE,
    EDIT_COMPANY_DETAILS_PAGE,
    EDIT_CONTROLLER_PAGE,
    EDIT_PERSON_OF_CONTACT_PAGE,
    EDIT_USER_DETAILS_PAGE,
    EQUIPMENT_PAGE,
    FAA_REPORTS_PAGE,
    FLIGHT_LOG_PAGE,
    HOME_PAGE,
    MANAGE_CONNECTIONS_PAGE,
    MANAGE_ORGANIZATION_PAGE,
    MAPS_PAGE,
    MAP_GENERATION_PAGE,
    OPERATOR_MAP_PAGE,
    ORGANIZATION_DETAILS,
    ORGANIZATION_EQUIPMENT,
    ORGANIZATION_EQUIPMENT_AIRCRAFT,
    ORGANIZATION_EQUIPMENT_CONTROLLER,
    ORGANIZATION_NEW_AIRCRAFT,
    ORGANIZATION_NEW_CONTROLLER,
    ORGANIZATION_SUBSCRIPTION,
    ORGANIZATION_TEAM_DETAILS,
    ORGANIZATION_TEAM_DETAILS_ACTIVE,
    ORGANIZATION_TEAM_DETAILS_DISABLED,
    ORGANIZATION_TEAM_DETAILS_INVITED,
    PRODUCT_USAGE_REPORT_PAGE,
    PROVIDER_MAP,
    TERMS_OF_SERVICE_PAGE,
    UPLOAD_FLIGHT_LOG,
    VERIFY_ACCOUNT_PAGE,
    VERIFY_EMAIL_PAGE,
    VERIFY_ORGANIZATION_PAGE,
    VIEW_FAA_REPORT_PAGE,
    WORK_ORDER_PAGE
} from './constants';

const usePageRoutes = () => ({
    aamPage: AAM_PAGE,
    accountDetails: ACCOUNT_DETAILS,
    accountLoginPage: ACCOUNT_LOGIN_PAGE,
    accountLogoutPage: ACCOUNT_LOGOUT_PAGE,
    accountPage: ACCOUNT_PAGE,
    accountSettings: ACCOUNT_SETTINGS,
    acmPage: ACM_PAGE,
    asAppliedMapPage: ({ jobId }: { jobId?: string }) => `${AAM_PAGE}/${jobId}/AAM`,
    asCoveredMapPage: ({ jobId }: { jobId?: string }) => `${AAM_PAGE}/${jobId}/COVERAGE`,
    asDryCoveredMapPage: ({ jobId }: { jobId?: string }) => `${AAM_PAGE}/${jobId}/DRY_COVERAGE`,
    createCustomerPage: CREATE_CUSTOMER_PAGE,
    createFaaReportPage: (id: string) => `${CREATE_FAA_REPORT_PAGE}/${id}`,
    createOrganizationPage: CREATE_ORGANIZATAION_PAGE,
    customersPage: CUSTOMERS_PAGE,
    draftProductUsageReportPage: (workOrderId: string, draftId: string) =>
        `${WORK_ORDER_PAGE}/${workOrderId}/product-usage-report/${draftId}`,
    droneFleetPage: DRONE_FLEET_PAGE,
    editAircraftPage: (id: string) => `${EDIT_AIRCRAFT_PAGE}/${id}`,
    editCertificationsPage: EDIT_CERTIFICATIONS_PAGE,
    editCompanyDetailsPage: EDIT_COMPANY_DETAILS_PAGE,
    editControllerPage: (id: string) => `${EDIT_CONTROLLER_PAGE}/${id}`,
    editCustomersPage: (id: string) => `${CUSTOMERS_PAGE}/${id}/edit`,
    editOperatorMapPage: (id: string) => `${OPERATOR_MAP_PAGE}/${id}/edit`,
    editPersonOfContactPage: EDIT_PERSON_OF_CONTACT_PAGE,
    editUserDetailsPage: EDIT_USER_DETAILS_PAGE,
    equipmentPage: EQUIPMENT_PAGE,
    faaReportsPage: FAA_REPORTS_PAGE,

    flightDetailsPage: (id: string) => `${FLIGHT_LOG_PAGE}/${id}`,
    flightLogPage: FLIGHT_LOG_PAGE,
    generateMapPage: MAP_GENERATION_PAGE,
    homePage: HOME_PAGE,
    manageConnectionsPage: (code?: string, source?: Sources) =>
        `${MANAGE_CONNECTIONS_PAGE}/?code=${code}&source=${source}`,
    manageOrganizationPage: MANAGE_ORGANIZATION_PAGE,
    mapPage: (jobId: string, mapType: string) => `${AAM_PAGE}/${jobId}/${mapType}`,
    mapsPage: MAPS_PAGE,
    newProductUsageReportPage: (workOrderId: string) =>
        `${WORK_ORDER_PAGE}/${workOrderId}/product-usage-report`,
    operatorMapPage: OPERATOR_MAP_PAGE,
    organizationDetails: ORGANIZATION_DETAILS,
    organizationEquipment: ORGANIZATION_EQUIPMENT,
    organizationEquipmentAircraft: ORGANIZATION_EQUIPMENT_AIRCRAFT,
    organizationEquipmentController: ORGANIZATION_EQUIPMENT_CONTROLLER,
    organizationNewAircraft: ORGANIZATION_NEW_AIRCRAFT,
    organizationNewController: ORGANIZATION_NEW_CONTROLLER,
    organizationSubscription: ORGANIZATION_SUBSCRIPTION,
    organizationTeamDetails: ORGANIZATION_TEAM_DETAILS,
    organizationTeamDetailsActive: ORGANIZATION_TEAM_DETAILS_ACTIVE,
    organizationTeamDetailsDisabled: ORGANIZATION_TEAM_DETAILS_DISABLED,
    organizationTeamDetailsInvited: ORGANIZATION_TEAM_DETAILS_INVITED,
    productUsageReportEditPage: (id: string) => `${PRODUCT_USAGE_REPORT_PAGE}/${id}/edit`,
    productUsageReportPage: (id: string) => `${PRODUCT_USAGE_REPORT_PAGE}/${id}`,
    productUsageReportPageWithModal: `${PRODUCT_USAGE_REPORT_PAGE}/newPur`,
    productUsageReportsPage: PRODUCT_USAGE_REPORT_PAGE,
    providerMapViewPage: (id: string) => `${PROVIDER_MAP}/${id}`,
    termsOfServicePage: TERMS_OF_SERVICE_PAGE,
    uploadFlightLogPage: UPLOAD_FLIGHT_LOG,
    verifyAccountPage: VERIFY_ACCOUNT_PAGE,
    verifyEmailPage: VERIFY_EMAIL_PAGE,
    verifyOrganizationPage: VERIFY_ORGANIZATION_PAGE,
    viewFaaReportPage: (id: string) => `${VIEW_FAA_REPORT_PAGE}/${id}`,
    workOrderDuplicatePage: (id: string) => `${WORK_ORDER_PAGE}/${id}/duplicate`,
    workOrderEditPage: (id: string) => `${WORK_ORDER_PAGE}/${id}/edit`,
    workOrderPage: WORK_ORDER_PAGE,
    workOrderPageWithId: (id: string) => `${WORK_ORDER_PAGE}/${id}`
});

export default usePageRoutes;
