import { ContactCard } from '@rantizo-software/rantizo-ui';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

const CustomerContactCard: FunctionComponent<Props> = ({ customer, testId = TEST_ID }) => {
    const { address1, address2, city, country, state, zipCode } = customer?.address || {};
    const { email, phoneNumber } = customer;

    const addressComponents = [address1, address2, city, country, state, zipCode].filter(Boolean);

    const formattedAddress =
        addressComponents.length > 0 ? addressComponents.join(', ') : undefined;

    return (
        <ContactCard
            address={formattedAddress}
            email={email}
            phoneNumber={phoneNumber}
            testId={testId}
        />
    );
};

export default CustomerContactCard;
