import useTranslate from 'app/i18n/useTranslate';

const useTranslation = () => {
    const t = useTranslate('customerMapHeader');

    return {
        EDIT: t('edit')
    };
};

export default useTranslation;
