import { format } from 'date-fns';
import { FlightQueryFlightModification } from 'generatedTypes/aam/models';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
    BodyText,
    DatePicker,
    FormSectionContainer,
    TimePicker
} from '@rantizo-software/rantizo-ui';

import BackNextButtons from 'components/BackNextButtons';
import FormRow from 'components/FormRow';
import InputLabel from 'components/InputLabel';
import SelectedItemText from 'components/SelectedItemText';
import SideBarContentContainer from 'components/SideBarContentContainer';
import WithAddButton from 'components/WithAddButton';
import WithRemoveButton from 'components/WithRemoveButton';
import AircraftDropdown from 'components/form/AircraftDropdown';
import ProductUsageReportDropdown from 'components/form/ProductUsageReportDropdown';
import TimezoneDropdown from 'components/form/TimezoneDropdown';

import useForm from 'hooks/useForm';
import useProductUsageReports from 'hooks/useProductUsageReports';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { DATE_FORMAT, TIME_FORMAT, TODAY } from './constants';
import type {
    AircraftDropdownItem,
    ApplicationDetailsFormSchema,
    FunctionComponent,
    Props
} from './types';

import styles from './styles.module.scss';

const ApplicationDetailsForm: FunctionComponent<Props> = ({ onBack, onFlightsFound, onNext }) => {
    const {
        APPLICATION_DATE,
        APPLIED_WITH,
        ASSOCIATED_WITH,
        FLIGHTS_NOT_FOUND,
        INCOMPLETE_FORM,
        PRODUCT_USAGE_REPORT_PLACEHOLDER,
        TO
    } = useTranslation();

    const { getFlightsFromForm } = useData();

    const [submitForm, setSubmitForm] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [aircraft, setAircraft] = useState<AircraftDropdownItem[]>([]);
    const [defaultStartTime, setDefaultStartTime] = useState<string>('');
    const [defaultEndTime, setDefaultEndTime] = useState<string>('');
    const [defaultApplicationDate, setDefaultApplicationDate] = useState<string>('');

    const isRequired = false;

    const formSchema: ApplicationDetailsFormSchema = {
        applicationDate: '',
        applicationDateFromTime: '',
        applicationDateTimezone: '',
        applicationDateToTime: '',
        appliedWith: [],
        kmlFiles: '',
        newAppliedWith: '',
        productUsageReportId: ''
    };

    const dateRef = useRef<HTMLInputElement>(null);
    const aircraftRef = useRef<HTMLSelectElement>(null);
    const fromTimeRef = useRef<HTMLInputElement>(null);
    const timezoneRef = useRef<HTMLSelectElement>(null);
    const toTimeRef = useRef<HTMLInputElement>(null);
    const productUsageReportRef = useRef<HTMLSelectElement>(null);

    const applicationDetailsForm = useForm(formSchema);

    const { form, handleChange, handleError, handleForm, handleSubmit, handleValid } =
        applicationDetailsForm;

    const { fetchProductUsageReport } = useProductUsageReports();

    const handleProductUsageReportChange = useCallback(
        async (id: string) => {
            const { data, error } = await fetchProductUsageReport(id);

            if (error) {
                console.error(error);

                return;
            }

            const { applicationEndDate, applicationStartDate } = data;
            const startDate = new Date(applicationStartDate);
            const endDate = new Date(applicationEndDate);

            const applicationDate = format(startDate, DATE_FORMAT);
            const startTime = format(startDate, TIME_FORMAT);
            const endTime = format(endDate, TIME_FORMAT);

            if (data.drone) {
                setAircraft([
                    {
                        label: data.drone.nickname,
                        value: JSON.stringify(data.drone)
                    }
                ]);
            }

            setDefaultApplicationDate(applicationDate);
            setDefaultEndTime(endTime);
            setDefaultStartTime(startTime);

            handleChange('productUsageReportId')(id);
            handleChange('applicationDate')(applicationDate);
            handleChange('applicationDateFromTime')(startTime);
            handleChange('applicationDateToTime')(endTime);
        },
        [fetchProductUsageReport, handleChange]
    );

    const handleAircraftAdd = useCallback(() => {
        const { newAppliedWith } = form.current;

        if (newAppliedWith !== '') {
            const dropdownValue = JSON.parse(newAppliedWith) as AircraftDropdownItem;
            const { label, value } = dropdownValue;

            if (label !== '' && value !== '') {
                setAircraft(aircraft => [...aircraft, dropdownValue]);
                handleChange('newAppliedWith')('');
            }
        }
    }, [form, handleChange]);

    const handleAircraftRemove = useCallback((removedAircraft: AircraftDropdownItem) => {
        setAircraft(aircraftList => aircraftList.filter(aircraft => aircraft !== removedAircraft));
    }, []);

    const handleBack = useCallback(async () => {
        onBack?.({ form: applicationDetailsForm });
    }, [applicationDetailsForm, onBack]);

    const handleNext = useCallback(async () => {
        const event = new Event('submit');

        aircraftRef.current?.dispatchEvent(event);
        dateRef.current?.dispatchEvent(event);
        fromTimeRef.current?.dispatchEvent(event);
        toTimeRef.current?.dispatchEvent(event);
        timezoneRef.current?.dispatchEvent(event);
        productUsageReportRef.current?.dispatchEvent(event);

        const output = handleForm();

        if (typeof output === 'string') {
            console.error(output);

            return;
        }

        setSubmitForm(true);
    }, [handleForm]);

    useEffect(() => {
        const processForm = async () => {
            setIsSubmitting(true);
            const {
                applicationDate,
                applicationDateFromTime,
                applicationDateTimezone,
                applicationDateToTime,
                newAppliedWith
            } = form.current;

            if (
                applicationDateFromTime !== null &&
                applicationDateFromTime !== undefined &&
                applicationDateFromTime !== '' &&
                applicationDateToTime !== '' &&
                applicationDate &&
                applicationDateTimezone &&
                applicationDateToTime !== null &&
                applicationDateFromTime !== undefined &&
                (aircraft.length > 0 ||
                    (aircraft.length === 0 &&
                        newAppliedWith !== undefined &&
                        newAppliedWith !== null &&
                        newAppliedWith !== ''))
            ) {
                const aircraftList = aircraft.map(({ value }) => value);

                if (newAppliedWith !== '') {
                    if (newAppliedWith !== '') {
                        aircraftList.push(JSON.parse(newAppliedWith).value);
                    }
                }

                const aircraftSerialNumbers = aircraftList.map(
                    aircraft => JSON.parse(aircraft).flightControllerSerial
                );

                const flights = await getFlightsFromForm({
                    ...form.current,
                    appliedWith: aircraftSerialNumbers
                });

                const alternateFlights = await getFlightsFromForm(
                    { ...form.current, appliedWith: aircraftSerialNumbers },
                    FlightQueryFlightModification.REMOVE_FERRYING_LINES
                );

                if (flights.length === 0) {
                    // TODO BETTER ERROR HANDLING
                    alert(FLIGHTS_NOT_FOUND);
                } else {
                    onFlightsFound(flights, alternateFlights, ['AAM', 'COVERAGE']);
                    onNext?.({ form: applicationDetailsForm });
                }
            } else {
                // TODO replace with better error handling
                alert(INCOMPLETE_FORM);
            }
            setIsSubmitting(false);
        };

        if (submitForm) {
            processForm();
            setSubmitForm(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, submitForm]);

    return (
        <SideBarContentContainer>
            <FormSectionContainer>
                <FormSectionContainer>
                    <InputLabel isRequired={false} text={ASSOCIATED_WITH} />

                    <ProductUsageReportDropdown
                        onChange={(id: string) => {
                            handleProductUsageReportChange(id);
                        }}
                        isRequired={false}
                        onError={handleError('productUsageReportId')}
                        onSubmit={handleSubmit('productUsageReportId')}
                        onValid={handleValid('productUsageReportId')}
                        placeholder={PRODUCT_USAGE_REPORT_PLACEHOLDER}
                        ref={productUsageReportRef}
                        selected={undefined}
                    />

                    <InputLabel isRequired={isRequired} text={APPLIED_WITH} />

                    {aircraft.map((aircraft, index) => (
                        <WithRemoveButton
                            key={index}
                            onClick={() => handleAircraftRemove(aircraft)}
                        >
                            <SelectedItemText text={aircraft.label} />
                        </WithRemoveButton>
                    ))}

                    <WithAddButton onClick={handleAircraftAdd}>
                        <AircraftDropdown
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(dropdownValue: any) => {
                                handleChange('newAppliedWith')(dropdownValue);
                            }}
                            ignoreList={aircraft.map(({ value }) => value)}
                            isRequired={isRequired}
                            onError={handleError('newAppliedWith')}
                            onSubmit={handleSubmit('newAppliedWith')}
                            onValid={handleValid('newAppliedWith')}
                            ref={aircraftRef}
                        />
                    </WithAddButton>

                    <InputLabel isRequired={isRequired} text={APPLICATION_DATE} />

                    <DatePicker
                        isRequired={isRequired}
                        maxDate={TODAY}
                        onChange={handleChange('applicationDate')}
                        onValid={handleValid('applicationDate')}
                        ref={dateRef}
                        value={defaultApplicationDate}
                    />

                    <FormRow className={styles.timeRange}>
                        <TimePicker
                            onChange={(value: string) => {
                                handleChange('applicationDateFromTime')(value);
                            }}
                            onSubmit={handleSubmit('applicationDateFromTime')}
                            ref={fromTimeRef}
                            testId={'applicationDateFromTime'}
                            value={defaultStartTime}
                        />

                        <BodyText text={TO} />

                        <TimePicker
                            onChange={(value: string) => {
                                handleChange('applicationDateToTime')(value);
                            }}
                            onSubmit={handleSubmit('applicationDateToTime')}
                            ref={toTimeRef}
                            testId={'applicationDateToTime'}
                            value={defaultEndTime}
                        />

                        <TimezoneDropdown
                            onChange={(dropdownValue: unknown) => {
                                handleChange('applicationDateTimezone')(dropdownValue);
                            }}
                            onError={handleError('applicationDateTimezone')}
                            onSubmit={handleSubmit('applicationDateTimezone')}
                            onValid={handleValid('applicationDateTimezone')}
                            ref={timezoneRef}
                        />
                    </FormRow>
                </FormSectionContainer>

                <BackNextButtons
                    isNextLoading={isSubmitting}
                    onBack={handleBack}
                    onNext={handleNext}
                />
            </FormSectionContainer>
        </SideBarContentContainer>
    );
};

export default ApplicationDetailsForm;
