import { useMemo, useState } from 'react';

import {
    HeadingText,
    HorizontalContainer,
    MapMarker,
    MapPin,
    MapPinInfoWindow,
    ServiceChecklistItem,
    VerticalContainer,
    useAdvancedMarkerRef
} from '@rantizo-software/rantizo-ui';

import useTranslate from './hooks/useTranslate';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const OperatorDetailsMapPoint: FunctionComponent<Props> = ({
    location,
    services,
    testId = TEST_ID,
    title
}) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const position = useMemo(
        () => ({
            lat: location.coordinates[1],
            lng: location.coordinates[0]
        }),
        [location]
    );

    const { AERIAL_APPLICATION, AERIAL_IMAGING } = useTranslate();

    const [isShown, setIsShown] = useState(true);

    return (
        <VerticalContainer data-testid={testId}>
            <MapMarker
                onClick={() => setIsShown(!isShown)}
                position={position}
                ref={markerRef}
                title={title}
            >
                <MapPin />
            </MapMarker>

            <MapPinInfoWindow
                headerContent={<HeadingText text={title} />}
                isShown={isShown}
                marker={marker}
                position={position}
                setIsShown={setIsShown}
            >
                <HorizontalContainer className={styles.horizontalContainer}>
                    {services.includes('DRONE_AERIAL_APPLICATION') && (
                        <ServiceChecklistItem text={AERIAL_APPLICATION} />
                    )}

                    {services.includes('DRONE_IMAGERY') && (
                        <ServiceChecklistItem text={AERIAL_IMAGING} />
                    )}
                </HorizontalContainer>
            </MapPinInfoWindow>
        </VerticalContainer>
    );
};

export default OperatorDetailsMapPoint;
