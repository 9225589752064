import type { OperatorEnabledRequest } from 'generatedTypes/operator-map/models';
import {
    getCreateOperatorUrl,
    getDeleteOperatorUrl,
    getGetOperatorUrl,
    getUpdateOperatorEnabledStatusUrl,
    getUpdateOperatorUrl
} from 'generatedTypes/operator-map/services/operator-controller';
import { useCallback, useState } from 'react';

import type { PagedResponseOperatorResponse } from '@rantizo-software/rantizo-ui';

import useFetch from 'hooks/useFetch';
import useGlobalStore from 'hooks/useGlobalStore';
import useQuerystring from 'hooks/useQuerystring';
import useToast from 'hooks/useToast';

import useTranslation from './hooks/useTranslation';

import { OPERATORS_API } from './constants';
import type { ModifiedOperatorRequest } from './types';

const useOperators = () => {
    const { authenticatedDelete, authenticatedGet, authenticatedPost, authenticatedPut } =
        useFetch();

    const { addQuery } = useQuerystring({});
    const { useGetValue, useSetValue } = useGlobalStore<PagedResponseOperatorResponse | null>({
        namespace: 'useOperators'
    });
    const operators = useGetValue('operators');
    const saveOperatorsToStore = useSetValue('operators');

    const [loading, setLoading] = useState(false);

    const { broadcastToast } = useToast();

    const { CREATE_OPERATOR_TILE_TOAST_MESSAGE, UPDATE_OPERATOR_TILE_TOAST_MESSAGE } =
        useTranslation();

    const fetchOperatorById = useCallback(
        async (operatorId: string) => {
            setLoading(true);

            try {
                const url = getGetOperatorUrl(operatorId);
                const { data, error } = await authenticatedGet(url);

                if (error) {
                    throw error;
                }

                return data;
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [authenticatedGet]
    );

    const createOperator = useCallback(
        async (request: ModifiedOperatorRequest) => {
            setLoading(true);

            try {
                const url = getCreateOperatorUrl();
                const { data, error } = await authenticatedPost(url, JSON.stringify(request));

                broadcastToast({ text: CREATE_OPERATOR_TILE_TOAST_MESSAGE, type: 'info' });

                if (error) {
                    throw error; // Throw error to be caught in the catch block
                }

                return data;
            } catch (error) {
                broadcastToast({ text: JSON.stringify(error), type: 'error' });
            } finally {
                setLoading(false);
            }
        },
        [authenticatedPost, broadcastToast, CREATE_OPERATOR_TILE_TOAST_MESSAGE]
    );

    const deleteOperatorById = useCallback(
        async (operatorId: string) => {
            setLoading(true);

            try {
                const url = getDeleteOperatorUrl(operatorId);
                const { error } = await authenticatedDelete(url);

                if (error) {
                    throw error;
                }
            } catch (error) {
                broadcastToast({ text: JSON.stringify(error), type: 'error' });
            } finally {
                setLoading(false);
            }
        },
        [authenticatedDelete, broadcastToast]
    );

    const fetchAllOperators = useCallback(
        async ({
            pageToken,
            pageable
        }: {
            pageable: { page?: number; size: number };
            pageToken?: string;
        }) => {
            setLoading(true);

            try {
                const queryParams = {
                    size: `${pageable.size}`,
                    ...(pageToken && { pageToken })
                };

                const queryString = addQuery(queryParams);

                const url = `${OPERATORS_API}?${queryString}`;
                const { data } = await authenticatedGet<PagedResponseOperatorResponse>(url);

                saveOperatorsToStore(data);

                return data;
            } catch (error) {
                broadcastToast({ text: JSON.stringify(error), type: 'error' });
            } finally {
                setLoading(false);
            }
        },
        [authenticatedGet, broadcastToast, saveOperatorsToStore, addQuery]
    );

    const updateOperator = useCallback(
        async (operatorId: string, payload: ModifiedOperatorRequest) => {
            setLoading(true);

            try {
                const url = getUpdateOperatorUrl(operatorId);
                const { data, error } = await authenticatedPut(url, JSON.stringify(payload));

                if (error) {
                    throw error;
                }

                broadcastToast({ text: UPDATE_OPERATOR_TILE_TOAST_MESSAGE, type: 'info' });

                return data;
            } catch (error) {
                broadcastToast({ text: JSON.stringify(error), type: 'error' });

                return null;
            } finally {
                setLoading(false);
            }
        },
        [authenticatedPut, broadcastToast, UPDATE_OPERATOR_TILE_TOAST_MESSAGE]
    );

    const updateOperatorEnabled = useCallback(
        async (operatorId: string, payload: OperatorEnabledRequest) => {
            setLoading(true);

            try {
                const url = getUpdateOperatorEnabledStatusUrl(operatorId);
                const { error } = await authenticatedPut(url, JSON.stringify(payload));

                if (error) {
                    throw error;
                }
            } catch (error) {
                broadcastToast({ text: JSON.stringify(error), type: 'error' });
            } finally {
                setLoading(false);
            }
        },
        [authenticatedPut, broadcastToast]
    );

    return {
        createOperator,
        deleteOperatorById,
        fetchAllOperators,
        fetchOperatorById,
        loading,
        operators,
        updateOperator,
        updateOperatorEnabled
    };
};

export default useOperators;
