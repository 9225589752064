import { DeleteWithTrashButton, Wrapper } from '@rantizo-software/rantizo-ui';

import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const CustomerDeleteButton: FunctionComponent<Props> = ({ customerId, testId = TEST_ID }) => {
    const { DELETE_BUTTON_TEXT, DELETE_CONFIRMATION_BODY, DELETE_CONFIRMATION_TITLE } =
        useTranslation();

    const { handleDelete, isOpen, setIsOpen } = useData();

    return (
        <Wrapper>
            <DeleteWithTrashButton
                className={styles.deleteButton}
                onClick={() => setIsOpen(true)}
                text={DELETE_BUTTON_TEXT}
            />

            {isOpen && (
                <DeleteConfirmationModal
                    buttonText={DELETE_BUTTON_TEXT}
                    className={styles.deleteConfirmationModal}
                    onClose={() => setIsOpen(false)}
                    onConfirm={() => handleDelete(customerId)}
                    testId={testId}
                    text={DELETE_CONFIRMATION_BODY}
                    title={DELETE_CONFIRMATION_TITLE}
                />
            )}
        </Wrapper>
    );
};

export default CustomerDeleteButton;
