const translations = {
    back: 'Back',
    companyDetails: 'Company Details',
    consentLabel:
        'I agree that my information will be shared publicly and will be searchable on the service provider map',
    locationDetails: 'Location Details',
    serviceDetails: 'Service Details',
    submitForApproval: 'Submit for Approval'
};

export default translations;
