import {
    ContentText,
    HorizontalContainer,
    ModalOverlay,
    ModalTitle,
    PrimaryButton,
    VerticalContainer
} from '@rantizo-software/rantizo-ui';

import DeclineButton from 'components/DeclineButton';

import useTranslation from './hooks/useTranslation';

import { TEST_ID } from './constants';
import type { FunctionComponent, Props } from './types';

import styles from './styles.module.scss';

const SaveChangesModal: FunctionComponent<Props> = ({
    className,
    isSaveDisabled = false,
    onClose,
    onConfirm,
    testId = TEST_ID
}) => {
    const { BODY, CONFIRM_TEXT, DECLINE_TEXT, TITLE } = useTranslation();

    return (
        <ModalOverlay onClose={onClose} testId={testId}>
            <VerticalContainer className={`${styles.verticalContainer} ${className}`}>
                <ModalTitle text={TITLE} />

                <ContentText text={BODY} />

                <HorizontalContainer className={styles.horizontalContainer}>
                    <PrimaryButton
                        isDisabled={isSaveDisabled}
                        onClick={onConfirm}
                        text={CONFIRM_TEXT}
                    />

                    <DeclineButton onClick={onClose} text={DECLINE_TEXT} />
                </HorizontalContainer>
            </VerticalContainer>
        </ModalOverlay>
    );
};

export default SaveChangesModal;
