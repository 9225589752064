import useFetch from 'hooks/useFetch';

const useUploadFile = () => {
    const { httpPutBinary } = useFetch();

    const getObjectKeyFromUrl = (url: string) => {
        const regex = /(?:\.com\/)(.*)(?:\?)/;
        const match = url.match(regex);

        if (match && match[1]) {
            return match[1];
        }
        console.error('Error parsing object key from URL');

        return '';
    };

    const uploadFile = async (url: string, file: File) => {
        const arrayBuffer = await file.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);

        const result = await httpPutBinary(url, data, {
            headers: {
                'Content-Type': file.type
            }
        });

        if (result.error) {
            console.error('Error uploading file:', result.error);

            return false;
        }

        return true;
    };

    return { getObjectKeyFromUrl, uploadFile };
};

export default useUploadFile;
