import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useAccount from 'hooks/useAccount';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useGlobalStore from 'hooks/useGlobalStore';

const useLogout = () => {
    const { logout: logoutAuth0 } = useAuth0();
    const { setCurrentOrganization } = useCurrentOrganization();
    const { saveMyUserToStore } = useAccount();
    const { useGetValue, useSetValue } = useGlobalStore<boolean>({ namespace: 'useLogout' });

    const saveIsLoggingOutToStore = useSetValue('isLoggingOut');

    const logout = useCallback(() => {
        setCurrentOrganization(undefined);
        saveMyUserToStore(undefined);
        saveIsLoggingOutToStore(true);
        logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
    }, [logoutAuth0, saveIsLoggingOutToStore, saveMyUserToStore, setCurrentOrganization]);

    return {
        isLoggingOut: useGetValue('isLoggingOut'),
        logout
    };
};

export default useLogout;
